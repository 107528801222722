import React, { useState } from 'react';
import DivTag from '../../Components/DivComponent/DivTag';
import Button from '../../Components/ButtonComponent/Button';
import FormValidation from '../../Components/FormValidation/FormValidation';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import InputWithLabel from '../../Components/InputComponent/InputWithLabel';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import ReportHeading from '../../Components/ReportHeadings/ReportHeadings';
import PopUpComponent from './PopUpComponent';
import { motion } from 'framer-motion';
import printTable from '../../Components/TablePrintExcelPdfSearch/tablePrint';
import downloadExcel from '../../Components/TablePrintExcelPdfSearch/downloadExcel';
import downloadPdf from '../../Components/TablePrintExcelPdfSearch/downloadPdf';
import InputField from '../../Components/InputComponent/InputField';
import Pagination from '../../Components/Pagination/Pagination';
import 'jspdf-autotable'; // Import AutoTable plugin

const IdfcVanReport = () => {


  // Van Report Page Popup
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };




  const initialState = {
    customerName: '',
    van: '',
    company_name: '',
    status: '',
    BankName: '',
  };

  const validate = (values) => {
    let errors = {};

    // Add validation logic here
    if (!values.customerName) {
      errors.customerName = 'Customer Name is required';
    }
    if (!values.van) {
      errors.van = 'VAN is required';
    }
    if (!values.company_name) {
      errors.company_name = 'Company Name is required';
    }
    if (!values.status || values.status === '') {
      errors.status = 'Status is required';
    }
    if (!values.BankName || values.BankName === ''){
      errors.BankName = 'BankName is required';
    }
    // Add more validation rules as needed
    return errors;
  };

  const { values, errors, handleChange, handleBlur } = FormValidation(
    initialState,
    validate
  );

  // Define default table data
  const [defaultTableData, setDefaultTableData] = useState([
    {
      SrNo: 1,
      VirtualAccount: 'Yes',
      BankName: 'Canar',
      CompanyName: 'Candour',
      CustomerName: 'Ram',
      AssignDate: '2008/12/13',
      UseCase: '01,600',
      Pan: 'Gwnon23',
      AadharCIN: 'mk084',
      ApiID: 'ip45B',
      Status: 'Approved',
      DailyLimit: '8,000',
      BlockDate: '2011/12/13',
      BlockReason: 'Issue',
    }



    // Add more default data objects as needed
  ]);


  // State to track if search button is clicked
  const [searchClicked, setSearchClicked] = useState(false);

  // State to track the index of the row being edited
  const [editIndex, setEditIndex] = useState(null);

  const handleInputChange = (e, index, fieldName) => {
    const { value } = e.target;
    const newData = [...defaultTableData];
    newData[index][fieldName] = value;
    setDefaultTableData(newData);
  };

  // Function to handle search button click
  const handleSearchClick = () => {
    // Update the state to indicate that the search button is clicked
    setSearchClicked(true);
  };

  // Function to handle edit click
  const handleEditClick = (index) => {
    setEditIndex(index);
  };

  // Function to handle update click
  const handleUpdateClick = () => {
    // Logic to update the row data
    setEditIndex(null); // Reset editIndex after updating
  };



   // Pagination
   const [currentPage, setCurrentPage] = useState(1);
   const [itemsPerPage] = useState(4); // Change this number as per your requirement

   // Calculate total number of pages
   const totalPages = Math.ceil(defaultTableData.length / itemsPerPage);

   // Logic to handle pagination
   const paginate = (pageNumber) => setCurrentPage(pageNumber);

   // Printing function starts here 


   // Printing function
   const handlePrint = () => {
       printTable('table');
   };

   // Printing Function Ends


   // Download function starts here

   // Downloading Function
   const onDownloadExcel = () => {
       const headers = Object.keys(defaultTableData[0]).filter(header => header !== 'Activate');
       downloadExcel(headers, defaultTableData);
   };

   // Download function ends here


   // Download pdf function starts here
   const onDownloadPdf = () => {
       // Extract headers from the defaultTableData
       const headers = Object.keys(defaultTableData[0]).filter(header => header !== 'Activate');

       // Call downloadPdf with both defaultTableData and headers
       downloadPdf(defaultTableData, headers);
   };
   // Download pdf function ends here

   // Get current items
   const indexOfLastItem = currentPage * itemsPerPage;
   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
   const currentItems = defaultTableData.slice(indexOfFirstItem, indexOfLastItem);



   //Search By Input Field of Table Data
   const [searchQuery, setSearchQuery] = useState('');

   // Function to handle changes in the search input
   const handleSearchInputChange = (event) => {
       setSearchQuery(event.target.value);
   };

   // Filtered items based on search query
   const filteredItems = currentItems.filter(item =>
       item.BankName.toLowerCase().includes(searchQuery.toLowerCase()) ||
       item.ApiID.toLowerCase().includes(searchQuery.toLowerCase()) ||
       item.CompanyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
       item.AadharCIN.toLowerCase().includes(searchQuery.toLowerCase()) ||
       item.CustomerName.toLowerCase().includes(searchQuery.toLocaleLowerCase())
   );
   //Search Filter End


  return (
    <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
    >

{/* <DivTag id="content" className="main-content border-2 border-solid border-blue-800 rounded-xl mt-12 bg-white"> */}
<DivTag id="" className=" border-2 border-solid border-blue-800 rounded-xl md:mt-12 mt-5 bg-white">
         
            <ReportHeading name="Van Report" />
            
        <DivTag className="flex justify-center items-center">

          <DivTag className="flex flex-wrap mt-3">    
          
            <InputWithLabel
            DivclassName="w-1/2 md:w-1/5 px-2 md:mb-0"
              label="Customer Name"
              type="text"
              placeholder="Enter Customer Name"
              name="customerName"
              id="customerName"
              value={values.customerName}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.customerName ? 'border border-red-500' : 'border border-blue-600'}
              error={errors.customerName}
            />
          
            <InputWithLabel
            DivclassName="w-1/2 md:w-1/5 px-2 md:mb-0"
              label="VAN"
              type="text"
              placeholder="Enter Van"
              name="van"
              id="van"
              value={values.van}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.van ? 'border border-red-500' : 'border border-blue-600'}
              error={errors.van}
            />
        
            <InputWithLabel
            DivclassName="w-1/2 md:w-1/5 px-2 md:mb-0"
              label="Company Name"
              type="text"
              placeholder="Enter Company Name"
              name="company_name"
              id="company_name"
              value={values.company_name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.company_name ? 'border border-red-500' : 'border border-blue-600'}
              error={errors.company_name}
            />
           
         
            <SelectWithLabelOption
            DivclassName="w-1/2 md:w-1/5 px-2 md:mb-0"
            label="Status"
              id="status"
              name="status"
              value={values.status}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.status ? 'border border-red-500' : 'border border-blue-600'}
              error={errors.status}
            >
              <option value="">Select Status</option>
              <option value="Assigned & Active">Assigned & Active</option>
              <option value="Assigned but Blocked">Assigned but Blocked</option>
              <option value="Not Assigned">Not Assigned</option>
            </SelectWithLabelOption>
            
            <InputWithLabel
            DivclassName="w-full md:w-1/5 px-2 md:mb-0"
              label="Bank Name"
              type="text"
              placeholder="Enter Bank Name"
              name="BankName"
              id="BankName"
              value={values.BankName}
              onChange={handleChange}
              onBlur={handleBlur}
              className={errors.BankName ? 'border border-red-500' : 'border border-blue-600'}
              error={errors.BankName}
            />  
            

          </DivTag>

        </DivTag>

        <DivTag className="flex justify-center">
          <Button
           type="submit"
            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500
        hover:to-yellow-500 text-white border border-white rounded-lg px-2 py-1 text-lg"
            onClick={handleSearchClick}>
            Search
          </Button>
        </DivTag>

        <DivTag className="flex flex-col items-center"> {/* Container */}
                        <HeadingTag className="text-2xl text-blue-800 font-bold bg-white text-center mt-1">Report</HeadingTag>
                        <DivTag className="flex justify-end w-full"> {/* Flex container for the button */} 
                        </DivTag>
                    </DivTag>
                    
         {/* Print,pdf,excel,SearchFilter */}
         <DivTag className="flex">
                    <DivTag className="mr-auto p-0.5">
                        {/* Button printing */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={handlePrint}
                            text="Print"
                        />

                        {/* Button downloadexcel */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={onDownloadExcel}
                            text="Excel"
                        />

                        {/* Button  downloadPdf  */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={onDownloadPdf}
                            text="PDF"
                        />
                    </DivTag>
                    {/* Input Search Field */}

                    <InputField
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        placeholder="Search.........."
                        className="appearance-none block text-gray-700 border rounded mb-1
                leading-tight focus:outline-none focus:bg-white text-center"
                    />
                </DivTag>

                <div className="overflow-x-auto my-1 p-2">
                <table id="table" className="min-w-full divide-y divide-gray-200">

            <thead className="text-md">
              <tr className="bg-blue-800 text-white text-center border-2">
                <th className=" p-2 border-2">SNo</th>
                <th className="border-2">Virtual A/c</th>
                <th className="border-2">Bank Name</th>
                <th className="border-2">Comp Name</th>
                <th className="border-2">Cust Name</th>
                <th className="border-2">Asg Date</th>
                <th className="border-2">Use Case</th>
                <th className="border-2">PAN</th>
                <th className="border-2">Aadhar CIN</th>
                <th className="border-2">Api ID</th>
                <th className="border-2">Status</th>
                <th className="border-2">Daily Limit</th>
                <th className="border-2">Block Date</th>
                <th className="border-2">Block Reason</th>
                <th className="border-2">Action</th>
              </tr>
            </thead>
            <tbody className="">
              {filteredItems.map((item, index) => (
                <tr key={index} className='text-center'>
                  <td className='border-2'>{item.SrNo}</td>
                  <td className='border-2'>
                    {editIndex === index ? (
                      <InputField
                        className="w-12"
                        value={item.VirtualAccount}
                        onChange={(e) => handleInputChange(e, index, 'VirtualAccount')}
                      />
                    ) : (
                      item.VirtualAccount
                    )} </td>
                    <td className='border-2'>{item.BankName}</td>
                  <td className='border-2'>
                    {editIndex === index ? (
                      <InputField
                        className="w-12"
                        value={item.CompanyName}
                        onChange={(e) => handleInputChange(e, index, 'CompanyName')}
                      />
                    ) : (
                      item.CompanyName
                    )}
                  </td>
                  <td className='border-2'>
                    {editIndex === index ? (
                      <InputField
                        className="w-12"
                        value={item.CustomerName}
                        onChange={(e) => handleInputChange(e, index, 'CustomerName')}
                      />
                    ) : (
                      item.CustomerName
                    )}
                  </td>
                  <td className='border-2'>{item.AssignDate}</td>
                  <td className='border-2'>{item.UseCase}</td>
                  <td className='border-2'>
                    {editIndex === index ? (
                      <InputField
                        className="w-12"
                        value={item.Pan}
                        onChange={(e) => handleInputChange(e, index, 'Pan')}
                      />
                    ) : (
                      item.Pan
                    )}
                  </td>
                  <td className='border-2'>
                    {editIndex === index ? (
                      <InputField
                        className="w-12"
                        value={item.AadharCIN}
                        onChange={(e) => handleInputChange(e, index, 'AadharCIN')}
                      />
                    ) : (
                      item.AadharCIN
                    )}
                  </td>
                  <td className='border-2'>
                    {editIndex === index ? (
                      <InputField
                        className="w-12"
                        value={item.ApiID}
                        onChange={(e) => handleInputChange(e, index, 'ApiID')}
                      />
                    ) : (
                      item.ApiID
                    )}
                  </td>
                  <td className='border-2'>{item.Status}</td>
                  <td className='border-2'>{item.DailyLimit}</td>
                  <td className='border-2'>{item.BlockDate}</td>
                  <td className='border-2'>{item.BlockReason}</td>
                  <td className='border-2'>

                    {editIndex === index ? (
                      <Button className="text-white bg-sky-600 px-1 rounded-lg ml-2 animate-pulse" onClick={handleUpdateClick}>Update</Button>
                    ) : (
                      <Button className="text-white bg-red-500 px-1 rounded-lg ml-4" onClick={() => handleEditClick(index)}>Edit</Button>
                    )}

                    <Button className="text-white bg-green-500 px-2 rounded-lg mt-1" onClick={openModal}>Re-Assign</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='flex justify-center'>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />
        </div>
    </DivTag>
     {isModalOpen && <PopUpComponent onClose={closeModal} />}
     </motion.div>
  );
};

export default IdfcVanReport;
