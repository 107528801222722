import React, { useState, useEffect } from 'react';
import DivTag from '../../Components/DivComponent/DivTag';
import FormTag from '../../Components/FormComponent/FormTag';
import ReportHeading from '../../Components/ReportHeadings/ReportHeadings';
import { motion } from 'framer-motion';
import CheckBox from '../../Components/InputComponent/CheckBox';
import Button from '../../Components/ButtonComponent/Button';
import Seo from '../../../components/SeoComponent/Seo';


const ManagePermission = () => {
  const [checkboxes, setCheckboxes] = useState({
    apiUserChecked: false,
    apiAccountChecked: false,
    uploadKycChecked: false,
    approveKycChecked: false,
    walletBalManagementChecked: false,
    commissionMasterChecked: false,
    managePermissionChecked: false,
    manageCollectionChecked: false,
    idfcVanConfigChecked: false,
    idfcVanReportChecked: false,
    indusindVanReportChecked: false,
    vanTxnReportChecked: false,
    reportChecked: false,
    payoutReportChecked: false,
    dmtReportChecked: false,
    rechargeBbpsReportChecked: false,
    walletStatementChecked: false,
    addServicesChecked: false,
    activateServiceChecked: false,
    activeServiceReportChecked: false,
  });

  const checkboxGroups = {
    apiUserChecked: [
      'apiAccountChecked',
      'uploadKycChecked',
      'approveKycChecked',
      'walletBalManagementChecked',
      'commissionMasterChecked',
      'managePermissionChecked',
    ],
    manageCollectionChecked: [
      'idfcVanConfigChecked',
      'idfcVanReportChecked',
      'indusindVanReportChecked',
      'vanTxnReportChecked',
    ],
    reportChecked: [
      'payoutReportChecked',
      'dmtReportChecked',
      'rechargeBbpsReportChecked',
      'walletStatementChecked',
    ],
    addServicesChecked: [
      'activateServiceChecked',
      'activeServiceReportChecked',
    ],
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    const newCheckboxes = { ...checkboxes, [name]: checked };

    Object.keys(checkboxGroups).forEach((groupKey) => {
      if (name === groupKey) {
        checkboxGroups[groupKey].forEach((subCheckbox) => {
          newCheckboxes[subCheckbox] = checked;
        });
      } else if (checkboxGroups[groupKey].includes(name)) {
        if (!checked) {
          newCheckboxes[groupKey] = false;
        } else {
          newCheckboxes[groupKey] = checkboxGroups[groupKey].every(
            (subCheckbox) => newCheckboxes[subCheckbox]
          );
        }
      }
    });

    setCheckboxes(newCheckboxes);
  };

  return (
    <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1.2 }}>
      <Seo />
      {/* <DivTag id="content" className="main-content border-2 border-solid mt-8 bg-white"> */}
      <DivTag id="" className=" border-2 border-solid border-blue-800 rounded-xl mt-12 bg-white">
        <ReportHeading name="Manage Permission" />
        <FormTag method="" autoComplete="off">
          
          <DivTag className="border rounded-xl mt-2 p-1 bg-gradient-to-r from-orange-600 via-teal-800 to-blue-900">
            <CheckBox
              LabelClass="font-semibold text-white"
              label="Api User"
              name="apiUserChecked"
              checked={checkboxes.apiUserChecked}
              onChange={handleCheckboxChange}
            />
            <DivTag className="flex justify-center border border-blue-500 bg-white rounded-xl gap-1 p-1">
              {checkboxGroups.apiUserChecked.map((key) => (
                <CheckBox
                  key={key}
                  label={key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                  name={key}
                  checked={checkboxes[key]}
                  onChange={handleCheckboxChange}
                />
              ))}
            </DivTag>
          </DivTag>

          <DivTag className="border rounded-xl mt-3 p-1 bg-gradient-to-r from-orange-600 via-teal-800 to-blue-900">
            <CheckBox
              LabelClass="font-semibold text-white"
              label="Manage Collection"
              name="manageCollectionChecked"
              checked={checkboxes.manageCollectionChecked}
              onChange={handleCheckboxChange}
            />
            <DivTag className="flex justify-center border border-blue-500 bg-white rounded-xl gap-1 p-1">
              {checkboxGroups.manageCollectionChecked.map((key) => (
                <CheckBox
                  key={key}
                  label={key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                  name={key}
                  checked={checkboxes[key]}
                  onChange={handleCheckboxChange}
                />
              ))}
            </DivTag>
          </DivTag>

          <DivTag className="border rounded-xl mb-2 mt-3 p-1 bg-gradient-to-r from-orange-600 via-teal-800 to-blue-900">
            <CheckBox
              LabelClass="font-semibold text-white"
              label="Report"
              name="reportChecked"
              checked={checkboxes.reportChecked}
              onChange={handleCheckboxChange}
            />
            <DivTag className="flex justify-center border border-blue-500 bg-white rounded-xl gap-1 p-1">
              {checkboxGroups.reportChecked.map((key) => (
                <CheckBox
                  key={key}
                  label={key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                  name={key}
                  checked={checkboxes[key]}
                  onChange={handleCheckboxChange}
                />
              ))}
            </DivTag>
          </DivTag>

          <DivTag className="border rounded-2xl mb-2 mt-3 p-1 bg-gradient-to-r from-orange-600 via-teal-800 to-blue-900">
            <CheckBox
              LabelClass="font-semibold text-white"
              label="Services"
              name="addServicesChecked"
              checked={checkboxes.addServicesChecked}
              onChange={handleCheckboxChange}
            />
            <DivTag className="flex justify-center border border-blue-500 bg-white rounded-2xl gap-1 p-1">
              {checkboxGroups.addServicesChecked.map((key) => (
                <CheckBox
                  key={key}
                  label={key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                  name={key}
                  checked={checkboxes[key]}
                  onChange={handleCheckboxChange}
                />
              ))}
            </DivTag>
          </DivTag>

          <DivTag className="flex justify-center">
                    <Button
                        type="button"
                        id="submit"
                        className="text-center text-white bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900
                         via-teal-400 to-purple-900 font-semibold p-0 rounded-md px-2 py-1 text-lg hover:bg-red-300"
                        text="Search"
                    />
                </DivTag>
        </FormTag>
        
      </DivTag>
    </motion.div>
  );
};

export default ManagePermission;
