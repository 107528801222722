import React from 'react';

const CheckBox = ({ label, name, checked, onChange, LabelClass }) => {
  return (
    <label className={LabelClass}>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
        className="mr-2"
      />
      {label}
    </label>
  );
};

export default CheckBox;
