
import React, { useState, useEffect } from 'react';
import { IoMdEyeOff, IoMdEye } from 'react-icons/io';
import { motion } from 'framer-motion';
import Seo from '../../components/SeoComponent/Seo';

function MasterPage() {
  const [isPinSent, setIsPinSent] = useState(false);
  const [otp, setOtp] = useState('');
  const [pin, setPin] = useState('');
  const [ipAddress, setIpAddress] = useState('');
  const [showOtpModal, setShowOtpModal] = useState(false); 
  const [showOtpModalIp, setShowOtpModalIp] = useState(false); 
  const [showPin, setShowPin] = useState(false);
  const [showIp, setShowIp] = useState(false); 
  const [error, setError] = useState(''); 

  // Handle send OTP click for PIN
  const handleSendOtpForPin = () => {
    if (pin.length !== 6) {
      setError("Please enter a valid 6-digit PIN.");
    } else {
      setError('');
      setIsPinSent(true);
      setShowOtpModal(true);  // Open OTP modal for PIN
    }
  };

  // Handle OTP input change
  const handleOtpChange = (event) => {
    setOtp(event.target.value);
  };

  // Handle change pin button click
  const handleChangePin = () => {
    alert("PIN changed successfully");
    setShowOtpModal(false);
  };

  // Handle IP address change
  const handleChangeIp = () => {
    alert("IP Address changed successfully");
    setShowOtpModalIp(false);
  };

  return (
    <motion.div
    initial={{ opacity: 0, y: -50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 1.2 }}
>
    <Seo />



    <div className="text-center w-full max-w-[500px] h-auto sm:h-[550px] mt-24 mx-auto bg-white rounded-lg shadow-xl p-5 bg-gradient-to-r from-blue-200 to-cyan-200">
  <h1 className="text-blue-800 text-lg sm:text-2xl mb-4">
    <u>Master Page</u>
  </h1>

  {/* Pin Section */}
  <div className="p-2">
    <label className="block text-sm mb-2">PIN</label>
    <div className="flex flex-col sm:flex-row items-center justify-center gap-2 mb-4">
      <input
        className="border border-blue-400 text-center p-2 rounded-lg shadow-sm w-full sm:w-auto"
        placeholder="Enter PIN"
        type={showPin ? 'text' : 'password'}
        value={pin}
        onChange={(e) => setPin(e.target.value)}
        maxLength="6"
      />
      <button
        type="button"
        className="text-xl"
        onClick={() => setShowPin(!showPin)}
      >
        {showPin ? <IoMdEye /> : <IoMdEyeOff />}
      </button>
    </div>
    {pin.length === 0 && <span className="text-red-600 text-sm">*</span>}
    <button
      type="button"
      className="mt-2 w-full sm:w-auto text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-5 py-1"
      onClick={handleSendOtpForPin}
    >
      Send OTP
    </button>
  </div>

  {error && <p className="text-red-500 text-sm mt-2">{error}</p>}

  {/* IP Address Section */}
  <div className="p-2 mt-1">
    <label className="block text-sm mb-2">IP Address</label>
    <div className="flex flex-col sm:flex-row items-center justify-center gap-2 mb-4">
      <input
        className="border border-blue-400 text-center p-2 rounded-lg shadow-sm w-full sm:w-auto"
        placeholder="Enter IP Address"
        type={showIp ? 'text' : 'password'}
        value={ipAddress}
        onChange={(e) => setIpAddress(e.target.value)}
      />
      <button
        type="button"
        className="text-xl"
        onClick={() => setShowIp(!showIp)}
      >
        {showIp ? <IoMdEye /> : <IoMdEyeOff />}
      </button>
    </div>
    <button
      type="button"
      className="mt-2 w-full sm:w-auto text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 font-medium rounded-lg text-sm px-3 py-1"
      onClick={() => setShowOtpModalIp(true)}
    >
      Send OTP
    </button>
  </div>

  {/* Info Section */}
  <div className="p-4 text-sm mt-4">
    <p>
      <span className="text-red-600">*</span> Last Pin Changed on
      _______________
    </p>
    <p>
      <span className="text-red-600">*</span> Last IP Changed on
      _______________
    </p>
  </div>

  {/* OTP Modal for PIN */}
  {showOtpModal && (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-[400px] h-auto">
        <h2 className="text-lg sm:text-xl mb-4">Enter OTP</h2>
        <input
          className="border border-blue-400 text-center p-2 mb-4 w-full"
          type="password"
          placeholder="Enter OTP"
          value={otp}
          onChange={handleOtpChange}
        />
        <div className="flex justify-between">
          <button
            type="button"
            className="text-white bg-green-500 hover:bg-green-700 rounded-lg text-sm px-5 py-2.5"
            onClick={handleChangePin}
          >
            Change PIN
          </button>
          <button
            type="button"
            className="text-white bg-red-500 hover:bg-red-700 rounded-lg text-sm px-5 py-2.5"
            onClick={() => setShowOtpModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )}

  {/* OTP Modal for IP Address */}
  {showOtpModalIp && (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-[400px] h-auto">
        <h2 className="text-lg sm:text-xl mb-4">Enter OTP</h2>
        <input
          className="border border-blue-400 text-center p-2 mb-4 w-full"
          type="password"
          placeholder="Enter OTP"
          value={otp}
          onChange={handleOtpChange}
        />
        <div className="flex justify-between">
          <button
            type="button"
            className="text-white bg-green-500 hover:bg-green-700 rounded-lg text-sm px-5 py-2.5"
            onClick={handleChangeIp}
          >
            Change IP
          </button>
          <button
            type="button"
            className="text-white bg-red-500 hover:bg-red-700 rounded-lg text-sm px-5 py-2.5"
            onClick={() => setShowOtpModalIp(false)}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  )}
</div>



    </motion.div>
  );
}

export default MasterPage;
