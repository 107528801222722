import React from 'react';
import { FaSkype } from 'react-icons/fa'; // Example React Icon
import DivTag from '../DivComponent/DivTag';
import HeadingTag from '../HeadingComponent/HeadingTag';
import ParagraphTag from '../ParagraphComponent/ParagraphTag';
import SpanTag from '../SpanComponent/SpanTag';
import ImgTag from '../ImageComponent/ImgTag';

const SalesMeetingComponent = ({ headingText, startTime, joinedCount, speakerAvatars }) => {
  return (
    <div className=" p-1 mt-2 rounded-lg shadow flex flex-col items-center justify-center">
      <div className="flex items-center justify-between">
        <DivTag>
          <HeadingTag className="text-lg font-semibold text-gray-800" text={headingText}></HeadingTag>
          <ParagraphTag className="text-gray-600 text-sm mb-0" text={`Start time: ${startTime}`}></ParagraphTag>
          <DivTag className="mt-2">
            <SpanTag className="inline-flex items-center px-2 py-1 text-xs font-semibold leading-none text-white bg-teal-500 rounded-full hover:bg-teal-600">
              <FaSkype className="mr-1" /> Skype
            </SpanTag>
          </DivTag>
        </DivTag>
        <DivTag className="text-right">
          <HeadingTag className="text-3xl font-bold text-green-500" text={joinedCount}></HeadingTag>
          <ParagraphTag className="text-green-500 text-sm" text="Joined"></ParagraphTag>
        </DivTag>
      </div>
      <hr className="my-1 border-gray-300" />
      <HeadingTag className="text-sm font-semibold text-blue-600 mb-1" text="Speaker"></HeadingTag>
      <DivTag className="flex -space-x-2">
        {speakerAvatars.slice(0, 4).map((avatar, index) => (
          <ImgTag key={index} alt="avatar" src={avatar} className="h-10 w-10 rounded-full border-2 border-white shadow-lg" />
        ))}
        {speakerAvatars.length > 4 && (
          <SpanTag className="flex items-center justify-center  rounded-full bg-blue-100 text-blue-600 text-sm font-semibold">
            +{speakerAvatars.length - 4} More
          </SpanTag>
        )}
      </DivTag>
      <DivTag className="widget overflow-hidden ">
      <ParagraphTag className="font-12 text-success-teal strong" text="Report is ready!"></ParagraphTag>
      <HeadingTag className="mb-2 font-15 strong" text="Download last 180 days of spending report"></HeadingTag>
      <span className="inline-block align-middle text-center select-none
       border font-normal whitespace-no-wrap rounded no-underline py-1 px-1
        leading-tight text-xs bg-gradient-primary text-white">
        Download
      </span>
      {/* <ITag className="las la-file-csv ab-rb text-success-teal" /> */}
    </DivTag>
    </div>
  );
};

export default SalesMeetingComponent;
