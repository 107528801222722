import React, { useEffect, useMemo, useState } from 'react';
import DivTag from '../../Components/DivComponent/DivTag';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import SpanTag from '../../Components/SpanComponent/SpanTag';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import TextAreaWithLabel from '../../Components/TextAreaWithLabel/TextAreaWithLabel';
import Button from '../../Components/ButtonComponent/Button';
import ButtonReport from '../../Components/ButtonComponent/ButtonReport';
import InputWithLabel from '../../Components/InputComponent/InputWithLabel';
import { retrivePackageByServiceIdService, retrivePackageService } from '../../../services/PackageList/PackageService';
import Option from '../../Components/OptionComponent/Option';
import { asignService } from '../../../services/apiaddservice';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';

const ActivatePopup = ({ onCloseActivatePop, serviceId }) => {

    const [packageData, setPackageData] = useState([]);
    const [activationData, setActivationData] = useState({
        'packageId': '',
        'serviceId': serviceId,
        'staffId': '',
        'userId': '',
        'postUrl': '',
        'remarks': ''

    })
    const handleChange = (e) => {
        setActivationData((prev) => {
            return {
                ...prev, [e.target.name]: e.target.value
            }
        })

    };


    async function RetrivePackageData() {
        try {
            const response = await retrivePackageByServiceIdService({ serviceId: serviceId });

            setPackageData(response.data);
        } catch (error) {
            return error;
        }
    }

    async function handleAssignData() {
        try {

            const response = await asignService({ formData: activationData });
            console.log(response)
            if (response.status === 403) {
                showErrorToast(response.data.message);
                return;
            }
            if (response.status === 422) {
                showErrorToast(response.data.message);
                return;
            }
            if (response.status.code === 200) {
                showSuccessToast(response.status.message);
                setActivationData({
                    'packageId': '',
                    'serviceId': '',
                    'staffId': '',
                    'userId': '',
                    'postUrl': '',
                    'remarks': ''
                })
                onCloseActivatePop();
            }
        } catch (error) {
            showErrorToast("An error occurs while assign");
            return;
        }
    }
    useEffect(() => {
        RetrivePackageData();
    }, [])
    return (
        <DivTag className="fixed inset-0 flex items-start justify-center bg-gray-800 bg-opacity-50 z-50">
            <DivTag className="bg-white rounded-lg shadow-lg w-full max-w-lg mt-20">
                <DivTag className="modal-header flex justify-between items-center p-4 border-b border-gray-200">
                    <HeadingTag className="text-xl font-semibold text-red-700">Activate</HeadingTag>
                    <Button type="button" className="text-gray-500 hover:text-gray-700" onClick={onCloseActivatePop}>
                        <SpanTag aria-hidden="true">&times;</SpanTag>
                    </Button>
                </DivTag>

                <DivTag className="p-4">
                    <InputWithLabel
                        DivclassName="w-full mb-4"
                        label="Enter Api Userid"
                        type="text"
                        name="userId"
                        id="UserId"
                        placeholder={"API03930"}
                        value={activationData.userId?.toUpperCase()}
                        onChange={handleChange}
                        required={true}

                    />
                    <InputWithLabel
                        DivclassName="w-full mb-4"
                        label="Enter URL"
                        type="text"
                        name="postUrl"
                        id="Enter_url"
                        value={activationData.postUrl}
                        onChange={handleChange}
                        required={true}

                    />

                    <SelectWithLabelOption
                        DivclassName="w-full mb-4"
                        label="Select package"
                        id="Select_sales_emp"
                        name="packageId"
                        value={activationData.packageId}
                        onChange={handleChange}
                        required={true}

                    >
                        <option value="">Select Package</option>
                        {packageData.length > 0 ?
                            packageData.map((item, index) => (
                                <Option key={index} value={item.package_id}>{item.package_name}</Option>

                            )) : ''
                        }
                    </SelectWithLabelOption>
                    <SelectWithLabelOption
                        DivclassName="w-full mb-4"
                        label="Select Staff"
                        id="staffId"
                        name="staffId"
                        value={activationData.staffId}
                        onChange={handleChange}
                        required={true}

                    >
                        <option value="">Select Staff</option>

                        <Option value=""></Option>


                    </SelectWithLabelOption>
                    <TextAreaWithLabel
                        DivclassName="w-full mb-4"
                        label="Remarks/UseCase"
                        type="text"
                        placeholder="Enter Remark"
                        name="remarks"
                        value={activationData.remarks}
                        onChange={handleChange}
                        required={true}

                    />
                </DivTag>

                <DivTag className="flex justify-center p-4 border-t border-gray-200">
                    <ButtonReport
                        type="button"
                        onClick={handleAssignData}
                        className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg px-4 py-2 text-lg"
                        text="Activate Now"
                    />
                </DivTag>
            </DivTag>
        </DivTag>
    );
};

export default ActivatePopup;
