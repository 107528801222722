import React from 'react';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import DivTag from '../../Components/DivComponent/DivTag';

const ReportHeading = ({ name }) => {
    return (
        <DivTag className="flex justify-center">
        <HeadingTag className="text-center text-white bg-blue-700 shadow-lg bg-gradient-to-r
         from-blue-900 via-teal-400 to-purple-900 text-xl font-semibold mt-3
          p-1 rounded-md">{name}</HeadingTag>
          </DivTag>
    );
}

export default ReportHeading;
