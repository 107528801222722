import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {

  const handleNextClick = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handlePreviousClick = (e) => {
    e.preventDefault(); // Prevent the default link behavior
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  return (
    <nav className=''>
      <ul className='pagination flex items-center gap-4 my-3 justify-center mb-3'>
        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
          <a
            onClick={handlePreviousClick}
            href='!#'
            className='page-link bg-green-700 p-1 rounded-md text-white'
          >
            Previous
          </a>
        </li>
        <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
          <a
            onClick={handleNextClick}
            href='!#'
            className='page-link bg-green-700 p-1 rounded-md text-white'
          >
            Next
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
