import React from 'react';
import DivTag from '../DivComponent/DivTag';
import HeadingTag from '../HeadingComponent/HeadingTag';
import ITag from '../IconComponent/Icons';
import SpanTag from '../SpanComponent/SpanTag';
import ATag from '../Anchorlink/ATag';

const SalesSummaryWidget = () => {
  const iconClass = [
    "las la-chart-bar text-purple-600 animate-bounce",
    "las la-file-invoice-dollar text-blue-500 animate-bounce",
    "las la-file-invoice-dollar text-yellow-500 animate-bounce",
    "las la-hand-holding-usd text-green-500 animate-bounce"
  ];

  const data = [
    { label: "Sales", value: "$8,190", progress: 70, progressClass: "bg-purple-500", progressBgClass: "bg-purple-300" },
    { label: "Revenue", value: "$4,290", progress: 50, progressClass: "bg-blue-500", progressBgClass: "bg-blue-300" },
    { label: "Budget", value: "$3,333", progress: 40, progressClass: "bg-yellow-500", progressBgClass: "bg-yellow-300" },
    { label: "Income", value: "$2,571", progress: 35, progressClass: "bg-green-500", progressBgClass: "bg-green-300" }
  ];

  return (
    <DivTag className="widget widget-activity-four mt-4 p-4 bg-white shadow-lg rounded-lg">
      <DivTag className="widget-heading mb-4">
        <HeadingTag className="text-cyan-500 font-semibold text-xl">Sales Summary</HeadingTag>
      </DivTag>
      <DivTag className="widget-content">
        {data.map((item, index) => (
          <DivTag className="sales-summary-content flex items-center mb-4" key={index}>
            <DivTag className="sales-summary-icon flex-shrink-0 mr-4">
              <ITag className={`${iconClass[index]} text-3xl`}></ITag>
            </DivTag>
            <DivTag className="sales-progress flex-grow">
              <DivTag className="flex justify-between items-center mb-1">
                <SpanTag className="font-medium text-gray-700">{item.label}</SpanTag>
                <SpanTag className="font-medium text-gray-700">{item.value}</SpanTag>
              </DivTag>
              <DivTag className="w-full h-2 bg-gray-200 rounded-full">
                <DivTag
                  className={`h-full rounded-full ${item.progressClass}`}
                  role="progressbar"
                  aria-valuenow={item.progress}
                  style={{ width: `${item.progress}%` }}
                />
              </DivTag>
            </DivTag>
          </DivTag>
        ))}
        <ATag
          className="block text-center w-full bg-blue-600 text-white font-medium py-2 rounded mt-6 hover:bg-blue-700 transition duration-300">
          View Full Report
        </ATag>
      </DivTag>
    </DivTag>
  );
};

export default SalesSummaryWidget;
