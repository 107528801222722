import { getRequest, postRequest } from "../config/AxiosConfig";
import API_URLS from "../config/BackendConfig";
import { getAuthToken } from "../utils/tokenUtils";

export const CustomerListService = async ({ aadhar_number, pan_number, mobile, userid, company_name, total_data, currentPage }) => {
    try {
        const payload = {
            'aadhar_number': aadhar_number,
            'pan_number': pan_number,
            'mobile': mobile,
            'user_id': userid,
            'company_name': company_name,
            'total_data': total_data,
            'page': currentPage
        }

        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "customers",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const AddAmountWalletService = async ({ userId, amount }) => {
    try {
        const payload = {
            'user_id': userId,
            'amount': amount
        }
        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "add-balance",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const DeductAmountWalletService = async ({ userId, amount }) => {
    try {
        const payload = {
            'user_id': userId,
            'amount': amount
        }
        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "deduct-balance",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response;
    } catch (error) {
        return error;
    }
}
export const RetriveWalletReportService = async (filterData, currentPage) => {
    try {
        const payload = {
            from_date: filterData.from_date,
            to_date: filterData.to_date,
            status: filterData.status,
            user_id: filterData.user_id,
            page: filterData.page,
            total_data: filterData.total_data,
            page: currentPage
        }

        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "wallet/report",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response;
    } catch (error) {
        return error;
    }
}



export const getfundRequestReportService = async (filterData, currentPage) => {
    try {
        const payload = {
            'total_data': filterData.total_data,
            'from_date': '',
            'to_date': '',
            'status': '',
            'user_id': filterData.user_id

        }

        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "fund/report",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const getpaySlipPreviewService = async (filename) => {
    try {


        const token = getAuthToken();
        const response = getRequest({
            url: API_URLS.ADMIN + "admin-fund-slip/" + filename,

            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response;
    } catch (error) {
        return error;
    }
}


export const handleFormSTatusService = async (fundId, formStatus, remarks) => {
    try {
        const payload = {
            'fund_id': fundId,
            'status': formStatus,
            'remarks': remarks
        }

        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "fund/action",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response;
    } catch (error) {
        return error;
    }
}

export const verifyPinsService = async (pin) => {
    try {
        const payload = {
            pin: pin
        }
        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "validate-pin",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        });
        return response;
    } catch (error) {
        return error
    }
}