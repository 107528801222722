
import React from 'react';

const ParagraphTag = ({children, className, text }) => (
  
    <p
      className={className}
      >
        {children}
      {text}
  </p>
);

export default ParagraphTag;


