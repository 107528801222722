// Downloading function starts here
export const downloadExcel = (headers, tableData) => {
    // Add headers to CSV data
    let csvData = headers.join(',') + '\n'; // Manually add headers
  
    // Format and append each row
    tableData.forEach((row, index) => {
        const rowData = headers.map(header => {
            let value = row[header];
            // Handle date formatting if needed
            return value;
        }).join(',');
        csvData += `${rowData}\n`; // Append each row
    });
  
    // Create a blob object from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
  
    // Create a temporary anchor element and trigger download
    const link = document.createElement('a');
    if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'table_data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } else {
        alert('Your browser does not support downloading files.');
    }
};
// Downloading Function Ends Here


  export default downloadExcel;