import React from 'react';
import { FaFolderOpen, FaFileImage, FaFilm } from 'react-icons/fa';

const StorageView = () => {
  return (
    <div className="mx-auto p-4 bg-white rounded-lg shadow-lg border border-gray-200 max-w-lg">
      <div className="flex justify-between items-center mb-4">
        <div>
          <h2 className="text-xl font-bold text-gray-800">My Files</h2>
          <p className="text-sm text-gray-500">Manage your files</p>
        </div>
        <a
          href="#"
          className="text-sm text-green-500 hover:text-green-600 font-semibold"
        >
          See all
        </a>
      </div>
      <div className="space-y-4">
        <div className="flex justify-between items-center py-2 border-b border-gray-200">
          <div className="flex items-center">
            <div className="flex items-center justify-center w-12 h-12 bg-yellow-100 text-yellow-500 rounded-lg mr-3">
              <FaFileImage size={24} />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-800">Photos</h3>
              <p className="text-sm text-gray-500">44 photos</p>
            </div>
          </div>
          <p className="text-sm text-gray-700 font-medium">128 GB</p>
        </div>

        <div className="flex justify-between items-center py-2 border-b border-gray-200">
          <div className="flex items-center">
            <div className="flex items-center justify-center w-12 h-12 bg-green-100 text-green-500 rounded-lg mr-3">
              <FaFolderOpen size={24} />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-800">Folders</h3>
              <p className="text-sm text-gray-500">33 folders</p>
            </div>
          </div>
          <p className="text-sm text-gray-700 font-medium">256 GB</p>
        </div>

        <div className="flex justify-between items-center py-2">
          <div className="flex items-center">
            <div className="flex items-center justify-center w-12 h-12 bg-blue-100 text-blue-500 rounded-lg mr-3">
              <FaFilm size={24} />
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-800">Movies</h3>
              <p className="text-sm text-gray-500">3 videos</p>
            </div>
          </div>
          <p className="text-sm text-gray-700 font-medium">3 GB</p>
        </div>
      </div>
    </div>
  );
};

export default StorageView;
