// Label.js
import React from 'react';

const Label = ({ htmlFor, children, className ,text}) => {
  return (
    <label
     htmlFor={htmlFor}
      className={className}
      >
      {children}
      {text}
    </label>
  );
};

export default Label;
