
import React from 'react';

const DivTag = ({children, className, datasitekey, style, ariaValuenow , dataToggle, ariaHaspopup ,ariaExpanded, id}) => (
  <div
    className={className} 
    data-sitekey={datasitekey}
    data-toggle={dataToggle}
    aria-haspopup={ariaHaspopup}
    aria-expanded={ariaExpanded}
    style={style}
    aria-valuenow={ariaValuenow}
    id={id}
  >
    {children}
  </div>
);
export default DivTag;
