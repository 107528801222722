import React, { useState } from 'react';
import DivTag from '../Components/DivComponent/DivTag';
import HeadingTag from '../Components/HeadingComponent/HeadingTag';
import SpanTag from '../Components/SpanComponent/SpanTag';
import InputWithLabel from '../Components/InputComponent/InputWithLabel';
import SelectWithLabelOption from '../Components/SelectComponent/SelectWithLabelOption';
import TextAreaWithLabel from '../Components/TextAreaWithLabel/TextAreaWithLabel';
import Button from '../Components/ButtonComponent/Button';
import { motion } from 'framer-motion';

const PartnerActionPopup = ({ onCloseActionPop , visible, className}) => {
    const dropIn = {
        hidden: {
            y: "-100vh",
            opacity: 0,
        },
        visible: {
            y: "0",
            opacity: 1,
            transition: {
                duration: 0.1,
                type: "spring",
                damping: 25,
                stiffness: 500,
            },
        },
        exit: {
            y: "100vh",
            opacity: 0,
        },
    };

 
    return (
        <motion.div
        initial="hidden"
        animate={visible ? "visible" : "hidden"}
        exit="exit"
        variants={dropIn}
        className={` shadow-lg rounded-lg border-2 backdrop-blur-2xl bg-neutral-50  border-blue-700 w-full md:h-[36%] h-[42%]  max-w-lg fixed inset-0 m-auto p-1 ${className}`}
    >
       
                <DivTag className="flex justify-between items-center p-1 border-b border-gray-200">
                    <HeadingTag className="text-lg font-semibold text-red-700" text="Confirmation" />
                    <Button type="button" className="text-gray-500 hover:text-gray-700" onClick={onCloseActionPop}>
                        <SpanTag aria-hidden="true" className="text-white px-2 hover:bg-blue-800 hover:text-white text-2xl bg-red-700 rounded-full">&times;</SpanTag>
                    </Button>
                </DivTag>

                <DivTag className="p-2">

                <TextAreaWithLabel
                        DivclassName="w-full mb-4"
                        label="Remarks"
                        type="text"
                        placeholder="Enter Remark"
                        name="remark"
                        value=""
                        onChange=""
                        required={true}
                        class_name='border border-blue-600 rounded-lg p-2'
                    />

                    <div className='flex '>
                    <SelectWithLabelOption
                        DivclassName="w-1/2 mb-4"
                        label="Select Type"
                        id="select_type"
                        name="select_type"
                        value=""
                        onChange=""
                        required={false}
                        className='border-blue-600 rounded-lg p-2'
                    >
                        <option value="" selected disabled>Select Type</option>
                        <option value="Approve">Approve</option>
                        <option value="Reject">Reject</option>
                    </SelectWithLabelOption>


                    <InputWithLabel
                            DivclassName="w-1/2 md:w-1/2 px-2 md:mb-0"
                            label="Business Name"
                            type="text"
                            name="business_name"
                            id="business_name"
                            placeholder="Enter Business Name"
                            value=""
                            onChange=""
                            onBlur=""
                            className='border border-blue-600'
                        />
                        </div>
                </DivTag>

                <DivTag className="flex justify-center border-t border-gray-200 mb-3">
                    <Button
                        type="button"
                        className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white rounded-md px-1 py-0 text-md"
                        onClick=""
                    >
                        Submit
                    </Button>
               
        </DivTag>
        </motion.div>
    );
};

export default PartnerActionPopup;
