import { postRequest } from "../config/AxiosConfig"; // Adjust the import path as needed
import { showSuccessToast, showErrorToast } from "../utils/toastUtils";
import { getAuthToken, removeauthToken, storeToken } from "../utils/tokenUtils";
import API_URLS from "../config/BackendConfig";
import Cookies from 'js-cookie';


export const logoutService = async () => {
  const token = getAuthToken();
  const response = await postRequest({
    url: `${API_URLS.ADMIN}logout`,
    headers: {
      Authorization: `Bearer ${token}`, 
    },
  });
  return response

};


export const login = async ({ user_id, password }) => {
  try {
    const response = await postRequest({
      url: API_URLS.ADMIN + "login",
      data: { user_id, password },
    });

    const data = response;
    if (data.accessToken) {
      const token = data.accessToken;
      storeToken(token);
      return {
        success: true,
        data: data,
        message: "Logged In Successfully!",
      };
    }

    return {
      success: false,
      data: data,
    };
  } catch (error) {
    return {
      success: false,
      data: null,
      message: "Invalid",
    };
  }
};


// src/services/authService.js
export const getCurrentUser = () => {
  // This function should return the current user or null if not authenticated
  const user = JSON.parse(Cookies.get('user'));
  return user ? user : null;
};




const handleAuthError = (error) => {
  if (error.response) {
    const errorMessage = error.response.data.message || "Login failed!";
    showErrorToast(`Error ${error.response.status}: ${errorMessage}`);
  } else if (error.request) {
    showErrorToast("No response received from server");
  } else {
    showErrorToast(`Error: ${error.message}`);
  }
};





export const verifyPin = async (pin, tempToken) => {
  try {
    const response = await postRequest({
      url: `${API_URLS.ADMIN}verify-pin`,
      data: { pin, temp_token: tempToken },
      headers: {
        Authorization: `Bearer ${tempToken}`, // Confirm if this is needed
      },
    });

    console.log("Response:", response); // Log the entire response

    if (response.token) {
      const token = response.token;
      Cookies.set('authToken', token, { expires: 0.125 }); // 3 Hour
      //console.log("Token saved:", token); // Log token to verify
      showSuccessToast("PIN Verified Successfully");
      return {
        success: true,
        message: "PIN Verified Successfully",
        token, // Return the token if needed
      };
    }

    showErrorToast(response.message || "Invalid PIN");
    return {
      success: false,
      message: response.message || "Invalid PIN",
    };
  } catch (error) {
    console.error("Error details:", error.response ? error.response.data : error.message);
    showErrorToast("Server error occurred while verifying PIN.");
    return {
      success: false,
      message: "Server error occurred while verifying PIN.",
    };
  }
};


export const getAdminProfile=async()=>{
  try {
    const token=getAuthToken();
    const res = await postRequest({
      url: API_URLS.ADMIN + `profile`,
      headers: {
          'Authorization': `Bearer ${token}`, "Content-Type": "multipart/form-data",
      },
  });
  return res;
  } catch (error) {
    
  }
}





