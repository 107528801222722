import React from 'react';
import { FaArrowUp } from 'react-icons/fa';  // Example icon, you can replace it with any React Icon
import SpanTag from '../SpanComponent/SpanTag';
import ITag from '../IconComponent/Icons';
import DivTag from '../DivComponent/DivTag';
import ATag from '../Anchorlink/ATag';
import HeadingTag from '../HeadingComponent/HeadingTag';
import ParagraphTag from '../ParagraphComponent/ParagraphTag';

const QuickCategoryComponent = ({ iconClassName, comparisonText, comparisonIconClassName, categoryNumber, categoryText, iconColor }) => {
  return (
    <DivTag className="xl:w-1/4 lg:w-1/5 md:w-1/5 sm:w-full w-full mt-1 mb-1 p-4 bg-gradient-to-r from-blue-500 via-purple-500 to-pink-500 rounded-lg shadow-lg transform transition-transform duration-500 hover:scale-105">

      <ATag className="widget quick-category">
        <DivTag className="quick-category-head flex items-center">
          <SpanTag className="quick-category-icon qc-primary rounded-full p-3 bg-white text-xl flex items-center justify-center" style={{ color: iconColor }}>
            <FaArrowUp className={iconClassName} />  {/* Example icon, use your icon here */}
          </SpanTag>
          <DivTag className="ml-auto">
            <DivTag className="quick-comparison qcompare-success flex items-center text-white">
              <SpanTag className="text-lg font-semibold">{comparisonText}</SpanTag>
              <FaArrowUp className={`${comparisonIconClassName} ml-2`} />  {/* Example icon, use your icon here */}
            </DivTag>
          </DivTag>
        </DivTag>
        <DivTag className="quick-category-content mt-4 text-center">
          <HeadingTag className="text-4xl font-bold text-white">{categoryNumber}</HeadingTag>
          <ParagraphTag className="font-medium text-lg text-white mt-2">{categoryText}</ParagraphTag>
        </DivTag>
      </ATag>
    </DivTag>
  );
};

export default QuickCategoryComponent;
