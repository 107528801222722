import React from 'react';
import Button from '../../Components/ButtonComponent/Button';
import DivTag from '../../Components/DivComponent/DivTag';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import SpanTag from '../../Components/SpanComponent/SpanTag';
import TextAreaWithLabel from '../../Components/TextAreaWithLabel/TextAreaWithLabel';

const ModalApproveAction = ({ closeModalApproveAction }) => {

  // Function to handle updating and saving the page data
  const handleUpdate = () => {

    // Close the popup
    closeModalApproveAction();
  };
  const handleUpdate_two = () => {
    if(document.getElementById('remarks_main_0473').value ==''){
      document.getElementById('error_message').classList.remove('d-none');
    }else{
      document.getElementById('error_message').classList.add('d-none');
      closeModalApproveAction();
    }
    // Close the popup
    //closeModalApproveAction();
  };

  return (
    <DivTag className="modal fade show ml-28" style={{ display: 'block' }}>
      <DivTag className="modal-dialog border-2 border-solid border-blue-800 rounded-xl">
        <DivTag className="modal-content">
          <DivTag className="modal-header">
            <HeadingTag className="text-red-700">Confirmation</HeadingTag>
            <Button type="button" className="close" aria-label="Close" onClick={closeModalApproveAction}>
              <SpanTag aria-hidden="true">&times;</SpanTag>
            </Button>
          </DivTag>
          <DivTag className="modal-body flex flex-col items-center">
           
          <TextAreaWithLabel
            DivclassName="w-full px-2 md:mb-0 mt-2"
              label="Remarks"
              type="text"
              required
              placeholder="Enter Remarks"
              name="remarks_main_0473"
              id="remarks_main_0473"
            />
          <SpanTag id='error_message' className='text-danger d-none'>Please Enter Remarks</SpanTag>
          </DivTag>
          <DivTag className="flex justify-center mb-2">
            <Button
            type="submit"
            id="approve"
            name="approve"
              className="text-white bg-green-700 p-1 text-xs rounded-md"
              onClick={handleUpdate_two}
            >
              Approve
            </Button>
            <Button
            type="submit"
            id="reject"
            name="reject"
              className="text-white bg-red-600 p-1 text-xs rounded-md ms-3"
              onClick={handleUpdate}
            >
              Reject
            </Button>
          </DivTag>
          
        </DivTag>
      </DivTag>
    </DivTag>
  );
};

export default ModalApproveAction;




















