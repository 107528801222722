import React, { useState, useEffect } from 'react';
import DivTag from '../Components/DivComponent/DivTag';
import Button from '../Components/ButtonComponent/Button';
import FormValidation from '../Components/FormValidation/FormValidation';
import InputWithLabel from '../Components/InputComponent/InputWithLabel';
import HeadingTag from '../Components/HeadingComponent/HeadingTag';
import SelectWithLabelOption from '../Components/SelectComponent/SelectWithLabelOption';
import ReportHeading from '../Components/ReportHeadings/ReportHeadings';
import Option from '../Components/OptionComponent/Option';
import SearchApiUserFiled from '../Components/SearchApiUserComponent/SearchApiUserFiled';
import { motion } from 'framer-motion';
import printTable from '../Components/TablePrintExcelPdfSearch/tablePrint';
import downloadExcel from '../Components/TablePrintExcelPdfSearch/downloadExcel';
import downloadPdf from '../Components/TablePrintExcelPdfSearch/downloadPdf';
import InputField from '../Components/InputComponent/InputField';
import 'jspdf-autotable'; // Import AutoTable plugin
import Seo from '../../components/SeoComponent/Seo';
import { RiErrorWarningLine } from "react-icons/ri";
import PartnerActionPopup from './PartnerActionPopup';


const PartnerShip = () => {   
const [partnerData, setPartnerData] = useState([]);
const [isModalOpenActionPopup, setIsModalActionPop] = useState(false);


    const initialState = {
        from_date: '',
        to_date: '',
        status: '',
        partner_id: '',
        mobile: ''
    };


    // Function to open the modal
    const openActionPopup = () => {
        setIsModalActionPop(true);
    };

    // Function to close the modal
    const onCloseActionPop = () => {
        setIsModalActionPop(false);
    };



    const validate = (values) => {
        let errors = {};

        // Add validation logic here
        if (!values.from_date) {
            errors.from_date = 'From Date is required';
        }
        if (!values.to_date) {
            errors.to_date = 'To Date is required';
        }
        
        if (!values.status || values.status === '') {
            errors.status = 'Status is required';
        }
        if (!values.partner_id) {
            errors.partner_id = 'PartnerId is required';
        }
        if (!values.mobile){
            errors.mobile = 'Mobile is Required';
        }
        return errors;
    };

    const { values, errors, handleChange, handleBlur } = FormValidation(
        initialState,
        validate
    );


// Printing function
const handlePrint = () => {
    printTable('table');
};

    return (

        <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
    >
        <Seo />
   <DivTag className=" mt-8 bg-white">
            <ReportHeading name="PartnerShip" />
                <DivTag className="flex justify-center items-center">
                    <DivTag className="flex flex-wrap mt-3">
                        <InputWithLabel
                            DivclassName="w-1/2 md:w-1/6 px-2 md:mb-0"
                            label="From Date"
                            type="date"
                            name="from_date"
                            id="from_date"
                            value={values.from_date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.from_date ? 'border border-red-500' : 'border border-blue-600'}
                            error={errors.from_date}
                        />

                        <InputWithLabel
                            DivclassName="w-1/2 md:w-1/6 px-2 md:mb-0"
                            label="To Date"
                            type="date"
                            name="to_date"
                            id="to_date"
                            value={values.to_date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.to_date ? 'border border-red-500' : 'border border-blue-600'}
                            error={errors.to_date}
                        />

                        <SelectWithLabelOption
                            DivclassName="w-1/2 md:w-1/5 px-2 md:mb-0"
                            label="Status"
                            id="status"
                            name="status"
                            value={values.status}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.status ? 'border border-red-500' : 'border border-blue-600'}
                            error={errors.status}
                        >
                            <Option value="">Select Status</Option>
                            <Option value="Active">Active</Option>
                            <Option value="Block">Block</Option>
                            <Option value="Pending">Pending</Option>
                        </SelectWithLabelOption>
                       

                        <InputWithLabel
                            DivclassName="w-1/2 md:w-1/5 px-2 md:mb-0"
                            label="PartnerID"
                            type="text"
                            placeholder="Enter PartnerID"
                            name="partner_id"
                            id="partner_id"
                            value={values.partner_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.partner_id ? 'border border-red-500' : 'border border-blue-600'}
                            error={errors.partner_id}
                        />

                        <InputWithLabel
                            DivclassName="w-1/2 md:w-1/6 px-2 md:mb-0"
                            label="Mobile"
                            type="text"
                            name="mobile"
                            id="mobile"
                            placeholder="Enter Mobile No."
                            value={values.mobile}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.mobile ? 'border border-red-500' : 'border border-blue-600'}
                            error={errors.mobile}
                        />
                    </DivTag>
                </DivTag>

                <DivTag className="flex justify-center mt-2">
                    <Button
                        type="submit"
                        className="text-center text-white bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900
                         via-teal-400 to-purple-900 font-semibold p-0 rounded-md px-2 py-1 text-lg hover:bg-red-300"
                        onClick="">
                        Search
                    </Button>
                </DivTag>

                <HeadingTag className="text-2xl text-blue-800 font-bold bg-white text-center mt-2 mb-2" text="Report"/>

                <DivTag className="overflow-x-auto my-1 p-2">
                       <table id="table" className="min-w-full divide-y divide-gray-200">
                            <thead className="text-md">
                                <tr className="bg-blue-800 text-white text-center">
                                    <th className="border-2 p-2">S No.</th>
                                    <th className="border-2">Doc</th>
                                    <th className="border-2">Request Date</th>
                                    <th className="border-2">Comp Name</th>
                                    <th className="border-2">Email</th>
                                    <th className="border-2">Mobile</th>
                                    <th className='border-2'>Address</th>
                                    <th className='border-2'>Action</th>
                                    <th className='border-2'>RefferalID</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {/* {partnerData.map((item, index) => ( */}
                                    <tr key="" className='text-center'>
                                        <td>1</td>
                                        <td>12/12/2025</td>
                                        <td>11/11/2020</td>
                                        <td>IDSPay</td>
                                        <td>id@gmail.com</td>
                                        <td>9172563334</td>
                                        <td>muz.</td>
                                        <td><RiErrorWarningLine className="ml-7 text-blue-700" onClick={openActionPopup} size={24} /></td>
                                        <td>Ref9787</td>
                                     </tr>
                                {/* ))} */}
                            </tbody>
                        </table>
                    </DivTag> 
            </DivTag>
            {isModalOpenActionPopup && <PartnerActionPopup visible={isModalOpenActionPopup} onCloseActionPop={onCloseActionPop}  />}
</motion.div>
  
        );
    };
    
    export default PartnerShip;
