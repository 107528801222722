import React, { useEffect, useState } from 'react';
import ReportHeading from '../../Components/ReportHeadings/ReportHeadings';
import { FaInbox } from 'react-icons/fa';
import { getfundRequestReportService, getpaySlipPreviewService, handleFormSTatusService } from '../../../services/walletservice';
import Loader from '../../../components/Loader/Loader';
import PopMenu from '../../../components/Dialog/PopMenu';
import InputWithLabel from '../../Components/InputComponent/InputWithLabel';
import Button from '../../Components/ButtonComponent/Button';
import { showSuccessToast } from '../../../utils/toastUtils';
import ImageSketlton from '../../../components/Loader/ImageSkelton';

const FundApprove = () => {
  const [fundRequestReport, setFundRequestReport] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [totaldata, setTotalData] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [approvePopUp, setApprovePopUp] = useState(false);
  const [rejectPopUp, setRejectPopUp] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState('');
  const [amount, setAmount] = useState();
  const [selectedName, setSelectedName] = useState('');
  const [paySlip, setPaySlip] = useState('');
  const [filename, setFileName] = useState('');
  const [isImagePreview, setIsImagePreview] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [selectedFundId, setSelectedFundId] = useState('');
  const [apiId, setApiId] = useState('');
  async function getPaySlipPreview() {
    setIsImagePreview(true);
    const response = await getpaySlipPreviewService(filename);

    setPaySlip(response.dataUri);
    setIsImagePreview(false);
  }
  async function getFundRequestReport() {
    try {
      setIsLoading(true);
      const filterData = {
        total_data: totaldata,
        user_id: apiId
      }
      const response = await getfundRequestReportService(filterData, 1);


      setIsLoading(false)
      setFundRequestReport(response.data.data);
      setPaginationData(response.pagination)
      return;

    } catch (error) {
      setIsLoading(false);
      console.log(error);
      return;
    }
  }
  async function handleFundStatus(statuus) {
    try {
      const response = await handleFormSTatusService(selectedFundId, statuus, remarks);

      if (response.status.code === 200) {
        showSuccessToast(response.message);
        setFileName('');
        setPaySlip('')
        setSelectedFundId('');
        setRemarks('')
        setApprovePopUp(false);
        getFundRequestReport()
        return;
      }
    } catch (error) {

    }
  }
  useEffect(() => {
    getPaySlipPreview()
  }, [approvePopUp])
  useEffect(() => {
    getFundRequestReport()
  }, [totaldata])

  function getTotalFundDataCountByType(typesName) {
    if (!fundRequestReport || fundRequestReport.length === 0) {
      return 0;
    }

    const filteredReports = fundRequestReport.filter(curr => curr.status && curr.status.value === typesName);
    return filteredReports.length;
  }
  function getTotalFundDataAmountByType(typesName) {
    if (!fundRequestReport || fundRequestReport.length === 0) {
      return 0;
    }

    const totalAmount = fundRequestReport.reduce((acc, curr) => {
      if (typesName === "") {
        return acc + (parseFloat(curr.amount) || 0);
      }
      if (curr.status && curr.status.value === typesName) {
        return acc + (parseFloat(curr.amount) || 0); // Make sure to handle cases where amount may be missing or null
      }
      return acc;
    }, 0);

    return totalAmount;
  }

  return (


    <div className="p-4 mt-6 bg-white overflow-hidden ">
      {/* Header */}
      {isLoading && <Loader />}
      <div className="flex justify-center mb-8">
        <ReportHeading name="Fund Approve " />
      </div>


      {/* Fund Summary Boxes */}
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {[
          { label: 'Total Fund Request', value: fundRequestReport && fundRequestReport?.length },
          { label: 'Total Approved Fund', value: getTotalFundDataCountByType('approved') },
          { label: 'Total Rejected Fund', value: getTotalFundDataCountByType('rejected') },
          { label: 'Total Pending Form', value: getTotalFundDataCountByType('pending') },
          { label: 'Total Fund Request Amount', value: '₹' + getTotalFundDataAmountByType('') },
          { label: 'Total Approved Fund Amount', value: '₹' + getTotalFundDataAmountByType('approved') },
          { label: 'Total Rejected Fund Amount', value: '₹' + getTotalFundDataAmountByType('rejected') },
          { label: 'Total Pending Fund Amount', value: '₹' + getTotalFundDataAmountByType('pending') },
        ].map((box, index) => (
          <div
            key={index}
            className="bg-white shadow-md p-4 rounded-md text-center border"
          >
            <h3 className="text-gray-700 font-semibold">{box.label}</h3>
            <p className="text-xl text-green-600 font-bold">{box.value}</p>
          </div>
        ))}
      </div>

      {/* Filter Fund By Status */}
      <div className="mt-6 flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-4">
        <div className="flex-grow">
          <select className="border border-gray-300 p-2 rounded-md w-full sm:w-auto" onChange={(e) => setTotalData(e.target.value)}>
            <option value="#" selected disabled>Total Data</option>
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="200">200</option>
            {/* Add more filter options here */}
          </select>
        </div>
        <div className='flex justify-center items-center gap-3 uppercase'>
          <InputWithLabel value={apiId} onChange={(e) => setApiId(e.target.value)} label={"Search with apiid"} placeholder={'EX:APID***'} type={'text'} DivclassName={'!uppercase'} />
          <button className="bg-blue-900 h-9 mt-5 text-white p-2 rounded-md" onClick={getFundRequestReport}>
            Search
          </button>
        </div>
      </div>

      <PopMenu onClose={() => {
        setFileName('');
        setPaySlip('')
        setApprovePopUp(false);
      }} visible={approvePopUp} title={"Fund Approve"}
        className={'!h-[90%] overflow-y-auto w-1/3 overflow-x-hidden'}>
        <div>
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-1 justify-around my-4">
            <p className="text-sm font-bold text-blue-900 text-center">User Id: {selectedUserId}</p>
            <p className="text-sm font-bold text-blue-900  text-center">User Name: {selectedName}</p>
            <p className="text-sm font-bold text-blue-900  text-center">Amount: {amount}</p>

          </div>

          {isImagePreview ? <ImageSketlton /> : <img src={paySlip} alt="" className='h-3/4 w-full' />}
          <div>
            {/* <select name="" id="" className='h-12 w-full shadow my-5 outline-none' value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>

              <option value="#" disabled selected>Select Status</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
            </select> */}
            <div className='h-40 w-full shadow flex flex-col my-5 border  rounded'>
              <label htmlFor="">Remarks</label>
              <textarea name="remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)} id="" className='h-40 border-slate-400 border-1 outline-none '></textarea>
            </div>
            <div className='flex justify-around px-3'>
              <Button type={"button"} onClick={() => handleFundStatus('rejected')} text={"Reject"} className={'bg-red-800 rounded-sm text-white py-2 px-4 font-semibold my-2'} />
              <Button type={"button"} onClick={() => handleFundStatus('approved')} text={"Approve"} className={'bg-green-500 rounded-sm text-white  py-2 px-4 font-semibold my-2 '} />
            </div>
          </div>

        </div>
      </PopMenu>

      {/* Fund Request Table */}
      <div className="mt-6 overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-md">
          <thead className="bg-blue-600 text-white">
            <tr>
              {['SN.', 'USR_id ', 'Name', 'Mobile', 'Amount', 'UTR', 'Order_id', 'Status', 'Remarks', 'Request_date', 'Action'].map(
                (heading, index) => (
                  <th key={index} className="p-2 text-left whitespace-nowrap">
                    {heading}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {
              fundRequestReport.length > 0 ?

                fundRequestReport.map((fund, index) => (
                  <tr key={index}>
                    <td className="p-1 whitespace-nowrap text-sm font-medium text-gray-900 border border-black text-center"> {index + 1} </td>
                    <td className="p-1 whitespace-nowrap text-sm font-medium text-gray-900 border border-black text-center"> {fund?.user.user_id} </td>
                    <td className="p-1 whitespace-nowrap text-sm font-medium text-gray-900 border border-black text-center"> {fund?.user.name} </td>
                    <td className="p-1 whitespace-nowrap text-sm font-medium text-gray-900 border border-black text-center"> {fund?.user.mobile_number} </td>
                    <td className="p-1 whitespace-nowrap text-sm font-medium text-gray-900 
                    border border-black text-center"> {fund?.amount} </td>
                    <td className="p-1 whitespace-nowrap text-sm font-medium text-gray-900 border border-black text-center"> {fund?.transaction_ids[0].utr} </td>

                    <td className="p-1 whitespace-nowrap text-sm font-medium text-gray-900 border border-black text-center"> {fund?.transaction_ids[0].order_id} </td>
                    <td className={`p-1 whitespace-nowrap text-sm font-medium text-gray-900 border border-black text-center ${fund?.status.value === "pending" ? "text-yellow-500" : fund?.status.value === "rejected" ? "text-red-600" : "text-green-600"}`}> {fund?.status.value} </td>
                    <td className={`p-1 whitespace-nowrap text-sm font-medium text-gray-900 border border-black text-center `}> {fund?.remarks} </td>
                    <td className={`p-1 whitespace-nowrap text-sm font-medium text-gray-900 border border-black text-center `}> {fund?.created_at?.slice(0, 10)} </td>
                    <td className='flex gap-3 p-1 whitespace-nowrap  border-black'>
                      {
                        fund?.status.value === "pending" ?
                          <button className='btn bg-blue-500 text-white rounded-md px-1 py-1'
                            onClick={() => {
                              setSelectedName(fund?.user.name);
                              setSelectedUserId(fund?.user.user_id);
                              setAmount(fund.amount);
                              setFileName(fund?.pay_slip[0].file);
                              setSelectedFundId(fund?.id)
                              setApprovePopUp(true)
                            }}
                          >Update</button>
                          : <p className={`p-1 whitespace-nowrap text-sm font-medium text-white  text-center ${fund?.status.value === "pending" ? "text-yellow-500" : fund?.status.value === "rejected" ? "bg-red-600" : "bg-green-600"} rounded`}> {fund?.status.value} </p>
                      }

                      {/* <button className='btn bg-red-500 text-white rounded-md px-1 py-1' >Reject</button> */}
                    </td>

                  </tr>
                ))

                :
                <tr className="w-full flex justify-center items-center">
                  <td colSpan="5" className=" text-center flex justify-center items-center text-black text-xl">
                    <FaInbox />
                    Data Not Found</td>
                </tr>
            }
          </tbody>
        </table>
      </div>
      {/* <DivTag className="flex p-4 justify-center mt-4">
        <Button
          type="button"
          className="bg-blue-700 text-white rounded px-4 py-2 mr-2"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span className="text-lg">
          {currentPage} / {paginationData.last_page || 1}
        </span>
        <Button
          type="button"
          className="bg-blue-700 text-white rounded px-4 py-2 ml-2"
          onClick={() =>
            setCurrentPage((prev) =>
              Math.min(prev + 1, paginationData.last_page || 1)
            )
          }
          disabled={currentPage === paginationData.last_page}
        >
          Next
        </Button>
      </DivTag> */}
    </div>
  );
};

export default FundApprove;
