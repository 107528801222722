import React from 'react';
import DivTag from '../../Components/DivComponent/DivTag';
import Button from '../../Components/ButtonComponent/Button';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import SpanTag from '../../Components/SpanComponent/SpanTag';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import TextAreaWithLabel from '../../Components/TextAreaWithLabel/TextAreaWithLabel';
import FormValidation from '../../Components/FormValidation/FormValidation';

const PopUpConfig = ({ onCloseConfig }) => {
  const initialState = {
    text_Message: '',
    select_Bank_Name: '',
    status: '',
  };

  const validate = (values) => {
    let errors = {};
    if (!values.text_Message) errors.text_Message = 'Text Message is required';
    if (!values.select_Bank_Name) errors.select_Bank_Name = 'BankName is required';
    if (!values.status) errors.status = 'Status is required';
    return errors;
  };

  const { values, errors, handleChange, handleBlur } = FormValidation(initialState, validate);

  const handleUpdate = () => {
    console.log('Saving page data:', values);
    onCloseConfig(); // Close the popup
  };

  return (
    <DivTag className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <DivTag className="bg-white rounded-lg shadow-lg w-full max-w-lg">
        <DivTag className="modal-header flex justify-between items-center p-4 border-b border-gray-200">
          <HeadingTag className="text-xl font-semibold text-red-700">Confirmation</HeadingTag>
          <Button type="button" className="text-gray-500 hover:text-gray-700" aria-label="Close" onClick={onCloseConfig}>
            <SpanTag aria-hidden="true">&times;</SpanTag>
          </Button>
        </DivTag>
        <DivTag className="modal-body p-4">
          <TextAreaWithLabel
            DivclassName="w-full mb-4"
            label="Text Message"
            type="text"
            placeholder="Enter text message"
            name="text_Message"
            id="text_Message"
            value={values.text_Message}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`border ${errors.text_Message ? 'border-red-500' : 'border-blue-600'} p-2 rounded`}
            error={errors.text_Message}
          />

          <DivTag className="flex flex-wrap -mx-2">
            <SelectWithLabelOption
              DivclassName="w-full md:w-1/2 px-2 mb-4"
              label="Select Bank Name"
              id="select_Bank_Name"
              name="select_Bank_Name"
              value={values.select_Bank_Name}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`border ${errors.select_Bank_Name ? 'border-red-500' : 'border-blue-600'} p-2 rounded`}
              error={errors.select_Bank_Name}
            >
              <option value="">Select Bank Name</option>
              <option value="Bank1">Bank1</option>
              <option value="Bank2">Bank2</option>
              <option value="Bank3">Bank3</option>
            </SelectWithLabelOption>

            <SelectWithLabelOption
              DivclassName="w-full md:w-1/2 px-2 mb-4"
              label="Status"
              id="status"
              name="status"
              value={values.status}
              onChange={handleChange}
              onBlur={handleBlur}
              className={`border ${errors.status ? 'border-red-500' : 'border-blue-600'} p-2 rounded`}
              error={errors.status}
            >
              <option value="">Select Status</option>
              <option value="Assigned & Active">Assigned & Active</option>
            </SelectWithLabelOption>
          </DivTag>
        </DivTag>
        <DivTag className="modal-footer p-4 border-t border-gray-200">
          <Button
            type="button"
            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg px-4 py-2 text-lg"
            onClick={handleUpdate}
          >
            Submit
          </Button>
        </DivTag>
      </DivTag>
    </DivTag>
  );
};

export default PopUpConfig;
