import { data } from "jquery";
import { postRequest } from "../config/AxiosConfig"
import API_URLS from "../config/BackendConfig"
import { getAuthToken } from "../utils/tokenUtils";

export const RetriveApiKeyReportService = async ({ totalData, fromData, toDate, userId, serviceStatus, currentPage }) => {
    try {
        const payload = {
            'total_data': userId !==""?1:parseInt(totalData),
            //  'from_date': fromData,
            //  'to_date': toDate
            'user_id': userId,
            //  'status': serviceStatus,
            'page':userId !==""?1 : currentPage
        }

        const token = getAuthToken();
        const respone = await postRequest({
            url: API_URLS.ADMIN + 'api-key/report',
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: payload,
        });
        // console.log(respone)
        return respone
    } catch (error) {
        return error;
    }
}
export const RetriveApiKeyReportServiceByFilter = async ({ totalData, fromData, toDate, userId, serviceStatus }) => {
    try {
        const payload = {
            'total_data': totalData,
            'from_date': fromData,
            'to_date': toDate,
            'user_id': userId,
            'status': serviceStatus
        }
        // console.log(payload)
        const token = getAuthToken();
        const respone = await postRequest({
            url: API_URLS.ADMIN + 'api-key/report',
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` },

        });
        return respone
    } catch (error) {
        return error;
    }
}

export const toggleStatusService = ({ apiKeyId }) => {
    try {
        const payload = {
            "api_key_id": apiKeyId
        }
        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "api-key/toggle-status",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        })
        return response
    } catch (error) {
        return error
    }
}
export const toggleEnvService = ({ apiKeyId }) => {
    try {
        const payload = {
            "api_key_id": apiKeyId
        }
        const token = getAuthToken();
        const response = postRequest({
            url: API_URLS.ADMIN + "api-key/toggle-environment",
            data: payload,
            headers: { 'Authorization': `Bearer ${token}` }
        })
        return response
    } catch (error) {
        return error
    }
}