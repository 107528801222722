import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { getAdminProfile } from "../../services/authService";
import { removeauthToken } from "../../utils/tokenUtils";

const PrivateRoute = ({ children }) => {
  const authToken = Cookies.get("authToken");
  let isAuthenticated = !!authToken; // Check if the auth token exists

  async function isAdminLogin() {
    const response = await getAdminProfile();

    if (response.status == 401) {
      removeauthToken();
      isAuthenticated = false;
      return
    };

  }
  isAdminLogin()
  return isAuthenticated ? children : <Navigate to="/" />;
};

export default PrivateRoute;
