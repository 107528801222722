import React, { useState } from 'react';
import DivTag from '../../Components/DivComponent/DivTag';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import SpanTag from '../../Components/SpanComponent/SpanTag';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import TextAreaWithLabel from '../../Components/TextAreaWithLabel/TextAreaWithLabel';
import Button from '../../Components/ButtonComponent/Button';
import ParagraphTag from '../../Components/ParagraphComponent/ParagraphTag';
import { updateAssignedReportStatus } from '../../../services/apiaddservice';

const ActionButtonPopup = ({ onCloseActionPop,selectedReportId,selectedUserId }) => {
    const initialState = {
        select_type: '',
        remark: '',
    };

    const [values, setValues] = useState(initialState);
    const [errors, setErrors] = useState({});

    const handleChange = (event) => {
        setValues({
            ...values,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit =async () => {
        const validationErrors = validate(values);

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            const response=await updateAssignedReportStatus({
                userId:selectedUserId,
                assignMentId:selectedReportId,
                statusType:values.select_type
            })
            console.log(response)
            onCloseActionPop();
        }
    };

    const validate = (values) => {
        let errors = {};

        if (values.remark.length < 5) {
            errors.remark = 'Remarks must be at least 5 characters';
        }

        return errors;
    };

    return (
        <DivTag className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
            <DivTag className="bg-white rounded-lg shadow-lg max-w-md w-full">
                <DivTag className="flex justify-between items-center p-4 border-b border-gray-200">
                    <HeadingTag className="text-lg font-semibold text-red-700">Confirmation</HeadingTag>
                    <Button type="button" className="text-gray-500 hover:text-gray-700" onClick={onCloseActionPop}>
                        <SpanTag aria-hidden="true">&times;</SpanTag>
                    </Button>
                </DivTag>

                <DivTag className="p-4">
                    <SelectWithLabelOption
                        DivclassName="w-full mb-4"
                        label="Select Type"
                        id="select_type"
                        name="select_type"
                        value={values.select_type}
                        onChange={handleChange}
                        required={false}
                        className={`border ${errors.select_type ? 'border-red-500' : 'border-blue-600'} rounded-lg p-2`}
                        error={errors.select_type}
                    >
                        <option value="" selected disabled>Select Type</option>
                        <option value="active">Active</option>
                        <option value="inactive">Disable</option>
                        <option value="inactive">Temp Disable</option>
                        <option value="inactive">Change Domain</option>
                    </SelectWithLabelOption>

                    <TextAreaWithLabel
                        DivclassName="w-full mb-4"
                        label="Remarks"
                        type="text"
                        placeholder="Enter Remark"
                        name="remark"
                        value={values.remark}
                        onChange={handleChange}
                        required={true}
                        className={`border ${errors.remark ? 'border-red-500' : 'border-blue-600'} rounded-lg p-2`}
                        error={errors.remark && <ParagraphTag className="text-red-500 text-center">{errors.remark}</ParagraphTag>}
                    />
                </DivTag>

                <DivTag className="flex justify-center p-4 border-t border-gray-200">
                    <Button
                        type="button"
                        className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white rounded-lg px-4 py-2 text-lg"
                        onClick={handleSubmit}
                    >
                        Submit
                    </Button>
                </DivTag>
            </DivTag>
        </DivTag>
    );
};

export default ActionButtonPopup;
