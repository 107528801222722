import { postRequest } from "../../config/AxiosConfig";
import API_URLS from "../../config/BackendConfig";
import { getAuthToken } from "../../utils/tokenUtils";


// Add Routes
export const AddRouteService = async (data) => {
    try {
        const token = getAuthToken();  
        const response = await postRequest({
            url: API_URLS.ADMIN + 'create/available-routes',
            data: data,  
            headers: {
                'Authorization': `Bearer ${token}`, 
            },
        });
       // console.log(response);  
        return response;  
    } catch (error) {
        console.error(error);  
        return error;  
    }
};



 // Fetch Routes Reports Services
export const fetchRoutes = async (page = 1) => {
    try {
        const token = getAuthToken();
        const response = await postRequest({
        url: `${API_URLS.ADMIN}available-routes/report?page=${page}`,
        headers: {
            'Authorization': `Bearer ${token}`
        },
    });
   // console.log(response);
    return response
} catch (error) {
    return error;
}}
// ENd


export const ToggleStatusService = async ({id}) => {
    try {
        const payload = {
            "route_id": id,
        }
        const token = getAuthToken();
        const response = await postRequest({
            url: API_URLS.ADMIN + "available-routes/toggle-status",
            headers: {
                'Authorization': `Bearer ${token}`
            },
            data: payload,
        });
        //console.log("API Response:", response.data);
        return response;
    } catch (error) {
        console.error('Erro in Toggle', error.response?.data || error.message);
    }
};

// Edit Route Details Service
export const editRouteDetails = async ({ route_id }) => {
    try {
      const token = getAuthToken();
      const response = await postRequest({
        url: API_URLS.ADMIN + "available-route/details",
        data: { route_id },
        headers: { Authorization: `Bearer ${token}` },
      });
     // console.log("RouteDetails",response);
      return response;
    } catch (error) {
      console.error("Error fetching Route details:", error);
      throw error;
    }
  };
  
  // Update Route Details Service
  export const updateRouteDetails = async ({  payload, route_id }) => {
    try {
      const token = getAuthToken();
      const response = await postRequest({
        url: `${API_URLS.ADMIN}available-route/update/${route_id}`,
        data: payload, 
        headers: { Authorization: `Bearer ${token}` },
      });
    //  console.log(response);
      return response;
    } catch (error) {
      console.error("Error updating Route:", error);
      throw error;
    }
  };
  
  // ENd 