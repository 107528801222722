import React from 'react';
import { FaTimes } from "react-icons/fa";
import { motion } from 'framer-motion';
import Backdrop from './Backdrop';

const ConfirmPopUp = ({ className, children, onClose, visible, title, onConfirm }) => {
    const dropIn = {
        hidden: {
            y: "-100vh",
            opacity: 0,
        },
        visible: {
            y: "0",
            opacity: 1,
            transition: {
                duration: 0.1,
                type: "spring",
                damping: 25,
                stiffness: 500,
            },
        },
        exit: {
            y: "100vh",
            opacity: 0,
        },
    };
const handleConform=()=>{
    onConfirm();
    onClose();
}
    return (

        <motion.div
            initial="hidden"
            animate={visible ? "visible" : "hidden"}
            exit="exit"
            variants={dropIn}
            className={` shadow-lg rounded-lg border backdrop-blur-2xl bg-neutral-50  border-gray-200 w-full h-1/3 max-w-lg fixed inset-0 m-auto p-3 ${className}`}
        >
            <div className="flex justify-between items-center border-b border-gray-200 pb-4">
                <h2 className="text-xl font-semibold text-sky-500">Confirmation </h2>


            </div>
            <div className="mt-5">
                <h4 className='text-2xl px-3 text-neutral-600 text-center font-semibold my-2'>{title}</h4>
                <div className='w-full flex justify-between my-5 px-8'>
                    <button className='py-2 px-6 text-2xl text-white font-semibold bg-red-600 border-none cursor-pointer rounded-xl'
                        onClick={onClose}
                    >No </button>
                    <button className='py-2 px-6 text-2xl text-white font-semibold bg-blue-600 border-none  rounded-xl cursor-pointer'  onClick={handleConform}>YES</button>
                </div>
            </div>

        </motion.div>

    );
};

export default ConfirmPopUp;
