import React from 'react';
import './InputReadonly.css'; // Import the external CSS file

const InputReadonly = ({ label, name, id, type, readOnly, DivclassName , inputClass ,...props }) => {
  return (
    <div className={DivclassName}>
      <label htmlFor={id} className="block tracking-wide text-s font-bold mb-0">
        {label}
      </label>
      <input
        id={id}
        type={type}
        name={name}
        readOnly={readOnly}
        {...props}
        className={`w-full border rounded py-2 px-2 bg-slate-600 ${inputClass} `}
      />
    </div>
  );
};

export default InputReadonly;

