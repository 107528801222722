import React, { useState, useEffect } from "react";
import { editRouteDetails, updateRouteDetails } from "../../services/RouteService/routeService";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";

const RoutesEditModal = ({ isOpen, onClose, routeId ,onUpdate }) => {
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [getID, setGetID] = useState('');

  useEffect(() => {
    if (isOpen) fetchRouteDetails();
  }, [isOpen, routeId]);

  const fetchRouteDetails = async () => {
    setIsLoading(true);
    try {
      const response = await editRouteDetails({ route_id: routeId });
      setFormData(response?.data || {});
    } catch (error) {
      console.error("Failed to fetch route details:", error);
      showErrorToast("Error fetching route details.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (!formData?.id) {
        showErrorToast("Route ID is missing.");
        setIsSubmitting(false);
        return;
      }
  
      const response = await updateRouteDetails({
        payload: formData,
        route_id: routeId,
      });
  
      if (response?.status?.code === 200) {
        showSuccessToast("Route details updated successfully!");
        onClose();
        if (onUpdate) onUpdate(); // Notify parent to refresh data
      } else {
        const errorMessages = response?.errors
          ? Object.values(response.errors).flat().join(", ")
          : response?.status?.message || "Validation failed.";
        showErrorToast(errorMessages);
      }
    } catch (error) {
      console.error("Failed to update route:", error);
      showErrorToast(error.response?.data?.message || "Error updating route details.");
    } finally {
      setIsSubmitting(false);
    }
  };
  

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-[50%] max-w-4xl overflow-auto md:h-[36%] h-[36%]">
        <div className="p-1 border-b flex justify-between items-center">
          <h2 className="text-xl font-bold">Edit Route</h2>
          <button onClick={onClose} className="text-gray-600 hover:text-gray-900 text-2xl font-bold">
            &times;
          </button>
        </div>
        <div className="p-1 space-y-1">
          {isLoading ? (
            <p className="text-center text-gray-500">Loading...</p>
          ) : (
            formData && (
              <>
                <h3 className="text-lg font-semibold mb-2">Route Details</h3>
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-1">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Route Name</label>
                    <input
                      type="text"
                      name="route_name"
                      value={formData?.route_name || ""}
                      onChange={handleChange}
                      className="border rounded-md p-2 w-full border-blue-600"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">URL</label>
                    <input
                      type="text"
                      name="route_url"
                      value={formData?.route_url || ""}
                      onChange={handleChange}
                      className="border rounded-md p-2 w-full border-blue-600"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Route Description</label>
                    <input
                      type="text"
                      name="route_description"
                      value={formData?.route_description || ""}
                      onChange={handleChange}
                      className="border rounded-md p-2 w-full border-blue-600"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Route Method</label>
                    <select
                      name="route_method"
                      value={formData?.route_method || ""}
                      onChange={handleChange}
                      className="border rounded-md p-2 w-full border-blue-600"
                    >
                      <option value="">Select Method</option>
                      <option value="GET">GET</option>
                      <option value="POST">POST</option>
                      <option value="PUT">PUT</option>
                      <option value="DELETE">DELETE</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Privacy</label>
                    <select
                      name="privacy"
                      value={formData?.privacy || ""}
                      onChange={handleChange}
                      className="border rounded-md p-2 w-full border-blue-600"
                    >
                      <option value="">Select Privacy</option>
                      <option value="with-pin">With-Pin</option>
                      <option value="without-pin">Without-Pin</option>
                    </select>
                  </div>
                  {/* <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Select Status</label>
                    <select
                      name="is_active"
                      value={formData?.is_active || ""}
                      onChange={handleChange}
                      className="border rounded-md p-2 w-full border-blue-600"
                    >
                      <option value="">Select Status</option>
                      <option value="1">Active</option>
                      <option value="0">InActive</option>
                    </select>
                  </div> */}
                </div>
              </>
            )
          )}
        </div>
        <div className="p-2 border-t flex justify-center space-x-2">
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-2 py-1 rounded-md"
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className={`bg-blue-500 text-white px-2 py-1 rounded-md ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isSubmitting || isLoading}
          >
            {isSubmitting ? "Saving..." : "Save Changes"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoutesEditModal;
