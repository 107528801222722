
import React from "react";

const ATag = ({ dataToggle, text, href, role, dataActive, dataPlacement, id, areaExpanded, areaControls, className, children }) => (
 
    <a
    data-toggle={dataToggle}
    href={href}
    role={role}
    data-active={dataActive}
    data-placement={dataPlacement}
    aria-expanded={areaExpanded}
    aria-controls={areaControls}
    className={className}
    text={text}
    id={id}
    >
     {children}
     {text}
     </a>
);

export default ATag;
