import React, { useState } from 'react';
import InputWithLabel from '../InputComponent/InputWithLabel';
import DivTag from '../DivComponent/DivTag';

const SearchApiUserFiled = ({ DivclassName, DivTagClassName }) => {
    const [names] = useState(['John', 'Bobby', 'Bobby', 'Bo', 'Ram', 'Ra', 'ram']);
    const [searchTerm, setSearchTerm] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);

    const filteredNames = names.filter(name => name.toLowerCase().includes(searchTerm.toLowerCase()));

    const handleSearch = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
        setShowDropdown(term.length >= 3);
    };

    const handleSelectName = (name) => {
        console.log('Selected Name:', name);
        setSearchTerm(name);
        setShowDropdown(false);
    };

    return (
        <>
            <InputWithLabel
                DivclassName={DivclassName}
                label="Search Api User"
                type="text"
                name="search_api_user"
                placeholder="Api User Search........."
                id="search_api_user"
                onChange={handleSearch}
                className="border"
                value={searchTerm}
            />
            {showDropdown && (
                <DivTag className={DivTagClassName}>
                    {filteredNames.map((name, index) => (
                        <p
                            className="p-2 cursor-pointer hover:bg-gray-200 text-center "
                            key={index}
                            onClick={() => handleSelectName(name)}
                        >
                            {name}
                        </p>
                    ))}
                </DivTag>
            )}
        </>
    );
};

export default SearchApiUserFiled;
