import { useState, useEffect, useMemo } from 'react';
import InputField from '../../../Components/InputComponent/InputField';
import Label from '../../../Components/LabelComponent/Label';
import DivTag from '../../../Components/DivComponent/DivTag';
import HeadingTag from '../../../Components/HeadingComponent/HeadingTag';
import FileUpload from '../../../Components/UploadFileComponent/Uploadfile';
import { Button as Buttons } from 'primereact/button';
import { retrivePackageService } from '../../../../services/PackageList/PackageService';


const PersonalData = ({ handleInputChange, Next, Prev }) => {
    const [name, setName] = useState('');
    const [mobile_number, setMobile_number] = useState('');
    const [email, setEmail] = useState('');
    const [aadhar, setAadhar] = useState('');
    const [pan, setPan] = useState('');
    const [pinCode, setPinCode] = useState('');
    const [address, setAddress] = useState('');
    const [postOffice, setPostOffice] = useState('');
    const [district, setDistrict] = useState('');
    const [state, setState] = useState('');
    const [personalConfirm, setPersonalConfirm] = useState(false);
    const [dob, setDob] = useState('');
    const [aadharImage,setAadharImage]=useState(null);
    const [panImage,setPanImage]=useState(null);
    const [image,setImage]=useState(null)

    // const [selectedPackageId, setSelectedPackageid] = useState('');
    // const [packageData, setPackageData] = useState([]);

    // State to manage error messages
    const [errors, setErrors] = useState({});

    // Handle input changes and validation
    const handleInputChangeField = (field, value) => {
        setErrors({ ...errors, [field]: '' }); // Clear the error for the field
        // Your code for handling input change
    };



    const validateForm = () => {
        let formErrors = {};

        if (!name) formErrors.name = 'Name is required';
        if (!mobile_number) formErrors.mobile_number = 'Mobile number is required';

        if (!email) formErrors.email = 'Email is required';
        if (!aadhar) formErrors.aadhar = 'Aadhar number is required';

        if (!pan) formErrors.pan = 'PAN is required';
        //if (!selectedPackageId) formErrors.selectedPackageId = 'Package  is required';
        if (!pinCode) formErrors.pinCode = 'Pin code is required';
        if (!address) formErrors.address = 'Address is required';
        if (!postOffice) formErrors.postOffice = 'Post Office is required';
        if (!district) formErrors.district = 'District is required';
        if (!state) formErrors.state = 'State is required';
        if (!personalConfirm) formErrors.personalConfirm = 'You must confirm KYC document receipt';
        if (mobile_number.length < 10) formErrors.mobile_number = "Mobile number must be 10 digit ";
        if (aadhar.length < 12 || aadhar.length > 12) formErrors.aadhar = "Aadhar number must be 12 digit ";
        if (pinCode.length < 6 || pinCode.length > 6) formErrors.pinCode = "Pincode  must be 6 digit ";
        if (pan.length < 10 || pan.length > 10) formErrors.pan = "Pan number  must be 10 digit ";
        setErrors(formErrors);

        // If there are no errors, call the Next function
        if (Object.keys(formErrors).length === 0) {
            Next();
        }
    };

    // async function RetrivePackageData() {
    //     try {
    //         const response = await retrivePackageService();
    //         console.log(response)
    //         setPackageData(response.data);
    //     } catch (error) {
    //         return error;
    //     }
    // }
    // useMemo(() => {
    //     RetrivePackageData();
    // }, [])
    return (
        <>
            <fieldset>
                <DivTag className="form-card">
                    <HeadingTag className="fs-title text-blue-600 mb-1 text-xl" text="Authorised Signatory Information" />

                    <DivTag className="flex flex-wrap -mx-3">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Name
                            </label>
                            <input
                                id="name"
                                type="text"
                                name="name"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Enter Name"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                    handleInputChange('user', 'name', e.target.value);
                                }}
                            />
                            {errors.name && <p className="text-red-500 text-xs italic">{errors.name}</p>}
                        </div>

                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Mobile
                            </label>
                            <input
                                id="mobile_number"
                                type="number"
                                name="mobile_number"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Enter Mobile"
                                max={10}

                                maxLength={10}
                                value={mobile_number}
                                onChange={(e) => {
                                    setMobile_number(e.target.value);
                                    handleInputChange('user', 'mobile_number', e.target.value)
                                }}
                            />
                            {errors.mobile_number && <p className="text-red-500 text-xs italic">{errors.mobile_number}</p>}
                        </div>

                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Email
                            </label>
                            <input
                                id="email"
                                type="text"
                                name="email"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    handleInputChange('user', 'email_address', e.target.value)
                                }}
                            />
                            {errors.email && <p className="text-red-500 text-xs italic">{errors.email}</p>}
                        </div>

                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Aadhar
                            </label>
                            <input
                                id="aadhar"
                                type="text"
                                name="aadhar"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Enter Aadhar"
                                maxLength={12}
                                minLength={12}
                                value={aadhar}
                                required
                                onChange={(e) => {
                                    setAadhar(e.target.value);
                                    handleInputChange('user', 'aadhar_number', e.target.value)
                                }}
                            />
                            {errors.aadhar && <p className="text-red-500 text-xs italic">{errors.aadhar}</p>}
                        </div>

                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Pan
                            </label>
                            <input
                                id="pan"
                                type="text"
                                name="pan"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Enter PAN"
                                value={pan}
                                maxLength={10}
                                minLength={10}
                                onChange={(e) => {
                                    setPan(e.target.value);
                                    handleInputChange('user', 'pan_number', e.target.value)
                                }}
                            />
                            {errors.pan && <p className="text-red-500 text-xs italic">{errors.pan}</p>}
                        </div>

                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Pin Code
                            </label>
                            <input
                                id="pin_code"
                                type="number"
                                name="pin_code"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Enter Pin Code"
                                value={pinCode}
                                maxLength={6}
                                onChange={(e) => {
                                    setPinCode(e.target.value);
                                    handleInputChange('user', 'pin_code', e.target.value)
                                }}
                            />
                            {errors.pinCode && <p className="text-red-500 text-xs italic">{errors.pinCode}</p>}
                        </div>
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                DOB
                            </label>
                            <input
                                id="dob"
                                type="date"
                                name="dob"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Enter Pin Code"
                                value={dob}

                                onChange={(e) => {
                                    setDob(e.target.value);
                                    handleInputChange('user', 'dob', e.target.value)
                                }}
                            />

                        </div>
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Address
                            </label>
                            <input
                                id="address"
                                type="text"
                                name="address"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Enter Address"
                                value={address}
                                onChange={(e) => {
                                    setAddress(e.target.value);
                                    handleInputChange('user', 'landmark_address', e.target.value)
                                }}
                            />
                            {errors.address && <p className="text-red-500 text-xs italic">{errors.address}</p>}
                        </div>

                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Post Office
                            </label>
                            <input
                                id="post_office"
                                type="text"
                                name="post_office"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Enter Post Office"
                                value={postOffice}
                                onChange={(e) => {
                                    setPostOffice(e.target.value);
                                    handleInputChange('user', 'post_office', e.target.value)
                                }}
                            />
                            {errors.postOffice && <p className="text-red-500 text-xs italic">{errors.postOffice}</p>}
                        </div>

                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                District
                            </label>
                            <input
                                id="district"
                                type="text"
                                name="district"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Enter District"
                                value={district}
                                onChange={(e) => {
                                    setDistrict(e.target.value);
                                    handleInputChange('user', 'district', e.target.value)
                                }}
                            />
                            {errors.district && <p className="text-red-500 text-xs italic">{errors.district}</p>}
                        </div>

                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                State
                            </label>
                            <input
                                id="state"
                                type="text"
                                name="state"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Enter State"
                                value={state}
                                onChange={(e) => {
                                    setState(e.target.value);
                                    handleInputChange('user', 'state', e.target.value)
                                }}
                            />
                            {errors.state && <p className="text-red-500 text-xs italic">{errors.state}</p>}
                        </div>
                        {/* <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Package
                            </label>
                            <select name="" id="" className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                value={selectedPackageId}
                                onChange={(e) => {
                                    setSelectedPackageid(e.target.value);
                                    handleInputChange('user', 'package_id', e.target.value)
                                }}
                            >
                                <option value="" selected disabled>Select package </option>
                                {
                                    packageData && packageData.length > 0
                                        ? packageData.map((item, index) => (
                                            <option value={item.id} key={index}>{item.name}</option>
                                        ))
                                        : ""
                                }
                            </select>
                            {errors.selectedPackageId && <p className="text-red-500 text-xs italic">{errors.selectedPackageId}</p>}
                        </div> */}
                       
                       <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                htmlFor="uploadPAN"
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            >
                                Upload PAN
                            </label>
                            <input
                                id="uploadPAN"
                                type="file"
                             
                                name="upload_pan"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white"
                                onChange={(e) => {
                                    setPanImage(e.target.files[0])
                                    handleInputChange('user', 'pan_image', e.target.files[0])
                                }


                                }
                            />
                            {errors.upload_pan && <p className="text-red-500 text-xs italic">{errors.upload_pan}</p>}
                        </div>
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                htmlFor="uploadPAN"
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            >
                                Upload Aadhar
                            </label>
                            <input
                                id="uploadPAN"
                                type="file"
                             
                                name="upload_aadhar"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white"
                                onChange={(e) => {
                                    setAadharImage(e.target.files[0])
                                    handleInputChange('user', 'aadhar_image', e.target.files[0])
                                }


                                }
                            />
                            {errors.upload_pan && <p className="text-red-500 text-xs italic">{errors.upload_pan}</p>}
                        </div>
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                htmlFor="uploadPAN"
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            >
                                Upload Image
                            </label>
                            <input
                                id="uploadPAN"
                                type="file"
                             
                                name="upload_image"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white"
                                onChange={(e) => {
                                    setImage(e.target.files[0])
                                    handleInputChange('user', 'image', e.target.files[0])
                                }


                                }
                            />
                        
                        </div>

                        <DivTag className="w-full md:w-1/2 px-4 mb-6 md:mb-0 flex items-center mt-4">
                            <input
                                id="personal_confirm"
                                type="checkbox"
                                name="personal_confirm"
                                className="form-checkbox h-5 w-5 text-blue-600 border-blue-600 rounded focus:ring-blue-500 focus:border-blue-500"
                                checked={personalConfirm == true}
                                value={personalConfirm}

                                onChange={() => setPersonalConfirm(!personalConfirm)}
                            />
                            <Label
                                htmlFor="personal_confirm"
                                className="uppercase tracking-wide text-gray-700 text-xs font-bold ml-4"

                            >
                                I have Received the KYC Doc.
                            </Label>
                        </DivTag>
                    </DivTag>
                </DivTag>

                <div className="flex pt-4 justify-center gap-4">
                    <Buttons
                        label="Previous"
                        severity="secondary"
                        icon="pi pi-arrow-left"
                        onClick={Prev}
                        className="bg-red-600 py-2 px-4 sm:py-1 sm:px-2 text-white"
                    />
                    <Buttons
                        label="Next"
                        icon="pi pi-arrow-right"
                        iconPos="right"
                        disabled={personalConfirm === false}
                        onClick={() => validateForm()}
                        className="bg-blue-400 py-2 px-4 sm:py-1 sm:px-2 text-white"
                    />
                </div>
            </fieldset>
        </>
    );
};

export default PersonalData;