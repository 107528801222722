// InputField.js
import React from 'react';

const InputField = ({ type, placeholder, className, id, name, value, onChange, defaultValue, onClick, ...props }) => {

  return (
    <input
      type={type}
      placeholder={placeholder}
      className={className}
      id={id}
      name={name}
      value={value}
      onClick={onClick}
      onChange={onChange}
      defaultValue={defaultValue}
      required
      
      {...props}
    />

  );
};

export default InputField;