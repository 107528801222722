// import React, { useState } from 'react'; 

import SpanTag from '../../../Components/SpanComponent/SpanTag';
import DivTag from '../../../Components/DivComponent/DivTag';
import HeadingTag from '../../../Components/HeadingComponent/HeadingTag';
import ParagraphTag from '../../../Components/ParagraphComponent/ParagraphTag';
import { FiCheckCircle } from 'react-icons/fi';
// import { Link } from 'react-router-dom';
import { Button as Buttons} from 'primereact/button';

const FinishData = ({ pin, password, userId , Prev }) => {
    return (
        <>
                <div className="form-card">
                    <HeadingTag className="fs-title mb-4 text-center text-2xl" text="Congrats !">
                        <SpanTag className="text-blue-600"></SpanTag>
                    </HeadingTag>
                    <br />
                    <DivTag className="flex flex-wrap items-center justify-center">
                        <FiCheckCircle className="text-green-500 checkmark" size={100} />
                    </DivTag>
                    <br />
                    <br />
                    <DivTag className="flex flex-wrap  justify-center">
                        <DivTag className="md:w-3/5 text-center">
                            <ParagraphTag text="Your API ID:"> </ParagraphTag><SpanTag id="Api_ID" text={userId} className="text-xl text-blue-800"></SpanTag>
                            <ParagraphTag text="Your Password:"> </ParagraphTag><SpanTag id="password_api" text={password} className="text-xl text-blue-800"></SpanTag>
                            <ParagraphTag text="Your Pin:"> </ParagraphTag><SpanTag id="password_api" text={pin} className="text-xl text-blue-800"></SpanTag>
                        </DivTag>
                    </DivTag>

                    <div className="flex pt-4 justify-center gap-4">
                        <Buttons
                            label="Previous"
                            severity="secondary"
                            icon="pi pi-arrow-left"
                            onClick={Prev}
                            className="bg-red-600 py-2 px-4 sm:py-1 sm:px-2 text-white"
                        />
                        {/* <Buttons
                            label="Finish"
                            severity="secondary"
                            icon="pi pi-arrow-right"
                            onClick=""
                            className="bg-blue-600 py-2 px-4 sm:py-1 sm:px-2 text-white"
                        /> */}
                    </div>

                </div>
        </>
    );
};

export default FinishData;