import React, { useState, useEffect } from 'react';
import DivTag from '../../Components/DivComponent/DivTag';
import Button from '../../Components/ButtonComponent/Button';
import FormValidation from '../../Components/FormValidation/FormValidation';
import InputWithLabel from '../../Components/InputComponent/InputWithLabel';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import ReportHeading from '../../Components/ReportHeadings/ReportHeadings';
import Option from '../../Components/OptionComponent/Option';
import SearchApiUserFiled from '../../Components/SearchApiUserComponent/SearchApiUserFiled';
import InputReadonly from '../../Components/InputComponent/InputReadonly';
import FormTag from '../../Components/FormComponent/FormTag';
import { motion } from 'framer-motion';
import { DeleteDocumentService, DocumentPreviewService, GetUploadedByIdService, GetUploadedDocumentIdService, GetUserDataByIdService, UploadedDocumentService } from '../../../services/apiService';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';
import PopMenu from '../../../components/Dialog/PopMenu';
import { ToastContainer } from "react-toastify";
import Seo from '../../../components/SeoComponent/Seo';
const ApiUserKyc = () => {


    const [searchText, setSearchText] = useState('');
    const [userData, setUserData] = useState();
    const [companyData, setCompanyData] = useState();
    const [documentsType, setDocumentType] = useState([]);
    const [isDocUploading, setIsDocUploading] = useState(false);
    const [uploadedDocument, setUploadedDocument] = useState([]);
    const [selectedDocumentId, setSelectedDocumentId] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [selectFileName, setSelectedFileName] = useState('');
    const [previewFile, setPreviewFile] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [tableData, setTableData] = useState([
        {
            SrNo: 1,
            Document: '1',
            DocumentPreview: '',
            Status: 'success',
            Action: ''
        }
    ]);
    const handleDelete = (index) => {
        const updatedTableData = [...tableData];
        updatedTableData.splice(index, 1);
        setTableData(updatedTableData);
    };
    async function DocumentTypeLists() {
        try {
            const response = await GetUploadedByIdService();

            setDocumentType(response.data)
        } catch (error) {
            return
        }
    }
    async function UplodedDocumentLists() {
        try {
            const response = await GetUploadedDocumentIdService({ userId: searchText.toUpperCase() });
            console.log(response)
            setUploadedDocument(response.data)
        } catch (error) {
            return
        }
    }
    async function getUserDetailsOnSearch() {
        try {
            const response = await GetUserDataByIdService({ userId: searchText });

            if (response.success === true) {

                setCompanyData(response.company);
                setUserData(response.user)
                await DocumentTypeLists();
                await UplodedDocumentLists();
            }

        } catch (error) {
            return
        }
    }
    async function handleUploadDocument() {
        try {
            setIsDocUploading(true)
            if (selectedDocumentId === '') {
                setIsDocUploading(false)
                return showErrorToast('Document type is required')
            }
            if (selectedFile === null) {
                setIsDocUploading(false)
                return showErrorToast("Please select new file")
            }
            const formData = new FormData();
            formData.append('userid', searchText);
            formData.append('docid', selectedDocumentId);
            formData.append('file', selectedFile)

            const resp = await UploadedDocumentService({
                userId: formData.get('userid'),
                docid: formData.get('docid'),
                file: formData.get('file')

            });

            if (resp.status === "success") {
                setIsDocUploading(false)
                setSelectedFile(null);
                await UplodedDocumentLists();
                showSuccessToast('Docment upload success ');
            }

        } catch (error) {
            setIsDocUploading(true)
            return error
        }
    }
    async function handlePreviewDoc(filename) {
        try {

            setIsPreviewOpen(true);
            setSelectedFileName(filename)

            const response = await DocumentPreviewService(selectFileName);



            // const base64String = btoa(String.fromCharCode(...new Uint8Array(response)))

            // const blob = new Blob([base64String], { type: "image/png" })
            // console.log(blob)
            // const url = URL.createObjectURL(base64String);
            setPreviewFile(response.dataUri);


        } catch (error) {

        }

    }

    async function handleDeleteDocument(docId) {
        try {
            setIsLoading(true);
            const response = await DeleteDocumentService({ userId: searchText, docid: docId });
            if (response.status === 403) {
                setIsLoading(false);
                showErrorToast(response.data.message);
                return
            }
            if (response.status.code === 200) {
                await UplodedDocumentLists();
                setIsLoading(false);
                showSuccessToast(response.message)
            }

        } catch (error) {
            setIsLoading(false);
            showErrorToast("failed to delete document ");
            return
        }
    }
    useEffect(() => {
        setTimeout(() => {
            getUserDetailsOnSearch();
        }, 1000)


    }, [searchText])

    return (

        <motion.div
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2 }}
        >
            <Seo />

            <DivTag id="" className=" border-2 border-solid border-blue-800 rounded-xl border-t-0 mt-10 bg-white">

                <ReportHeading name="Api User KYC" />
                <HeadingTag className="text-xl px-5 font-bold border-2 border-solid border-blue-800 mt-3
            border-b-0 border-t-0 rounded-md underline" text="Api User Details" />
                <DivTag className="border-2 border-solid border-blue-800 rounded-md 
                border-t-0 border-b-0 flex justify-center items-center">
                    <PopMenu visible={isPreviewOpen} onClose={() => setIsPreviewOpen(false)} className={'h-1/2 p-5'} title={"Document Preview"}>
                        <img src={previewFile} className='h-full w-full' alt="document image" />
                    </PopMenu>
                    <FormTag
                        method=""
                        autoComplete="off" >

                        <DivTag className="flex flex-wrap p-3 mt-2">
                            <div className='flex flex-col items-start -mt-2'>
                                <label htmlFor="" className='text-slate-600 font-bold'>Search User</label>
                                <input type='text' onChange={(e) => setSearchText(e.target.value)} value={searchText.toUpperCase()}
                                    placeholder='Search..'
                                    className='border shadow-md h-9 rounded-sm text-black outline-none px-2'
                                />
                            </div>

                            <InputReadonly
                                DivclassName="w-full md:w-1/4 px-2 md:mb-0 text-black"
                                label="Api User Name"
                                type="text"
                                name="api_user_name"
                                value={userData?.name}
                                id="api_user_name"
                                readOnly="readOnly"

                            />


                            <InputReadonly
                                DivclassName="w-full md:w-1/4 px-2 md:mb-0"
                                label="Authorized Signatiory Name"
                                type="text"
                                name="auth_sign_name"
                                id="auth_sign_name"
                                readOnly="readOnly"
                                value={userData?.name}
                            />

                            <InputReadonly
                                DivclassName="w-full md:w-1/4 md:mb-0"
                                label="Aadhar No.(AuthorizedSignatiory)"
                                type="text"
                                name="aadhar_no"
                                id="aadhar_no"
                                readOnly="readOnly"
                                value={userData?.aadhar_number}
                            />

                            <InputReadonly
                                DivclassName="w-full md:w-1/3 mt-3 px-2 md:mb-0"
                                label="Pan No.(Authorized Signatiory)"
                                type="text"
                                name="pan_no"
                                id="pan_no"
                                readOnly="readOnly"
                                value={userData?.pan_number}
                            />

                            <InputReadonly
                                DivclassName="w-full md:w-1/3 mt-3 px-3 md:mb-0"
                                label="Company Pan"
                                type="text"
                                name="company_pan"
                                id="company_pan"
                                readOnly="readOnly"
                                value={companyData?.company_pan}
                            />

                            <InputReadonly
                                DivclassName="w-full md:w-1/3 mt-3 md:mb-0"
                                label="Address"
                                type="text"
                                name="address"
                                id="address"
                                readOnly="readOnly"
                                value={userData?.landmark_address}
                            />

                            <SelectWithLabelOption
                                DivclassName="w-full md:w-1/2 mt-3 px-3 md:mb-0"
                                label="Choose Document"
                                id="choose_document"
                                name="choose_document"
                                onChange={(e) => setSelectedDocumentId(e.target.value)}
                            >
                                <Option value="" selected disabled>Choose Document</Option>
                                {
                                    documentsType.length > 0 ?
                                        documentsType.map((val, index) => (
                                            <option value={val.id} key={index}>{val.name}</option>
                                        ))
                                        : ""

                                }
                            </SelectWithLabelOption>

                            <InputWithLabel
                                DivclassName="w-full md:w-1/2 mt-3 px-3 md:mb-0"
                                label="File Upload"
                                type="file"
                                name="file_upload"
                                id="file_upload"
                                error='Max Size : 2 MB  is allowed'
                                
                                onChange={(e) => setSelectedFile(e.target.files[0])}
                            />

                        </DivTag>

                        <DivTag className="flex justify-center mb-4">
                            <Button
                                type="button"
                                className={isDocUploading ? "hidden" : "text-center text-white bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900 via-teal-400 to-purple-900 font-semibold p-0 rounded-md px-2 py-1 text-lg hover:bg-red-300"}
                                id="submitApiUserKyc"
                                name="submitApiUserKyc"
                                onClick={handleUploadDocument}

                            >Submit
                            </Button>
                        </DivTag>
                    </FormTag>

                </DivTag>


                <div className="flex justify-center mt-5 mb-5 overflow-x-auto">
                    <table className="min-w-full table-auto border-collapse">
                        <thead className="text-xs bg-gray-100">
                            <tr className='bg-blue-600 text-white'>
                                <th className="px-4 py-2 text-center">S No.</th>
                                <th className="px-4 py-2 text-center">Document</th>
                                <th className="px-4 py-2 text-center">Document Preview</th>
                                <th className="px-4 py-2 text-center">Status</th>
                                <th className="px-4 py-2 text-center">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                uploadedDocument.length > 0 ?
                                    uploadedDocument.map((item, index) => (
                                        <tr key={index} className="border-t">
                                            <td className="px-4 py-2 text-center">{index + 1}</td>
                                            <td className="px-4 py-2 text-center">{item?.remarks}</td>
                                            <td className="px-4 py-2 text-center">
                                                <button
                                                    type="button"
                                                    className="text-white bg-green-600 rounded-md text-sm p-1"
                                                    onClick={() => handlePreviewDoc(item.document_path)}
                                                >
                                                    Preview
                                                </button>
                                            </td>
                                            <td className="px-4 py-2 text-center font-bold">{item?.status}</td>
                                            <td className="px-4 py-2 text-center">
                                                <button
                                                    type="button"
                                                    className="text-white bg-red-500 rounded-sm p-1"

                                                    onClick={() => handleDeleteDocument(item.document.id)}
                                                >
                                                    Delete
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    <tr>
                                        <td colSpan="5" className="px-4 py-2 text-center text-black text-xl">Data Not Found</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>



                <ToastContainer />
            </DivTag>
        </motion.div>

    );

};

export default ApiUserKyc;