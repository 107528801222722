import React, { useState } from 'react';
import Button from '../ButtonComponent/Button';
import DivTag from '../DivComponent/DivTag';
import ImgTag from '../ImageComponent/ImgTag';

const ImageCard = ({ imageUrl, onViewClick, onApproveClick }) => {
  const [isApproved, setIsApproved] = useState(false);

  const handleApproveClick = () => {
    setIsApproved(true);
    onApproveClick(); // Call the original onApproveClick function
  };

  return (
    <DivTag className="m-2 border rounded-md p-1">
      <ImgTag src={imageUrl} alt="Image" className="w-44 h-34 object-cover rounded-lg" />
      <DivTag className="flex justify-center mt-2">
        <Button onClick={onViewClick} className="bg-blue-500 text-white p-1 text-xs rounded-md mr-2">View</Button>
        {!isApproved && (
          <Button onClick={handleApproveClick} className="bg-green-500 text-white text-xs rounded-md">Approve</Button>
        )}
      </DivTag>
    </DivTag>
  );
};

export default ImageCard;
