import React, { useState, useEffect } from 'react';
import DivTag from '../../Components/DivComponent/DivTag';
import Button from '../../Components/ButtonComponent/Button';
import FormValidation from '../../Components/FormValidation/FormValidation';
import InputWithLabel from '../../Components/InputComponent/InputWithLabel';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import ReportHeading from '../../Components/ReportHeadings/ReportHeadings';
import Option from '../../Components/OptionComponent/Option';
import { motion } from 'framer-motion';
import 'jspdf-autotable'; // Import AutoTable plugin
import Seo from '../../../components/SeoComponent/Seo';


const PartnerShip = () => {   
const [partnerData, setPartnerData] = useState([]);
const [isModalOpenActionPopup, setIsModalActionPop] = useState(false);


    const initialState = {
        from_date: '',
        to_date: '',
        s_partner: '',
    };


    // Function to open the modal
    const openActionPopup = () => {
        setIsModalActionPop(true);
    };

    // Function to close the modal
    const onCloseActionPop = () => {
        setIsModalActionPop(false);
    };



    const validate = (values) => {
        let errors = {};

        // Add validation logic here
        if (!values.from_date) {
            errors.from_date = 'From Date is required';
        }
        if (!values.to_date) {
            errors.to_date = 'To Date is required';
        }
        
        if (!values.s_partner || values.s_partner === '') {
            errors.s_partner = 'Select Partner is required';
        }
        return errors;
    };

    const { values, errors, handleChange, handleBlur } = FormValidation(
        initialState,
        validate
    );


    return (

        <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
    >
        <Seo />
   <DivTag className=" mt-8 bg-white">
            <ReportHeading name="Lead Report" />
                <DivTag className="flex justify-center items-center">
                    <DivTag className="flex flex-wrap mt-3">
                        <InputWithLabel
                            DivclassName="w-1/2 md:w-1/3 px-2 md:mb-0"
                            label="From Date"
                            type="date"
                            name="from_date"
                            id="from_date"
                            value={values.from_date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.from_date ? 'border border-red-500' : 'border border-blue-600'}
                            error={errors.from_date}
                        />

                        <InputWithLabel
                            DivclassName="w-1/2 md:w-1/3 px-2 md:mb-0"
                            label="To Date"
                            type="date"
                            name="to_date" 
                            id="to_date"
                            value={values.to_date}
                            onChange={handleChange}
                            onBlur={handleBlur}  
                            className={errors.to_date ? 'border border-red-500' : 'border border-blue-600'}
                            error={errors.to_date}
                        />

                        <SelectWithLabelOption
                            DivclassName="w-1/2 md:w-1/3 px-2 md:mb-0"
                            label="Select Partner"
                            id="s_partner"
                            name="s_partner"
                            value={values.s_partner}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.s_partner ? 'border border-red-500' : 'border border-blue-600'}
                            error={errors.s_partner}>
                            <Option value="">Select Parnter</Option>
                            <Option value="Pt1">Pt1</Option>
                            <Option value="Pt2">Pt2</Option>
                        </SelectWithLabelOption>
                       
                    </DivTag>
                </DivTag>

                <DivTag className="flex justify-center mt-2">
                    <Button
                        type="submit"  
                        className="text-center text-white bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900
                         via-teal-400 to-purple-900 font-semibold p-0 rounded-md px-2 py-1 text-lg hover:bg-red-300"
                        onClick="handleSearch">
                        Search
                    </Button>
                </DivTag>

                <HeadingTag className="text-2xl text-blue-800 font-bold bg-white text-center mt-2 mb-2" text="Report"/>

                <DivTag className="overflow-x-auto my-1 p-2">
                       <table id="table" className="min-w-full divide-y divide-gray-200">
                            <thead className="text-md">
                                <tr className="bg-blue-800 text-white text-center">
                                    <th className="border-2 p-2">S No.</th>
                                    <th className="border-2">Client Name</th>
                                    <th className="border-2">Requirement</th>
                                    <th className="border-2">Partner ID & Name</th>
                                    <th className="border-2">Doc</th>
                                    <th className="border-2">Partner Brand Name</th>
                                    <th className='border-2'>Update Status</th>
                                    <th className='border-2'>Closed Date</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                               <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                               </tr>
                            </tbody>
                        </table>
                    </DivTag> 
            </DivTag>
          
</motion.div>
  
        );
    };
    
    export default PartnerShip;
