import React, { useState } from 'react';
import Label from '../../../Components/LabelComponent/Label';
import DivTag from '../../../Components/DivComponent/DivTag';
import HeadingTag from '../../../Components/HeadingComponent/HeadingTag';
import { Button as Buttons } from 'primereact/button';

const SOpaymentData = ({ handleInputChange, handleSubmit, Prev }) => {
    const [paymentType, setPaymentType] = useState('');
    const [amount, setAmount] = useState('');
    const [user_id, setUserId] = useState();
    const [utr, setUtr] = useState('');

    // const handlePaymentTypeChange = (e) => {
    //     const value = e.target.value;
    //     setPaymentType(value);
    //     handleInputChange('payment', 'payment_type', value);
    // };

    return (
        <>
            <fieldset>
                <DivTag className="form-card">
                    <HeadingTag className="fs-title mb-4 text-xl" text="Sales Officer & Payment Information" />

                    <DivTag className="flex flex-wrap -mx-3 mb-6">
                        <DivTag className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <Label
                                htmlFor="sales_officer"
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Sales Officer
                            </Label>
                            <select
                                id="sales_officer"
                                name="sales_officer"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white text-center"
                                onChange={(e) => handleInputChange('payment', 'sales_officer', e.target.value)}
                            >
                                <option value="">Select Sales Officer</option>
                                <option value="Vishal Kumar">Vishal Kumar</option>
                                <option value="Shivani Priya">Shivani Priya</option>
                                <option value="Rabina Khatoon">Rabina Khatoon</option>
                                <option value="Vijeta Kr Ranjan">Vijeta Kr Ranjan</option>
                                <option value="Varun Sharma">Varun Sharma</option>
                                <option value="Sujeeta Kumari">Sujeeta Kumari</option>
                                <option value="Shivangi Sharma">Shivangi Sharma</option>
                            </select>
                        </DivTag>

                        <DivTag className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <Label
                                htmlFor="payment_type"
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Payment Type
                            </Label>
                            <select
                                id="payment_type"
                                name="payment_type"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white text-center"

                                value={paymentType}
                                onChange={(e) => {
                                    setPaymentType(e.target.value)
                                    handleInputChange('payment', 'payment_type', e.target.value)
                                }}
                            >
                                <option value="">Select Payment Type</option>
                                <option value="CASH">By Cash</option>
                                <option value="TRANSFER">Online Transfer</option>
                                <option value="PG">By Payment Gateway</option>
                            </select>
                        </DivTag>

                        {paymentType === 'TRANSFER' || paymentType === 'TRANSFER' ? (
                            <DivTag className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                <Label
                                    htmlFor="UTR"
                                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                    UTR
                                </Label>
                                <input
                                    id="UTR"
                                    type="text"
                                    name="utr"
                                    className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    placeholder="Enter UTR"
                                    onChange={(e) => handleInputChange('payment', 'utr', e.target.value)}
                                />
                            </DivTag>
                        ) : null}

                        <DivTag className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <Label
                                htmlFor="Payment_Date"
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Payment Amount
                            </Label>
                            <input
                                id="Payment_Date"
                                type="number"
                                name="amount"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white"
                                onChange={(e) => handleInputChange('payment', 'amount', e.target.value)}
                            />
                        </DivTag>
                    </DivTag>
                </DivTag>

                <div className="flex pt-4 justify-center gap-4">
                    <Buttons
                        label="Previous"
                        severity="secondary"
                        icon="pi pi-arrow-left"
                        onClick={Prev}
                        className="bg-blue-400 py-2 px-4 sm:py-1 sm:px-2 text-white"
                    />
                    <Buttons
                        label="Submit"
                        severity="secondary"
                        icon="pi pi-arrow-right"
                        defaultValue="Confirm"
                        onClick={() => handleSubmit()}
                        className="bg-blue-600 py-2 px-4 sm:py-1 sm:px-2 text-white"
                    />

                </div>

            </fieldset>
        </>
    );
};

export default SOpaymentData;