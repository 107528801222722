import React, { useEffect, useMemo, useState } from 'react';
import DivTag from '../../Components/DivComponent/DivTag';
import Button from '../../Components/ButtonComponent/Button';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import SearchApiUserFiled from '../../Components/SearchApiUserComponent/SearchApiUserFiled';
import ReportHeading from '../../Components/ReportHeadings/ReportHeadings';
import FormValidation from '../../Components/FormValidation/FormValidation';
import Option from '../../Components/OptionComponent/Option';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import { motion } from 'framer-motion';
import FormTag from '../../Components/FormComponent/FormTag';
import ButtonReport from '../../Components/ButtonComponent/ButtonReport';
import Pagination from '../../Components/Pagination/Pagination';
import ActivatePopup from './ActivatePopup';
import printTable from '../../Components/TablePrintExcelPdfSearch/tablePrint';
import downloadExcel from '../../Components/TablePrintExcelPdfSearch/downloadExcel';
import downloadPdf from '../../Components/TablePrintExcelPdfSearch/downloadPdf';
import InputField from '../../Components/InputComponent/InputField';
import 'jspdf-autotable'; // Import AutoTable plugin
import { RetriveServiceReportService, toggleService } from '../../../services/apiaddservice';
import UpdatePopUp from './UpdatePopUp';
import { ToastContainer } from 'react-toastify';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';
import ConfirmPopUp from '../../../components/Dialog/ConfirmPopup';
import Seo from '../../../components/SeoComponent/Seo';


const ActivateService = () => {

    //Activate Button PopUp Click 
    const [isModalOpenActivatePopup, setIsModalActivatePop] = useState(false);
    const [isEditPopOpen, setIsEditPopOpen] = useState(false);
    const [selectedId, setSelectedId] = useState('');
    const [isToggle, setIsToggle] = useState(false);

    // Function to open the modal
    const openActivatePopup = (id) => {
        setSelectedId(id)
        setIsModalActivatePop(true);
    };

    // Function to close the modal
    const onCloseActivatePop = () => {
        setIsModalActivatePop(false);
    };



    const initialState = {
        select_service: ''
    };

    const validate = (values) => {
        let errors = {};

        if (!values.select_service) {
            errors.select_service = 'Select Service';
        }
        return errors;
    };

    const { values, errors, handleChange, handleBlur } = FormValidation(
        initialState,
        validate
    );

    // Define default table data
    const [defaultTableData, setDefaultTableData] = useState([

    ]);

    // State to track if search button is clicked
    const [searchClicked, setSearchClicked] = useState(false);

    // Function to handle search button click
    const handleSubmitClick = () => {
        // Update the state to indicate that the search button is clicked
        setSearchClicked(true);
    };

    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5); // Change this number as per your requirement

    // Calculate total number of pages
    const totalPages = Math.ceil(defaultTableData.length / itemsPerPage);

    // Logic to handle pagination
    const paginate = (pageNumber) => setCurrentPage(pageNumber);



    // Printing function starts here 
    const handlePrint = () => {
        printTable('table');
    };
    // Printing Function Ends

    // Download function starts here
    const onDownloadExcel = () => {
        const headers = Object.keys(defaultTableData[0]).filter(header => header !== 'Activate');
        downloadExcel(headers, defaultTableData);
    };
    // Download function ends here

    // Download pdf function starts here
    const onDownloadPdf = () => {
        // Extract headers from the defaultTableData
        const headers = Object.keys(defaultTableData[0]).filter(header => header !== 'Activate');

        // Call downloadPdf with both defaultTableData and headers
        downloadPdf(defaultTableData, headers);
    };
    // Download pdf function ends here

    // Get current items
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = defaultTableData.slice(indexOfFirstItem, indexOfLastItem);

    //Search By Input Field of Table Data
    const [searchQuery, setSearchQuery] = useState('');

    // Function to handle changes in the search input
    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const handleUpdatePop = (id) => {
        setSelectedId(id);
        setIsEditPopOpen(true);
    }
    const handleToggle = (id) => {
        setSelectedId(id);
        setIsToggle(true);
    }
    // Filtered items based on search query
    // const filteredItems = currentItems.filter(item =>
    //     item.ServiceCategory.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     item.ServiceName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     item.Status.toLowerCase().includes(searchQuery.toLowerCase()) ||
    //     item.ServiceCreationDate.toLowerCase().includes(searchQuery.toLocaleLowerCase())
    // );
    //Search Filter End

    const handleActivate = async () => {

        try {


            const respnse = await toggleService(selectedId);

            if (respnse.status.code === 200) {
                await retriveSrviceReport();
                showSuccessToast(respnse.status.message);
                setIsToggle(false)
                return
            } setIsToggle(false)
            showErrorToast('Failed to activate ');
            return

        } catch (error) {
            setIsToggle(false);
            showErrorToast('An Error occurs   ');
            return
        }
    }
    async function retriveSrviceReport() {
        try {
            const response = await RetriveServiceReportService();
            console.log(response)
            if (response.status === "success") {
                setDefaultTableData(response.data);
                return
            }
        } catch (error) {
            return
        }
    }
    useEffect(() => {
        retriveSrviceReport();
    }, [isEditPopOpen])
    return (

        <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1.2 }}>
            <Seo />

            {/* <DivTag id="content" className="main-content border-2 border-solid border-blue-800 rounded-xl mt-12 bg-white"> */}
            <DivTag id="" className="border-2 border-solid border-blue-800 rounded-xl mt-12 bg-white">

                <ReportHeading name="Activate Service" />
                <FormTag
                    method=""
                    autoComplete="off"
                >
                    <DivTag className="flex justify-center items-center mt-3">

                        <SelectWithLabelOption
                            DivclassName="w-full md:w-1/4 px-2 md:mb-0"
                            label="Select Service"
                            id="select_service"
                            name="select_service"
                            value={values.select_service}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required={true}
                            className={errors.select_service ? 'border border-red-500' : 'border border-blue-600'}
                            error={errors.select_service}>
                            <Option value="">Select Service</Option>
                            <Option value="service1">Service1</Option>
                            <Option value="service2">Service2</Option>
                            <Option value="service3">Service3</Option>
                        </SelectWithLabelOption>


                        <DivTag className="w-full md:w-1/5 px-2 md:mb-0 md:mt-4 mt-9">
                            <ButtonReport
                                type="submit"
                                id="submit"
                                name="submit"
                                onClick={handleSubmitClick}
                                text="submit"
                            />
                        </DivTag>


                    </DivTag>
                </FormTag>
                <HeadingTag className="text-2xl text-blue-800 font-bold bg-white text-center mt-2 mb-2" text="Report" />


                {/* Print,pdf,excel,SearchFilter */}
                <DivTag className="flex">
                    <DivTag className="mr-auto p-0.5">
                        {/* Button printing */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={handlePrint}
                            text="Print"
                        />

                        {/* Button downloadexcel */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={onDownloadExcel}
                            text="Excel"
                        />

                        {/* Button  downloadPdf  */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={onDownloadPdf}
                            text="PDF"
                        />
                    </DivTag>
                    {/* Input Search Field */}

                    <InputField
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        placeholder="Search.........."
                        className="appearance-none block text-gray-700 border rounded mb-1
                leading-tight focus:outline-none focus:bg-white text-center"
                    />
                </DivTag>
                <UpdatePopUp visible={isEditPopOpen} onClose={() => setIsEditPopOpen(false)} selectedId={selectedId} />
                <div
                    className={isToggle ? 'w-full h-full bg-transparent backdrop-blur-sm absolute top-0 left-0 z-50' : ""}>
                    <ConfirmPopUp visible={isToggle} onClose={() => setIsToggle(false)} onConfirm={handleActivate} title=
                        {"Click Yes to Continue the submission .  No For Cancel?"} /></div>
                <div className="flex justify-center mb-1 overflow-x-auto">
                    <table className="min-w-full table-auto border-collapse">

                        <thead className="text-md">
                            <tr className="bg-blue-800 text-white text-center">
                                <th className="p-2 border-2">S No.</th>
                                <th className="border-2">Service Category</th>
                                <th className="border-2">Service Name</th>
                                <th className="border-2">Status</th>
                                <th className="border-2">Service Creation Date</th>
                                <th className='border-2'>Action</th>
                                <th className='border-2'>Status_Toggle</th>
                                <th className='border-2'>Activation_Service</th>
                            </tr>
                        </thead>
                        <tbody className="overflow-y-scroll w-80">
                            {
                                defaultTableData && defaultTableData.length > 0 ?
                                    defaultTableData.map((item, index) => (
                                        <tr key={index} className='text-center'>
                                            <td className="text-center whitespace-nowrap border text-sm">{index + 1}</td>
                                            <td className="text-center whitespace-nowrap border text-sm">{item.service_category.name}</td>
                                            <td className="text-center whitespace-nowrap border text-sm ">{item.service_name}</td>
                                            <td className={item?.status.value==="active"?" whitespace-nowrap text-sm text-green-500 border  text-center":" whitespace-nowrap text-sm text-red-500 border  text-center"}> {item?.status.value} </td>
                                            <td className="text-center whitespace-nowrap border text-sm">{item?.created_at.substring(0, 10)}</td>
                                            <td className='whitespace-nowrap border text-sm'>   <Button
                                                type=""
                                                className="text-white bg-red-500 rounded-sm px-1 text-xs  hover:bg-blue-400 hover:text-white"
                                                id="activate"
                                                name="Edit"
                                                onClick={() => handleUpdatePop(item.id)}
                                                text="Edit"
                                            /></td>
                                            <td className='whitespace-nowrap border text-sm' >
                                                <button className='border border-sky-400 h-8 w-16 rounded-2xl bg-white  shadow-lg  ' onClick={() => handleToggle(item?.id)}>
                                                    <div className={item.status.value === "active" ? 'bg-green-500 translate-x-8 duration-200 transition-all rounded-full h-7 w-7' : "bg-red-500 rounded-full h-7 w-7 translate-x-0.1 duration-200 transition-all"} />
                                                </button>
                                            </td>
                                            <td className="text-center whitespace-nowrap border text-sm">
                                                {item.Activate}
                                                <Button
                                                    type=""
                                                    className="text-white bg-red-500 rounded-sm px-1 text-xs  hover:bg-blue-400 hover:text-white"
                                                    id="activate"
                                                    name="activate"
                                                    onClick={()=>openActivatePopup(item.id)}
                                                    text="Activate"
                                                />
                                            </td>
                                        </tr>

                                    ))
                                    :
                                    <tr>
                                        <td colSpan="5" className="px-4 py-2 text-center text-black text-xl">Data Not Found</td>
                                    </tr>
                            }
                        </tbody>
                    </table>
                </div>
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={paginate} />

            </DivTag>
            {isModalOpenActivatePopup && <ActivatePopup serviceId={selectedId} onCloseActivatePop={onCloseActivatePop} />} {/* Render the modal */}
            <ToastContainer />
        </motion.div>

    );
};

export default ActivateService;