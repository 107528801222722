import React, { useState } from 'react';
import DivTag from '../../Components/DivComponent/DivTag';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import SpanTag from '../../Components/SpanComponent/SpanTag';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import Button from '../../Components/ButtonComponent/Button';
import { motion } from 'framer-motion';

const ProvidersActionPopup = ({ onCloseActionPop , visible, className}) => {
    const dropIn = {
        hidden: {
            y: "-100vh",
            opacity: 0,
        },
        visible: {
            y: "0",
            opacity: 1,
            transition: {
                duration: 0.1,
                type: "spring",
                damping: 25,
                stiffness: 500,
            },
        },
        exit: {
            y: "100vh",
            opacity: 0,
        },
    };

 
    return (
        <motion.div
        initial="hidden"
        animate={visible ? "visible" : "hidden"}
        exit="exit"
        variants={dropIn}
        className={` shadow-lg rounded-lg border-2 backdrop-blur-2xl bg-neutral-50  border-blue-700 w-full md:h-[56%] h-[42%]  max-w-lg fixed inset-0 m-auto p-1 ${className}`}
    >
       
                <DivTag className="flex justify-between items-center p-1 border-b border-gray-200">
                    <HeadingTag className="text-lg font-semibold text-red-700 " text="Add Providers" />
                    <Button type="button" className="text-gray-500 hover:text-gray-700" onClick={onCloseActionPop}>
                        <SpanTag aria-hidden="true" className="text-white px-2 hover:bg-blue-800 hover:text-white text-2xl bg-red-700 rounded-full">&times;</SpanTag>
                    </Button>
                </DivTag>

                <DivTag className="flex flex-wrap justify-center mt-3">

                <SelectWithLabelOption
                        DivclassName="w-1/2 md:w-1/2 px-2 md:mb-0"
                        label="Select Provider"
                        name="s_providers"
                        id="s_providers"
                        // value=""
                        onChange=""
                        required={true}
                        className='border border-blue-600'
                        >
                            <option value="" selected disabled>Select Providers</option>
                            <option value="Providers1">Providers1</option>
                            <option value="Providers2">Providers2</option>
                        </SelectWithLabelOption>
                </DivTag>

                <DivTag className="flex justify-center border-t border-gray-200 md:mb-3 mt-4">
                    <Button
                        type="button"
                        className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white rounded-md  px-1 py-0 text-md"
                        onClick=""
                    >
                        Submit
                    </Button>
                    
               
        </DivTag>
         <div className="overflow-x-auto my-1 p-2 md:mt-4">
         <table id="table" className="min-w-full divide-y divide-gray-200">
            <thead className=''>
                <tr className='bg-blue-800 text-white text-center'>
                <th className='p-2 border'>Sno</th>
                <td className='border'>Provider</td>
                </tr>
            </thead>
            <tbody className='text-center'>
                <tr key="">
                <td className=' shadow-inner shadow-blue-700'>1</td>
                <td className=' shadow-inner shadow-blue-700'>pd</td>
                </tr>
            </tbody>
            </table>
            </div>
        </motion.div>
    );
};

export default ProvidersActionPopup;
