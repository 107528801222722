import React, { useEffect, useMemo, useState } from 'react'
import PopMenu from '../../../components/Dialog/PopMenu'
import { RetriveServicecategoryService, RetriveServiceEditService, updateApiService } from '../../../services/apiaddservice';
import DivTag from '../../Components/DivComponent/DivTag';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import InputWithLabel from '../../Components/InputComponent/InputWithLabel';
import ReactQuill from 'react-quill';
import Option from '../../Components/OptionComponent/Option';
import TextAreaWithLabel from '../../Components/TextAreaWithLabel/TextAreaWithLabel';
import Button from '../../Components/ButtonComponent/Button';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';

const UpdatePopUp = ({ visible, onClose, selectedId, }) => {
    const [value, setValue] = useState('');
    const [serviceCategoryList, setServiceCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [serviceName, setServiceName] = useState('');
    const [postUrl, setPostUrl] = useState('');
    const [introduction, setIntroduction] = useState('');
    const [successResponse, setSuccessResponse] = useState('');
    const [requestResponse, setRequestResponse] = useState('');
    const [failedResponse, setFailedResponse] = useState('');
    const [activationFees, setActivationFees] = useState();
    const [isAgreementAgreed, setIsAgreementAgreed] = useState(false);
    const [adjustmentType, setAdjustmentType] = useState('');
    const modules_quill = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            [{ 'color': ['#ff0000', '#00ff00', '#0000ff', '#000000'] }], // Set font color options to red, green, blue
            ['clean']
        ]
    };
    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'color'
    ];


    async function RetriveServicecategory() {
        try {
            const respone = await RetriveServicecategoryService();

            if (respone.success === true) {
                setServiceCategoryList(respone.data);
                return;
            }
        } catch (error) {
            return error;
        }
    }
    async function RetriveEditService() {
        try {
            const response = await RetriveServiceEditService({ serviceId: selectedId });

            if (response.status.code === 200) {
                setValue(response.data.introduction);
                setPostUrl(response.data.end_point);
                setServiceName(response.data.service_name);
                setSelectedCategory(response.data.service_category_id);
                setRequestResponse(response.data.request);
                setFailedResponse(response.data.failed_response);
                setActivationFees(response.data.activation_fee);
                setSuccessResponse(response.data.respone)

            }
        } catch (error) {
            return error;
        }
    }
    const handleUpdateSerice = async () => {
        try {
            if (isAgreementAgreed === false) {
                console.log(isAgreementAgreed)
                return showErrorToast('Checked Agrement ')
            }
console.log(successResponse)
            const response = await updateApiService(
                {
                    serviceId: selectedId,
                    categoryId: selectedCategory,
                    serviceName: serviceName,
                    postUrl: postUrl,
                    introduction: value,
                    requestResponse: requestResponse,
                    failedResponse: failedResponse,
                    fees: activationFees,
                    adjustment_type: adjustmentType,
                    successResponse:successResponse
                }
            );
console.log(response)
            if (response.status.code === 200) {
                onClose();
                showSuccessToast('Successfully Update service ');

                return;
            }
            if (response.status.code === 422) {
                showErrorToast(response.status.message);
                return;
            }

        } catch (error) {
            showErrorToast('Failed to add new service ');
            return
        }
    }
    useEffect(() => {
        if (visible === true) {
            RetriveEditService();
            RetriveServicecategory();
            return
        }

    }, [visible])
    return (
        <div className='w-[100vw] bg-red-600' style={{ width: "100vw" }}>
            <PopMenu visible={visible} onClose={onClose} title={"Service Edit Form"} className={"overflow-y-scroll min-w-[90vw] z-50 h-[90vh]"}>
                <DivTag className="flex flex-wrap p-3 mt-2">

                    <SelectWithLabelOption DivclassName="w-full md:w-1/4 px-3 md:mb-0" label="Select Service Category" id="service" name="service" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} >
                        <Option value="#" disabled selected>Select Service Category</Option>
                        {
                            serviceCategoryList && serviceCategoryList.length > 0 ?
                                serviceCategoryList.map((category, index) => (
                                    <Option key={index} value={category.id} >{category?.name}</Option>
                                ))
                                : ""
                        }
                    </SelectWithLabelOption>
                    <SelectWithLabelOption DivclassName="w-full md:w-1/4 px-3 md:mb-0" label="Select Adjustment_type
" id="service" name="service" value={adjustmentType} onChange={(e) => setAdjustmentType(e.target.value)} required={true} >
                        <Option value="" disabled selected>Select adjustment_type
                        </Option>
                        <Option value="charge" >charge
                        </Option>
                        <Option value="commission" >commission
                        </Option>

                    </SelectWithLabelOption>
                    <InputWithLabel DivclassName="w-full md:w-1/4 px-2 md:mb-0 text-left mb-0 text-white"
                        DivTagClassName="absolute z-60 ml-3 mt-16 w-40 bg-white border rounded shadow-md" label="Service Name" type="text" name="service_name" placeholder="Enter Service Name..." id="service_name" value={serviceName} onChange={(e) => setServiceName(e.target.value)} className="border" required={true} />
                    <InputWithLabel DivclassName="w-full md:w-1/4 px-2 md:mb-0 text-left mb-0 text-black"
                        DivTagClassName="absolute z-60 ml-3 mt-16 w-40 bg-white border rounded shadow-md" label="Post URL" type="url" name="post_url" placeholder="Enter POST URL..." id="post_url" value={postUrl} onChange={(e) => setPostUrl(e.target.value)} className="border" required={true} />
                    <div className="d-flex w-full">
                        <div className="flex flex-col my-20">
                            <label className='block uppercase tracking-wide text-s font-bold mb-1'>Instruction <span className="text-red-500">*</span></label>
                            <ReactQuill
                                theme="snow"
                                value={value}
                                onChange={setValue}
                                modules={modules_quill}
                                formats={formats}
                                placeholder='Enter Instruction here....'
                                required={true}
                                className='h-20'
                            />
                        </div>
                        <div className='flex gap-3'>
                            <TextAreaWithLabel DivclassName="w-full px-2 md:mb-0 text-left mb-0 text-black"
                                label="Request"
                                type="text"
                                placeholder="Enter Request Sample"
                                name="req_sample"
                                id="req_sample"
                                value={requestResponse}
                                onChange={(e) => setRequestResponse(e.target.value)}
                   
                                class_name='h-72'
                                 />
                            <TextAreaWithLabel DivclassName="w-full px-2 md:mb-0 text-left mb-0 text-black"
                                label="Sucess Response"
                                type="text"
                                placeholder="Enter Request Sample"
                                name="successResponse"
                                id="req_sample"
                                value={successResponse}
                                onChange={(e) => setSuccessResponse(e.target.value)}
                               
                                class_name='h-72'
                                />
                        </div>
                    </div>

                    <TextAreaWithLabel DivclassName="w-full px-2 md:mb-0 text-left mb-0 text-black"
                        label="faled Response "
                        type="text"
                        placeholder="Failed Response "
                        name="req_sample"
                        id="req_sample"
                        value={failedResponse}
                        onChange={(e) => setFailedResponse(e.target.value)}

                        class_name='h-72'
                        required={true} />

                    <InputWithLabel DivclassName="w-full md:w-1/3 px-2 md:mb-0 text-left mb-0 text-black"
                        DivTagClassName="absolute z-60 ml-3 mt-16 w-40 handleSearcherbg-white border rounded shadow-md" label="Service Price" type="number" name="post_url" placeholder="Enter Price " id="post_url" value={activationFees} onChange={(e) => setActivationFees(e.target.value)} className="border" required={true} />

                    <div className='flex mt-5 ml-6 items-center gap-2 '>
                        <input type="checkbox" className='h-5 w-5' onChange={() => setIsAgreementAgreed(!isAgreementAgreed)} />
                        <p className='text-neutral-500 '>I have agreed terms & condition</p>
                    </div>

                    <Button type="submit" className="text-center mx-auto text-white bg-blue-700 shadow-lg bg-gradient-to-r mt-3 from-blue-900 via-teal-400 to-purple-900 font-semibold rounded-md px-2 py-1 text-lg hover:bg-red-300"
                        onClick={handleUpdateSerice}
                    >
                        Update
                    </Button>
                </DivTag>
            </PopMenu>
        </div>
    )
}

export default UpdatePopUp
