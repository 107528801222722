import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { verifyPin } from '../../../services/authService';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';
import Cookies from 'js-cookie';

const PinInput = ({ inputRefs, onPinChange }) => {
  const [pin, setPin] = useState(['', '', '', '', '', '']);

  const handleChange = (index, value) => {
    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    if (onPinChange) {
      onPinChange(newPin.join('')); // Pass the full PIN to onPinChange
    }

    if (value !== '' && index < 5) {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const handleBackspace = (index, event) => {
    if (event.key === 'Backspace' && index > 0 && pin[index] === '') {
      inputRefs.current[index - 1].current.focus();
    }
  };

  return (
    <div className="flex justify-center">
      {pin.map((value, index) => (
        <React.Fragment key={index}>
          <input
            ref={inputRefs.current[index]}
            type="password"
            maxLength={1}
            value={value}
            onChange={(e) => handleChange(index, e.target.value)}
            onKeyDown={(e) => handleBackspace(index, e)}
            className="border border-blue-600 w-10 h-10 text-center ml-2 rounded shadow-lg shadow-blue-600"
            autoFocus={index === 0}
          />
          {index === 2 && <span className="mt-2 text-sky-500 ml-1">&ndash;</span>}
        </React.Fragment>
      ))}
    </div>
  );
};

const ModalWithPinInput = ({ isModalOpen, toggleModal, tempToken }) => {
  const inputRefs = useRef(Array.from({ length: 6 }).map(() => React.createRef()));
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (pin.length !== 6) {
      setError('Please enter a 6-digit PIN.');
      return;
    }

    try {
      const result = await verifyPin(pin, tempToken);
      if (result.success) {
        showSuccessToast(result.message);
        Cookies.set('authToken', result.token); // Save token in local storage
        navigate('/admin-dashboard'); // Redirect to dashboard after successful PIN verification
        toggleModal(); // Close the modal
      } else {
        showErrorToast(result.message);
      }
    } catch (error) {
      showErrorToast("Server error occurred.");
    }
  };

  return (
    isModalOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/3">
        <div className="bg-[#130092] text-white text-center py-4 rounded-t-lg">
          <span className="text-xl">Enter PIN</span>
        </div>
        <div className="p-6">
          <PinInput inputRefs={inputRefs} onPinChange={setPin} />
          {error && <div className="text-red-500 mt-2 text-center">{error}</div>}
        </div>
        <div className="flex justify-between p-6">
          <button
            type="button"
            className="bg-red-900 shadow-xl shadow-red-600 bg-gradient-to-r from-red-400 to-red-900 text-white text-xl py-0 px-2 rounded-md hover:from-red-900 hover:to-red-400 focus:outline-none"
            onClick={toggleModal}
          >
            Close
          </button>
          <button
            type="button"
            className="text-center text-white bg-blue-900 shadow-xl  shadow-blue-600  bg-gradient-to-r from-blue-400 to-blue-900 font-semibold rounded-md px-2 py-0 text-xl hover:from-blue-900 hover:to-blue-400 border-1"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </div>
      </div>
    )
  );
};

export default ModalWithPinInput;
