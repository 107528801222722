import React, { useState, useEffect } from 'react';
import DivTag from '../Components/DivComponent/DivTag';
import Button from '../Components/ButtonComponent/Button';
import FormValidation from '../Components/FormValidation/FormValidation';
import InputWithLabel from '../Components/InputComponent/InputWithLabel';
import HeadingTag from '../Components/HeadingComponent/HeadingTag';
import ReportHeading from '../Components/ReportHeadings/ReportHeadings';
import { AddMasterTrainingService, RetriveMasterTrainingReport } from '../../services/MasterTraining/mastertraining';
import { motion } from 'framer-motion';
import Seo from '../../components/SeoComponent/Seo';

const MasterTraining = () => {   

const [videoLink,setVideoLink] = useState('');
const [description,setDescription] = useState('');
const [rank, setRank] = useState('');
const [desData, setDesData] = useState('');


    const initialState = {
        videoLink: '',
        description: '',
        remark: ''
    };

    const validate = (values) => {
        let errors = {};

        // Add validation logic here
        if (!values.videoLink) {
            errors.videoLink = 'VideoLink is required';
        }
        if (!values.description) {
            errors.description = 'Descrption is required';
        }
        if (!values.rank) {
            errors.rank = 'rank is required';
        }
        return errors;
    };

    const { values, errors } = FormValidation(
        initialState,
        validate
    );

    // Function to handle Submit button click
    const handleSubmit = () => {
    
    };

    return (

        <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
    >
        <Seo />
   <DivTag id="" className=" mt-8 bg-white">
            <ReportHeading name="Master For Training" />
                <DivTag className="flex justify-center items-center">
                        <InputWithLabel
                            DivclassName="w-1/2 md:w-1/6 px-2 md:mb-0"
                            label="Video Link"
                            type="text"
                            name="videolink"
                            placeholder="Enter Video Link"
                            id="videolink"
                            value={videoLink}
                           onChange={(e) => setVideoLink(e.target.value)}
                            className='border border-blue-600'
                            error={errors.videolink}
                        />
                    
                        <InputWithLabel
                            DivclassName="w-1/2 md:w-1/5 px-2 md:mb-0"
                            label="Description"
                            type="text"
                            placeholder="Enter Description"
                            name="txn_id"
                            id="txn_id"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            className='border border-blue-600'
                            error={errors.description}
                        />
                        <InputWithLabel
                         DivclassName='w-1/2 md:w-1/5 px-2 md:mb-0'
                         label='Rank'
                         type="text"
                         placeholder="Enter Rank"
                         name="rank"
                         id="rank"
                         value={rank}
                         onChange={(e) => setRank(e.target.value)}
                         className='border border-blue-600'
                         error={errors.rank}
                         />
                        <Button
                        type="submit"
                        className="text-center text-white bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900 mt-6
                         via-teal-400 to-purple-900 font-semibold p-0 rounded-md px-2 py-1 text-lg hover:bg-red-300"
                        onClick={handleSubmit}>
                        Submit
                    </Button>
                    </DivTag>

                    <DivTag className="flex justify-center">
                   
                </DivTag>


                <HeadingTag className="text-2xl text-blue-800 font-bold bg-white text-center mt-1" text="Report"/>
               
                <DivTag className="overflow-x-auto my-1 p-2">
                <table  className="min-w-full divide-y divide-gray-200">
                            <thead className="text-md">
                                <tr className="bg-blue-800 text-white text-center">
                                    <th className="border-2 p-2">S No.</th>
                                    <th className="border-2">Video Link</th>
                                    <th className="border-2">Description</th>
                                    <th className="border-2">Rank</th>
                                    <th className="border-2">Add Date</th>
                                    <th className='border-2'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-y-scroll text-center">
                                {/* {desData && desData.map((item) => { */}
                                <tr key="">
                                <td className='border shadow-inner shadow-blue-600 p-1'>1</td>
                                <td className='border shadow-inner shadow-blue-600 p-1'>www.link.com</td>
                                <td className='border shadow-inner shadow-blue-600 p-1'>Upto Mark</td>
                                <td className='border shadow-inner shadow-blue-600 p-1'>2</td>
                                <td className='border shadow-inner shadow-blue-600 p-1'>1999-12-12</td>
                                <td className='border shadow-inner shadow-blue-600 p-1'><button className='bg-green-500 rounded-sm px-1 py-0 text-white text-sm font-bold'>Edit</button>
                                <button className='bg-red-500 rounded-sm px-1 py-0 text-white text-sm font-bold ms-2'>Delete</button>
                                </td>
                               </tr>
                                {/* })} */}
                             
                            </tbody>
                        </table>
                        </DivTag>
          
                        </DivTag>
                    </motion.div>
  
        );
    };
    
    export default MasterTraining;













