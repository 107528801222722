// ButtonTag.js
import React from 'react';

const ButtonReport = ({type, text, id, name, onClick, areaLabel }) => (
  <button
  type={type}
    className="text-center text-white bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900
    via-teal-400 to-purple-900 font-semibold p-0 rounded-md px-1 py-1 text-lg hover:from-pink-500 hover:to-yellow-500"
    id={id}
    name={name}
    onClick={onClick}
    aria-label={areaLabel}
  >
    {text}
  </button>
);

export default ButtonReport;






