import { postRequest } from "../config/AxiosConfig";
import API_URLS from "../config/BackendConfig";
import { getAuthToken } from "../utils/tokenUtils";

export const fetchCustomerData = async (params) => {
  try {
    const token = getAuthToken();
    //console.log(params);
    const response = await postRequest({
      url: API_URLS.ADMIN + "customers",
      data: params,
      headers: { Authorization: `Bearer ${token}` },
    });
//console.log(response);
    return response;
  } catch (error) {
    console.error("Error fetching customer data:", error);
    return { error: error.message || "An unexpected error occurred." };
  }
};

// Edit Customer Details Service
export const editCusDetails = async ({ user_id }) => {
  try {
    const token = getAuthToken();
    const response = await postRequest({
      url: API_URLS.ADMIN + "edit/customer",
      data: { user_id },
      headers: { Authorization: `Bearer ${token}` },
    });
  //  console.log("res",response);
    return response;
  } catch (error) {
    console.error("Error fetching customer details:", error);
    throw error;
  }
};

// Update Customer Details Service
export const updateCustomerDetails = async ({  payload, id }) => {
  try {
    const token = getAuthToken();
    const response = await postRequest({
      url: `${API_URLS.ADMIN}update/customer/${id}`,
      data: payload, 
      headers: { Authorization: `Bearer ${token}` },
    });
    return response;
  } catch (error) {
    console.error("Error updating customer:", error);
    throw error;
  }
};

// ENd 
