
import Cookies from 'js-cookie';


// Store the token using a consistent key
export const storeToken = (token) => {
  Cookies.set("authToken", token, { expire: 0.125 });  // 0.125 days = 3 hours
};

// Retrieve the token using the same key
export const getToken = () => {
  return Cookies.get("authToken");
};

// Remove the token using the same key
export const removeToken = () => {
  Cookies.remove("authToken");
};

// Alias function to get the token (optional)
export const getAuthToken = () => {
  return getToken();
};

// Alias function to remove the token (optional)
export const removeauthToken = () => {
  removeToken();
};


export const isTokenExpired = () => {
  const token = getToken();
  // If the token is not found, it's considered expired
  if (!token) {
    return true;
  }
  // You can implement additional logic here if needed to check the token's expiration based on its content.
  return false;
};