import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Seo from '../../components/SeoComponent/Seo';
import { AddRouteService, fetchRoutes, ToggleStatusService } from '../../services/RouteService/routeService';
import { showErrorToast, showSuccessToast } from '../../utils/toastUtils';
import ConfirmPopUp from '../../components/Dialog/ConfirmPopup';
import Loader from '../../components/Loader/Loader';
import ReportHeading from '../Components/ReportHeadings/ReportHeadings';
import HeadingTag from '../Components/HeadingComponent/HeadingTag';
import Button from '../Components/ButtonComponent/Button';
import InputWithLabel from '../Components/InputComponent/InputWithLabel';
import SelectWithLabelOption from '../Components/SelectComponent/SelectWithLabelOption';
import TextAreaWithLabel from '../Components/TextAreaWithLabel/TextAreaWithLabel';
import Option from '../Components/OptionComponent/Option';
import RoutesEditModal from './RoutesEditModal';

const RouteList = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [lastPage, setLastPage] = useState(1);
  const [paginationData, setPaginationData] = useState();
  const [routes, setRoutes] = useState([]);
  const [formData, setFormData] = useState({
    routename: '',
    url: '',
    select_privacy: '',
    route_method: '',
    select_status: '',
    route_description: '',
  });
  const [errors, setErrors] = useState({});

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleEditClick = (id) => {
    setSelectedId(id);
   // console.log("st",id);
    setIsModalOpen(true); 
  };

  useEffect(() => {
    loadRoutes();
  }, [currentPage]);

  const loadRoutes = async (page = currentPage) => {
    setIsLoading(true);
    try {
      const response = await fetchRoutes(page);
      setRoutes(response?.data?.data);
      setPaginationData(response?.data?.pagination);
      setCurrentPage(response?.data?.pagination?.current_page);
      setLastPage(response?.data?.pagination?.last_page);
    } catch (error) {
      console.error('Error fetching routes:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const validate = (values) => {
    let errors = {};
    if (!values.routename) errors.routename = 'Route Name is required';
    if (!values.url) errors.url = 'URL is required';
    if (!values.select_privacy) errors.select_privacy = 'Privacy is required';
    if (!values.description) errors.description = 'Description is required';
    if (!values.select_status) errors.select_status = 'Status is required';
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    const validationErrors = validate(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
  
    const payload = {
      route_name: formData.routename,
      route_url: formData.url,
      route_method: 'GET', // Adjust as needed
      privacy: formData.select_privacy,
      route_description: formData.description,
      is_active: formData.select_status === '0', // Convert status to boolean
    };
  
    try {
      const response = await AddRouteService(payload);
        //console.log(response)
      if (response?.status.code === 200) {
        showSuccessToast('Route created successfully!');
        loadRoutes(); // Refresh data after adding route
       
        setFormData({
          routename: '',
          url: '',
          select_privacy: '',
          description: '',
          select_status: '1', 
        });
        return;
      } 
      //console.log(response?.status == 422)
      if (response?.status === 422) {
          showErrorToast( response?.data?.errors?.route_url[0] || 'The route url has already been taken.');
        return;
      }
  
    } catch (error) {
      console.error('Error creating route:', error);
      showErrorToast('Failed to create route.');
    }
  };

// Separate function to handle status toggling logic
const toggleStatusHandler = async (id) => {
  try {
    await ToggleStatusService({ id });
  } catch (error) {
    throw new Error('Error toggling status:', error);
  }
};
const refreshData = () => {
  loadRoutes(); 
};

const closeModalHandler = () => {
  closeModal(); 
};

// Main toggleStatus function
const toggleStatus = async () => {
  try {
    await toggleStatusHandler(selectedId);
    refreshData(); 
  } catch (error) {
    console.error(error.message); // Error handling
  } finally {
    closeModalHandler(); 
  }
};

  const openModal = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedId(null);
  };

  const handleUpdate = () => {
    loadRoutes(); // Refresh table data
  };

  return (
    <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1.2 }}>
      {isLoading && <Loader />}
      <ConfirmPopUp onConfirm={toggleStatus} visible={showModal} onClose={closeModal} title="Are you sure you want to submit?" />
      <Seo />
      <div id="" className="mt-5 bg-white p-2">
        <ReportHeading name="Route List" />
        {/* Form Section */}
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-5 gap-2 mt-2">
          <InputWithLabel label="Route Name" type="text" name="routename" placeholder="Enter name" value={formData.routename} onChange={handleChange} error={errors.routename} />

          <InputWithLabel label="URL" type="text" name="url" value={formData.url} placeholder="Enter URL" onChange={handleChange} error={errors.url}/>

          {/* <SelectWithLabelOption label="URL" name="url" value={formData.url} onChange={handleChange} error={errors.url} >
          <Option value="" selected disabled>Select Url</Option>
           <Option value="Dashboard">Dashboard</Option>
            <Option value="ProfileApiUser">Profile</Option>
            <Option value="VanCollectionReport">VanCollectionReport</Option>
            <Option value="WhitelistIp">WhitelistIp</Option>
            <Option value="WhitelistYourBankAc">WhitelistYourBankAc</Option>
            <Option value="CommissionCharges">CommissionCharges</Option>
            <Option value="AddFund">AddFund</Option>
            <Option value="ApiLink">ApiLink</Option>
            <Option value="HelpDesk">HelpDesk</Option>
            <Option value="EquifaxCrScore">EquifaxCrScore</Option>
            <Option value="HighmarkCrScore">HighmarkCrScore</Option>
            <Option value="BusinessWalletStructure">BusinessWalletStructure</Option>
            <Option value="PayoutReport">PayoutReport</Option>
            <Option value="ServicePage">ServicePage</Option>
            <Option value="DiscriptionLink">DiscriptionLink</Option>
            <Option value="RechargeAndBBPSReport">RechargeAndBBPSReport</Option>
            </SelectWithLabelOption> */}

          <TextAreaWithLabel label="Description" name="description" placeholder="Enter text message" value={formData.description} onChange={handleChange} error={errors.description} />
          <SelectWithLabelOption label="Select Privacy" name="select_privacy" value={formData.select_privacy} onChange={handleChange} error={errors.select_privacy}>
            <Option value="" selected disabled>Select Privacy</Option>
            <Option value="with-pin">With-Pin</Option>
            <Option value="without-pin">Without-Pin</Option>
          </SelectWithLabelOption>
          <SelectWithLabelOption label="Select Status" name="select_status" value={formData.select_status} onChange={handleChange} error={errors.select_status}>
            <Option value="" selected disabled>Select Status</Option>
            <Option value="1">Active</Option>
            <Option value="0">Inactive</Option>
          </SelectWithLabelOption>
        </div>
        <div className="flex justify-center mt-4">
          <Button
          className="text-center text-white bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900
                         via-teal-400 to-purple-900 font-semibold p-0 rounded-md px-2 py-1 text-lg hover:bg-red-300"
          type="submit" onClick={handleSubmit}>
            Submit
          </Button>
        </div>

                {/* Report Section */}
                <HeadingTag className="text-2xl text-blue-800 font-bold bg-white text-center mt-4" text="Report" />
        <div className="overflow-x-auto my-2 p-2">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="text-md">
              <tr className="bg-blue-800 text-white text-center">
                <th className="border-2 p-2">S No.</th>
                <th className="border-2">Route Name</th>
                <th className="border-2">URL</th>
                <th className="border-2">Description</th>
                <th className="border-2">Privacy</th>
                <th className="border-2">Action</th>
                <th className="border-2">Edit</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {routes?.length > 0 ? (
                routes.map((route, index) => (
                  <tr key={(currentPage - 1) * (lastPage) + index + 1}>
                    <td className="border shadow-inner p-1">{(currentPage - 1) * (paginationData?.per_page) + index + 1}</td>
                    <td className="border shadow-inner p-1">{route?.route_name}</td>
                    <td className="border shadow-inner p-1">{route?.route_url}</td>
                    <td className="border shadow-inner p-1">{route?.route_description}</td>
                    <td className="border shadow-inner p-1">{route?.privacy}</td>
                    <td className="border shadow-inner p-1">
                    <div className="flex justify-center items-center gap-2">
                <p className="font-semibold text-gray-700">Off</p>
  <button
    className="relative border h-6 w-14 rounded-2xl bg-white shadow-lg border-sky-400 flex items-center"
    onClick={() => openModal(route.id)}
  >
    <div
      className={`absolute h-6 w-6 rounded-full transition-all duration-200 ${
        route.is_active
          ? "bg-green-500 translate-x-8"
          : "bg-red-500 translate-x-0"
      }`}
    />
  </button>
  <p className="font-semibold text-gray-700">On</p>
</div>

                    </td>
                    <td className="border shadow-inner p-1">
                    <button
                  className="rounded-md p-1 bg-blue-700 text-white text-xs"
                  onClick={() => handleEditClick(route?.id)}
                >Edit</button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="px-4 py-2 text-center text-xl">Data Not Found</td>
                </tr>
              )}
            </tbody>
          </table>
         
            {isModalOpen && (
        <RoutesEditModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          routeId={selectedId}
          onUpdate={handleUpdate}
          
        />
      )}
      <div className='gap-2 mt-3'>
            <div className='flex justify-center gap-3'>
              <button className="py-1 text-xl rounded-md hover:bg-blue-800 px-4 bg-blue-500 text-white border-none cursor-pointer"
               onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1} >Prev</button>
              <button className="py-1 text-xl rounded-md hover:bg-blue-800 px-4 bg-blue-900 text-white border-none cursor-pointer"
               onClick={() =>setCurrentPage((prev) => Math.min(prev + 1, paginationData?.last_page || 1))}
                disabled={currentPage === paginationData?.last_page} >Next</button>
              <div className='flex justify-end'>
                <p className='text-[17px] font-bold'>Page {currentPage} of {lastPage}</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </motion.div>
    );
};

export default RouteList;
