
import React  from 'react';

const HeadingTag = ({className,text,children }) => (
  
    <h1
      className={className}
      text={text}
    >
      {children}
        {text}
  </h1>
);

export default HeadingTag;


