import React from 'react';
import DivTag from '../DivComponent/DivTag';
import SpanTag from '../SpanComponent/SpanTag';
import ParagraphTag from '../ParagraphComponent/ParagraphTag';

const WidgetComponent = ({ icon, value, text, totalSold }) => {
  return (
    <DivTag className="widget flex items-center bg-white shadow-md rounded-lg p-1 mb-2 border border-red-700">
      <DivTag className="widget-icon-container bg-blue-500 text-white rounded-full p-2 flex items-center justify-center mr-4">
        {icon}
      </DivTag>
      <DivTag className="flex-grow">
        <SpanTag className="block font-semibold text-gray-700 text-lg mb-1" text={text}></SpanTag>
        <SpanTag className="block text-2xl font-bold text-gray-900" text={value}></SpanTag>
        <ParagraphTag className="text-sm text-gray-500 mt-1" text={`Total ${totalSold} Sold`}></ParagraphTag>
      </DivTag>
    </DivTag>
  );
};

export default WidgetComponent;
