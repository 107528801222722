import React, { useState, useEffect, useMemo } from "react";
import DivTag from "../Components/DivComponent/DivTag";
import Button from "../Components/ButtonComponent/Button";
import InputWithLabel from "../Components/InputComponent/InputWithLabel";

import SelectWithLabelOption from "../Components/SelectComponent/SelectWithLabelOption";
import ReportHeading from "../Components/ReportHeadings/ReportHeadings";
import SearchApiUserFiled from "../Components/SearchApiUserComponent/SearchApiUserFiled";
import { motion } from "framer-motion";
import Option from "../Components/OptionComponent/Option";

import { RetriveApiKeyReportService, RetriveApiKeyReportServiceByFilter, toggleEnvService, toggleStatusService } from "../../services/apicustomerreportservice";
import { FaInbox } from "react-icons/fa";
import Seo from "../../components/SeoComponent/Seo";
import ConfirmPopUp from "../../components/Dialog/ConfirmPopup";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import { ToastContainer } from "react-toastify";
import Loader from "../../components/Loader/Loader";


const CustomerReport = () => {
  const initialState = {
    from_date: "",
    to_date: "",
    status: "",
    user_id: "",
    total_data: "5",
  };

  const validate = () => {
    let errors = {};
    return errors;
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationData, setPaginationData] = useState();
  const [apiKeyReport, setApiKeyReport] = useState([]);
  const [isStatusToggle, setIsStatusToggle] = useState(false);
  const [isEnvToggle, setIsEnvToggle] = useState(false);
  const [userId, setuserId] = useState('');
  const [totalData, setTotalData] = useState(5);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [serviceStatus, setServiceStatus] = useState('');
  const [apiKeyReportId, setApiKeyReportId] = useState('')
  const [isLoading, setIsLoading] = useState();
  function statusToggle(id) {

    setApiKeyReportId(id);
    setIsStatusToggle(true);
  }
  function envToggle(id) {

    setApiKeyReportId(id);
    setIsEnvToggle(true);
  }
  async function handleStatusToogle() {
    try {


      const respnse = await toggleStatusService({
        apiKeyId: apiKeyReportId
      });

      if (respnse.status.code === 200) {
        setIsStatusToggle(false)
        await RetriveApiKeyReport();

        showSuccessToast(respnse.status.message);

        return
      } setIsStatusToggle(false)
      showErrorToast('Failed to activate ');
      return

    } catch (error) {
      setIsStatusToggle(false);
      showErrorToast('An Error occurs   ');
      return
    }
  }
  async function handleEnvToogle() {
    try {


      const respnse = await toggleEnvService({
        apiKeyId: apiKeyReportId
      });

      if (respnse.status.code === 200) {
        setIsEnvToggle(false)
        await RetriveApiKeyReport();

        showSuccessToast(respnse.status.message);

        return
      } setIsEnvToggle(false)
      showErrorToast('Failed to activate ');
      return

    } catch (error) {
      setIsEnvToggle(false);
      showErrorToast('An Error occurs   ');
      return
    }
  }
  async function RetriveApiKeyReport() {
    try {
      setIsLoading(true)
      const response = await RetriveApiKeyReportService({ totalData: totalData, fromData: fromDate, toDate: toDate, userId: userId.toUpperCase(), serviceStatus, currentPage });

      if (response.status.code === 200) {
        setIsLoading(false)
        setApiKeyReport(response.data);
        setPaginationData(response.pagination)
        return
      }
    } catch (error) {
      setIsLoading(false)
      return error
    }
  }
  async function RetriveApiKeyReportByFilter() {
    try {
      const response = await RetriveApiKeyReportServiceByFilter({ totalData: totalData, fromData: fromDate, toDate: toDate, userId: userId.toUpperCase(), serviceStatus, currentPage });

      if (response.status.code === 200) {
        setApiKeyReport(response.data)
        setPaginationData(response.pagination)
        return
      }
    } catch (error) {
      return error
    }
  }

  useEffect(() => {

    RetriveApiKeyReport();
  }, [currentPage, totalData])


  return (
    <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1.2 }}
    >
      <Seo />
      <DivTag id="" className="mt-8 bg-white">
        <ReportHeading name="API Key Report" />
        {isLoading && <Loader />}
        <DivTag className="flex flex-col items-center gap-2  p-1">
          <DivTag className="flex flex-wrap gap-2">
            <div className="flex flex-col">
              <label htmlFor="userId">Search UserId</label>
              <input type="text"
                className="h-10 bg-white shadow-md border-sky-500 border rounded-md outline-none px-3"
                placeholder="Search userid...."
                value={userId.toUpperCase()} onChange={(e) => setuserId(e.target.value)} />
            </div>
            <InputWithLabel
              DivclassName="w-full md:w-1/6 px-2"
              label="From Date"
              type="date"
              name="from_date"
              id="from_date"
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
            // value={values.from_date}
            //   onChange={handleChange}
            //   onBlur={handleBlur}
            // className={`border rounded-md py-2 px-3 ${errors.from_date ? "border-red-500" : "border-blue-600"
            //   }`}
            // error={errors.from_date}
            />
            <InputWithLabel
              DivclassName="w-full md:w-1/6 px-2"
              label="To Date"
              type="date"
              name="to_date"
              id="to_date"
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
            // value={values.to_date}
            // onChange={handleChange}
            // onBlur={handleBlur}
            // className={`border rounded-md py-2 px-3 ${errors.to_date ? "border-red-500" : "border-blue-600"
            //   }`}
            // error={errors.to_date}
            />
            <SelectWithLabelOption
              DivclassName="w-full md:w-1/4 px-2"
              label="Records Per Page"
              name="total_data"
              id="total_data"
              value={totalData}
              onChange={(e) => setTotalData(e.target.value)}
            // value={values.total_data}
            // onChange={(e) => {
            //   handleChange(e);
            //   setItemsPerPage(Number(e.target.value)); // Update itemsPerPage state
            // }}
            // className="border  rounded-md py-2 px-3 border-blue-600"
            >
              <option value="">Select Record data</option>
              <option value="3">3</option>
              <option value="10">10</option>
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="250">250</option>
              <option value="500">500</option>
            </SelectWithLabelOption>
            <SelectWithLabelOption
              DivclassName="w-full md:w-1/4 px-2"
              label="Records Per Page"
              name="total_data"
              id="total_data"
              value={serviceStatus}
              onChange={(e) => setServiceStatus(e.target.value)}
            // value={values.s}
            // onChange={(e) => {
            //   handleChange(e);
            //   setItemsPerPage(Number(e.target.value)); // Update itemsPerPage state
            // }}
            // className="border  rounded-md py-2 px-3 border-blue-600"
            >

              <option value="">Select status</option>
              <option value="active">Active</option>
              <option value="inactive">In Active</option>
            </SelectWithLabelOption>
          </DivTag>

          <DivTag className="flex justify-center mt-1">
            <Button
              type="button"
              onClick={RetriveApiKeyReport}
              className="bg-blue-700 text-white rounded-md px-4 py-2 hover:bg-blue-800 transition duration-300"
            >
              Search
            </Button>
          </DivTag>
        </DivTag>
        <div
          className={isStatusToggle ? 'w-full h-full bg-transparent backdrop-blur-sm absolute top-0 left-0 z-50' : ""}>
          <ConfirmPopUp visible={isStatusToggle} onClose={() => setIsStatusToggle(false)} onConfirm={handleStatusToogle} title=
            {"Click Yes to Continue the submission .  No For Cancel?"} /></div>

        <div
          className={isEnvToggle ? 'w-full h-full bg-transparent backdrop-blur-sm absolute top-0 left-0 z-50' : ""}>
          <ConfirmPopUp visible={isEnvToggle} onClose={() => setIsEnvToggle(false)} onConfirm={handleEnvToogle} title=
            {"Click Yes to Continue the submission .  No For Cancel?"} /></div>
        <DivTag className="overflow-x-auto my-1 p-2">
          <table id="table" className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr className="bg-blue-700 text-center text-white">
                <th scope="col" className="p-2 text-xs  font-bold  tracking-wider text-center border border-black" >S.No </th>
                <th scope="col" className="p-2 text-xs  font-bold  tracking-wider text-center border border-black" >User ID </th>
                <th scope="col" className="p-2 text-xs  font-bold  tracking-wider text-center border border-black" >API Key</th>
                <th scope="col" className="p-2 text-xs  font-bold  tracking-wider text-center border border-black" >Status</th>
                <th scope="col" className="p-2 text-xs  font-bold  tracking-wider text-center border border-black" >Status_Toggle</th>
                <th scope="col" className="p-2 text-xs  font-bold  tracking-wider text-center border border-black" >Current_Env_Status</th>
                <th scope="col" className="p-2 text-xs  font-bold  tracking-wider text-center border border-black" >ENV_Toggle</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {/* {filteredItems.map((item) => ( */}
              {
                apiKeyReport && apiKeyReport.length > 0 ?
                  apiKeyReport.map((item, index) => (
                    <tr className="my-5" key={(currentPage - 1) * (paginationData?.per_page) + index + 1}>
                      <td className="p-1 whitespace-nowrap text-sm font-medium text-gray-900 border border-black text-center"> {(currentPage - 1) * (paginationData?.per_page) + index + 1} </td>
                      <td className=" whitespace-nowrap text-sm font-medium text-gray-900 border border-black text-center">{item?.userable.user_id}</td>
                      <td className=" whitespace-nowrap text-sm text-gray-500 border border-black text-center "> {item?.key} </td>
                      <td className={item?.status.value === "active" ? " whitespace-nowrap text-sm text-green-500 border border-black text-center" : " whitespace-nowrap text-sm text-red-500 border border-black text-center"}> {item?.status.value} </td>
                      <td className='whitespace-nowrap border text-sm flex justify-center' >
                        <button className='border h-8 w-16 rounded-2xl bg-white  shadow-lg  border-sky-400 ' onClick={() => statusToggle(item.id)}>
                          <div className={item.status.value === "active" ? 'bg-green-500 translate-x-8 duration-200 transition-all rounded-full h-7 w-7' : "bg-red-500 rounded-full h-7 w-7 translate-x-0.1 duration-200 transition-all"} />
                        </button>
                      </td>
                      <td className=" whitespace-nowrap text-sm text-gray-500 border border-black text-center "> {item?.environment} </td>
                      <td className='whitespace-nowrap border text-sm flex justify-center gap-3' >
                        <span className="text-sm font-semibold text-red-500">UAT</span> <button className='border h-8 w-16 rounded-2xl bg-white  shadow-lg  border-sky-400 ' onClick={() => envToggle(item.id)} >
                          <div className={item.environment === "production" ? 'bg-green-500 translate-x-8 duration-200 transition-all rounded-full h-7 w-7' : "bg-red-500 rounded-full h-7 w-7 translate-x-0.1 duration-200 transition-all"} />
                        </button> <span className="text-sm font-semibold text-green-500">PROD</span>
                      </td>
                    </tr>
                  ))

                  : <tr className="w-full flex justify-center items-center">
                    <td colSpan="5" className=" text-center flex justify-center items-center text-black text-xl">
                      <FaInbox />
                      Data Not Found</td>
                  </tr>
              }

              {/* ))} */}
            </tbody>
          </table>
        </DivTag>
        {
          apiKeyReport.length > 0 ?
            <div className="w-full flex justify-center gap-10">
              <button className="py-1 text-xl rounded-md hover:bg-blue-800 px-4 bg-blue-500 text-white border-none cursor-pointer"
                onClick={() =>
                  setCurrentPage((prev) => Math.max(prev - 1, 1))
                }
                disabled={currentPage === 1}

              >Prev</button>
              <p>{paginationData?.current_page}/{paginationData?.last_page}</p>
              <button className="py-1 text-xl rounded-md hover:bg-blue-800 px-4 bg-blue-900 text-white border-none cursor-pointer"
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, paginationData?.last_page || 1))
                }
                disabled={currentPage === paginationData?.last_page}
              >Next</button>
            </div>
            : ""
        }


        {/* <DivTag className="flex p-4 justify-center mt-4">
          <Button
            type="button"
            className="bg-blue-700 text-white rounded px-4 py-2 mr-2"
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <span className="text-lg">
            {currentPage} / {pagination.last_page || 1}
          </span>
          <Button
            type="button"
            className="bg-blue-700 text-white rounded px-4 py-2 ml-2"
            onClick={() =>
              setCurrentPage((prev) =>
                Math.min(prev + 1, pagination.last_page || 1)
              )
            }
            disabled={currentPage === pagination.last_page}
          >
            Next
          </Button>
        </DivTag> */}
        <ToastContainer />
      </DivTag>
    </motion.div>
  );
};

export default CustomerReport;