import  {React, useState} from 'react';
import DivTag from '../../Components/DivComponent/DivTag';
import ReportHeading from '../../Components/ReportHeadings/ReportHeadings';
import ImageCard from '../../Components/DivWithImageButton/DivWithImageButton';
import SpanTag from '../../Components/SpanComponent/SpanTag';
import TextAreaWithLabel from '../../Components/TextAreaWithLabel/TextAreaWithLabel';
import ImgTag from '../../Components/ImageComponent/ImgTag';
import ModalApproveAction from './ModalApproveAction';
import { motion } from 'framer-motion';
import Seo from '../../../components/SeoComponent/Seo';

const ActionForApprove = () => {

      //Approve Modal in the ActionForApprove Page
  const [isModalOpenApproveAction, setIsModalOpenApproveAction] = useState(false);

  const openModalApproveAction = () => {
    setIsModalOpenApproveAction(true);
  };

  const closeModalApproveAction = () => {
    setIsModalOpenApproveAction(false);
  };

    return ( 
        <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
    >
        <Seo />
        <DivTag id="content" className="main-content border-2 border-solid border-blue-800 rounded-xl border-t-0 mt-4 bg-white">

            <ReportHeading name="KYC Details" />
            <DivTag className="flex justify-center items-center">


                <DivTag className="flex p-1 m-1">

                    <ImageCard
                        imageUrl="https://imgs.search.brave.com/COAex8IszlP55EnK5dm1jhWbyj42UZ3DCwoOuem3MzE/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMuaW5kaWEuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy8yMDIz/LzA1L2FhZGhhci1j/YXJkLTIuanBnP2lt/cG9saWN5PU1lZGl1/bV9XaWR0aG9ubHkm/dz03MDA"
                        onViewClick=""
                        onApproveClick={openModalApproveAction}
                    />

                    <ImageCard
                        imageUrl="https://imgs.search.brave.com/COAex8IszlP55EnK5dm1jhWbyj42UZ3DCwoOuem3MzE/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMuaW5kaWEuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy8yMDIz/LzA1L2FhZGhhci1j/YXJkLTIuanBnP2lt/cG9saWN5PU1lZGl1/bV9XaWR0aG9ubHkm/dz03MDA"
                        onViewClick=""
                        onApproveClick={openModalApproveAction}
                    />

                    <ImageCard
                        imageUrl="https://imgs.search.brave.com/COAex8IszlP55EnK5dm1jhWbyj42UZ3DCwoOuem3MzE/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMuaW5kaWEuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy8yMDIz/LzA1L2FhZGhhci1j/YXJkLTIuanBnP2lt/cG9saWN5PU1lZGl1/bV9XaWR0aG9ubHkm/dz03MDA"
                        onViewClick=""
                        onApproveClick={openModalApproveAction}
                    />

                    <ImageCard
                        imageUrl="https://imgs.search.brave.com/COAex8IszlP55EnK5dm1jhWbyj42UZ3DCwoOuem3MzE/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMuaW5kaWEuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy8yMDIz/LzA1L2FhZGhhci1j/YXJkLTIuanBnP2lt/cG9saWN5PU1lZGl1/bV9XaWR0aG9ubHkm/dz03MDA"
                        onViewClick=""
                        onApproveClick={openModalApproveAction}
                    />


                </DivTag>

            </DivTag>

            <DivTag className="border-2 rounded-md text-blue-800 w-5/6 min-w-5/6 ml-28">
                <ReportHeading name="API User Details" />
                <DivTag className="flex p-1">

                    <DivTag className="flex flex-wrap justify-center">

                        <DivTag className="md:w-1/2 pr-4 pl-20 font-semibold rounded-sm text-lg">Api ID :
                            <SpanTag className="ml-24 font-normal" id="api_id"></SpanTag>
                        </DivTag>

                        <DivTag className="md:w-1/2 pr-4 pl-20 font-semibold rounded-sm text-lg">Comp Name :
                            <SpanTag className="ml-24 font-normal" id="company_name">CompName</SpanTag></DivTag>

                        <DivTag className="md:w-1/2 pr-4 pl-20 font font-semibold rounded-sm text-lg">Authorized Signatory :
                            <SpanTag className="ml-4 font-normal" id="auth_sign">Mr SIngh</SpanTag></DivTag>

                        <DivTag className="md:w-1/2 pr-4 pl-20 font-semibold rounded-sm text-lg">Aadhar :
                            <SpanTag className="ml-24 font-normal" id="aadhar">3453454346</SpanTag></DivTag>

                        <DivTag className="md:w-1/2 pr-4 pl-20 font-semibold rounded-sm text-lg">Pan :
                            <SpanTag className="ml-24 font-normal" id="pan">GEKh454A</SpanTag></DivTag>

                        <DivTag className="md:w-1/2 pr-4 pl-20 font-semibold rounded-sm text-lg">Mobile :
                            <SpanTag className="ml-24 font-normal" id="mobile">878345345</SpanTag></DivTag>

                        <DivTag className="md:w-1/2 pr-4 pl-20 font-semibold rounded-sm text-lg">E-mail :
                            <SpanTag className="ml-24 font-normal" id="email">idspay@gmail.com</SpanTag></DivTag>

                        <DivTag className="md:w-1/2 pr-4 pl-20 font-semibold rounded-sm text-lg">Address :
                            <SpanTag className="ml-24 font-normal" id="address">Patna, BIhar</SpanTag></DivTag>


                    </DivTag>

                </DivTag>
            </DivTag>



            <DivTag className="border-2 rounded-md text-blue-800 w-5/6 min-w-5/6 ml-28 mb-1 mt-2">
                <ReportHeading name="Digital KYC" />
                <DivTag className="flex p-1 m-2 ">

                    <DivTag className="flex flex-wrap justify-center">
                            
                            <ImgTag 
                            src="https://imgs.search.brave.com/COAex8IszlP55EnK5dm1jhWbyj42UZ3DCwoOuem3MzE/rs:fit:860:0:0/g:ce/aHR0cHM6Ly9zdGF0/aWMuaW5kaWEuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy8yMDIz/LzA1L2FhZGhhci1j/YXJkLTIuanBnP2lt/cG9saWN5PU1lZGl1/bV9XaWR0aG9ubHkm/dz03MDA" 
                            alt="digital_aadhar" 
                            className="w-44 h-30 object-cover rounded-lg m-1" 
                            />
                     

                        <TextAreaWithLabel
                            DivclassName="w-1/3 px-2 md:mb-0 mt-3 ml-5"
                            label="Response"
                            type="text"
                            placeholder="Enter Response"
                            name="remarks"
                            id="remarks"
                            required={true}
                        />

                        <DivTag className="flex flex-wrap justify-center min-w-2/3 w-2/3">
                            <DivTag className="w-2/3 pr-4 pl-24 font-semibold rounded-sm text-lg">Pan :
                                <SpanTag className="ml-20 font-normal" id="digital_pan">Verified</SpanTag></DivTag>

                            <DivTag className="w-2/3 pr-4 pl-24 font-semibold rounded-sm text-lg">Mobile :
                                <SpanTag className="ml-20 font-normal" id="digital_mobile">Verified</SpanTag></DivTag>

                            <DivTag className="w-2/3 pr-4 pl-24 font-semibold rounded-sm text-lg">E-mail :
                                <SpanTag className="ml-20 font-normal" id="digital_email">Verified</SpanTag></DivTag>
                        </DivTag>

                    </DivTag>

                </DivTag>
            </DivTag>


        </DivTag>
         {isModalOpenApproveAction && <ModalApproveAction closeModalApproveAction={closeModalApproveAction} />}
         </motion.div>
    );

};

export default ActionForApprove;



