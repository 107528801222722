import React from 'react';
import { FaTimes } from "react-icons/fa";
import { motion } from 'framer-motion';
import Backdrop from './Backdrop';

const PopMenu = ({ className, children, onClose, visible,title }) => {
    const dropIn = {
        hidden: {
            y: "-100vh",
            opacity: 0,
        },
        visible: {
            y: "0",
            opacity: 1,
            transition: {
                duration: 0.1,
                type: "spring",
                damping: 25,
                stiffness: 500,
            },
        },
        exit: {
            y: "100vh",
            opacity: 0,
        },
    };

    return (
        <Backdrop>
            <motion.div
                initial="hidden"
                animate={visible ? "visible" : "hidden"}
                exit="exit"
                variants={dropIn}
                className={`bg-white shadow-lg rounded-lg border border-gray-200 w-full max-w-lg fixed inset-0 m-auto p-3 ${className}`}
            >
                <div className="flex justify-between items-center border-b border-gray-200 pb-4">
                    <h2 className="text-xl font-semibold text-sky-500">{title}</h2>
                    <button
                        type="button"
                        className="text-gray-500 hover:text-gray-700"
                        onClick={onClose}
                    >
                        <FaTimes className="text-2xl" />
                    </button>
                </div>
                <div className="mt-1">
                    {children}
                </div>
               
            </motion.div>
        </Backdrop>
    );
};

export default PopMenu;
