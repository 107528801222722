import React from 'react';
import { FaStar } from 'react-icons/fa'; // Import React Icons
import { AiOutlineCheckCircle } from 'react-icons/ai'; // Import React Icons
import DivTag from '../DivComponent/DivTag'; // Import DivTag component
import ImgTag from '../ImageComponent/ImgTag'; // Import ImgTag component
import HeadingTag from '../HeadingComponent/HeadingTag'; // Import HeadingTag component
import ParagraphTag from '../ParagraphComponent/ParagraphTag'; // Import ParagraphTag component
import Button from '../ButtonComponent/Button'; // Import Button component

// Define the BadgeComponent function component
const BadgeComponent = ({ imageUrl, headingText, paragraphText }) => {
  return (
    <DivTag className="overflow-hidden text-center bg-white text-black mt-4 p-4  border border-red-700 rounded-lg shadow-lg flex flex-col items-center">
      <DivTag className="relative w-full h-32 mb-1">
        <div className="absolute inset-0 flex items-center justify-center">
          <AiOutlineCheckCircle className="text-blue-700 text-4xl" />
        </div>
        {[...Array(13)].map((_, index) => (
          <DivTag key={index} className="absolute w-2 h-2 bg-blue-600 rounded-full animate-float" style={{ top: `${Math.random() * 100}%`, left: `${Math.random() * 100}%` }}></DivTag>
        ))}
      </DivTag>
      <ImgTag src={imageUrl} className="h-24 w-24 rounded-full shadow-md mb-6" style={{ margin: 'auto' }} /> {/* Dynamic image source */}
      <HeadingTag className="text-black font-bold text-lg mb-2" text={headingText}></HeadingTag> {/* Dynamic heading text */}
      <ParagraphTag className="text-black mb-4 text-sm" text={paragraphText}></ParagraphTag> {/* Dynamic paragraph text */}
      <Button className="inline-block py-2 mb-4 px-4 text-xs font-semibold text-black bg-gradient-to-r from-cyan-400 to-blue-500 hover:from-cyan-500 hover:to-blue-600 rounded-lg shadow-md transition-transform transform hover:scale-105">
        Check Now
      </Button>
    </DivTag>
  );
};

export default BadgeComponent;
