import React from 'react';
import Button from '../ButtonComponent/Button';

// import 'jspdf-autotable';
// import jsPDF from 'jspdf';

// Printing function starts here
export  const printTable = (tableClassName) => {
  const table = document.querySelector(`.${tableClassName}`);
  if (table) {
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      const iframeDocument = iframe.contentDocument;
      iframeDocument.open();
      iframeDocument.write('<html><head><title>Table Print</title>');
      iframeDocument.write('<style>table {border-collapse: collapse; width: 100%;} th, td {border: 1px solid #000; padding: 8px;}</style>');
      iframeDocument.write('</head><body>');
      iframeDocument.write(table.outerHTML);
      iframeDocument.write('</body></html>');
      iframeDocument.close();
      iframe.onload = function () {
          iframe.contentWindow.print();
          setTimeout(() => {
              document.body.removeChild(iframe);
          }, 1000);
      };
  } else {
      alert('Table not found.');
  }
};
// Printing Function Ends here



  
  export default printTable;

