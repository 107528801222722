import React, { useState, useEffect } from "react";
import { editCusDetails } from "../../services/customerService";
import { updateCustomerDetails } from "../../services/customerService";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";

const EditCustomerModal = ({ isOpen, onClose, userId, onUpdate }) => {
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [getID, setGetID] = useState('');

  useEffect(() => {
    if (isOpen) fetchCustomerDetails();
  }, [isOpen]);

  const fetchCustomerDetails = async () => {
    setIsLoading(true);
    try {
      const response = await editCusDetails({ user_id: userId });
     // console.log(response.data.user.id)
      setGetID(response.data.user.id);
      setFormData({
        user: response?.data?.user || {},
        company: response?.data?.user?.company_details || {},   
      });
    
    } catch (error) {
      console.error("Failed to fetch customer details:", error);
      showErrorToast("Error fetching customer details.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e, section) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [section]: {
        ...(prev[section] || {}),
        [name]: value,
      },
    }));
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
  
    try {
      // Validate required fields
      if (!formData?.user?.id) {
        showErrorToast("User ID is missing.");
        setIsSubmitting(false);
        return;
      }
  
      // if (!formData?.company?.company_type_name) {
      //   showErrorToast("Company Type Name is required.");
      //   setIsSubmitting(false);
      //   return;
      // }
  
      // Prepare the payload
      const payload = {
        user: { ...formData?.user },
        company: {
          ...formData.company,
          company_type_name: formData?.company?.company_type_name || formData?.company?.company_type.name,
        },
      };
  
      // Make the API call
      const response = await updateCustomerDetails({
        payload,
        id: getID,
      });
    //  console.log(response)
      // Handle response
      if (response.status.code === 200) {
        showSuccessToast("Customer details updated successfully!");
        onClose();
        if (onUpdate) onUpdate(); // Notify parent to refresh data
        return
      } else if (response.status.code === 422 || response.status === 422) {
      
        showErrorToast(response?.data?.message || response?.status?.message || response?.data?.message || "Validation failed.");
      } 
    } catch (error) {
      console.error("Failed to update customer:", error);
      showErrorToast(
        error.response?.data?.message || "Error updating customer details."
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  
  if (!isOpen) return null;

  return (
  
       <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-lg w-11/12 max-w-4xl overflow-auto md:h-[70%] h-[96%]">
        {/* Modal Header */}
        <div className="p-1 border-b flex justify-between items-center">
          <h2 className="text-xl font-bold">Edit Customer</h2>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-900 text-2xl font-bold"
          >
            &times;
          </button>
        </div>

        {/* Modal Body */}
        <div className="p-1 space-y-1">
          {isLoading ? (
            <p className="text-center text-gray-500">Loading...</p>
          ) : (
            formData && (
              <>
                {/* User Details */}
                <div>
                  <h3 className="text-lg font-semibold mb-2">User Details</h3>
                  <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-1">
                    {[
                      { label: "Customer Name", name: "name", value: formData?.user?.name || "" },
                      { label: "Email", name: "email_address", value: formData?.user?.email_address || "", type: "email" },
                      { label: "Mobile", name: "mobile_number", value: formData?.user?.mobile_number || ""},
                      { label: "Pin Code", name: "pin_code", value: formData?.user?.pin_code || "" },
                      { label: "Landmark Address", name: "landmark_address", value: formData?.user?.landmark_address || "" },
                      { label: "Post Office", name: "post_office", value: formData?.user?.post_office || "" },
                      { label: "District", name: "district", value: formData?.user?.district || "" },
                      { label: "State", name: "state", value: formData?.user?.state || "" },
                      { label: "Date of Birth", name: "dob", type: "date", value: formData?.user?.dob ? formData?.user?.dob.slice(0, 10) : ""},
                      { label: "Aadhar", name: "aadhar_number", value: formData?.user?.aadhar_number || "" },
                      { label: "PAN", name: "pan_number", value: formData?.user?.pan_number || "" },
                    ].map((field, index) => (
                      <div key={index}>
                        <label className="block text-sm font-medium text-gray-700 mb-1">
                          {field.label}
                        </label>
                        <input
                          type={field.type || "text"}
                          name={field.name}
                          value={field.value}
                          onChange={(e) => handleChange(e, "user")}
                          placeholder={field.label}
                          className={`border rounded-md p-2 border-blue-600 w-full ${field.readOnly ? "bg-gray-100" : ""}`}
                          readOnly={field.readOnly}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                {/* Company Details */}
                <div>
  <h3 className="text-lg font-semibold mb-2">Company Details</h3>
  <div className="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 gap-1">
    {[
      { label: "Company Name", name: "company_name", value: formData?.company?.company_name || "" },
      { label: "Company Address", name: "company_address", value: formData?.company?.company_address || "" },
      { label: "Company CIN", name: "company_cin", value: formData?.company?.company_cin || "" },
      { label: "Company GST", name: "company_gst_no", value: formData?.company?.company_gst_no || "" },
      { label: "Company PAN", name: "company_pan", value: formData?.company?.company_pan || "" },
      { label: "Authorized Person", name: "authorized_person_name", value: formData?.company?.authorized_person_name || "" },
    ].map((field, index) => (
      <div key={index}>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          {field.label}
        </label>
        <input
          type="text"
          name={field.name}
          value={field.value}
          onChange={(e) => handleChange(e, "company")}
          placeholder={field.label}
          className="border rounded-md p-2 w-full border-blue-600"
        />
      </div>
    ))}
  <div>
  <label className="block text-sm font-medium text-gray-700 mb-1">
    Company Type
  </label>
  <select
  name="company_type_name"
  value={formData?.company?.company_type_name|| ""}
  onChange={(e) => handleChange(e, "company")}
  className="border rounded-md p-2 w-full border-blue-600"
>
  <option value="" disabled selected>select comp Type</option>
  <option value="Private Limited">Private Limited</option>
  <option value="Public Limited">Public Limited</option>
  <option value="NGO Sec-8">NGO Sec-8</option>
  <option value="OPC">OPC</option>
  <option value="LLP">LLP</option>
  <option value="Partnership Firm">Partnership Firm</option>
  <option value="Individual">Individual</option>
  <option value="Other's">Other's</option>
</select>

</div>
<div>
  <label className="block text-sm font-medium text-gray-700 mb-1">
    Selected Company Type
  </label>
  <select
    // name="company_type_name"
    value={formData?.company?.company_type.name || ""}
    onChange={(e) => handleChange(e, "company")}
    className="border rounded-md p-2 w-full border-blue-600 bg-slate-500 text-white" 
    disabled // This disables the dropdown, making it read-only.
  >
    <option value="" disabled>Select Company Type</option>
    <option value="Private Limited">Private Limited</option>
    <option value="Public Limited">Public Limited</option>
    <option value="NGO Sec-8">NGO Sec-8</option> 
    <option value="OPC">OPC</option>
    <option value="LLP">LLP</option>
    <option value="Partnership Firm">Partnership Firm</option>
    <option value="Individual">Individual</option>
    <option value="Other's">Other's</option>
  </select>
</div>

  </div>
</div>

              </>
            )
          )}
        </div>

        {/* Modal Footer */}
        <div className="p-2 border-t flex justify-center space-x-2">
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-2 py-1 rounded-md"
            disabled={isSubmitting}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className={`bg-blue-500 text-white px-2 py-1 rounded-md ${
              isSubmitting ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isSubmitting || isLoading}
          >
            {isSubmitting ? "Saving..." : "Save Changes"}
          </button>
        </div>
      </div>
    </div>
    
  );
};

export default EditCustomerModal;
