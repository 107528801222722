import React, { useState } from 'react';
import Button from '../../Components/ButtonComponent/Button';
import DivTag from '../../Components/DivComponent/DivTag';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import SpanTag from '../../Components/SpanComponent/SpanTag';
import FormValidation from '../../Components/FormValidation/FormValidation';
import InputWithLabel from '../../Components/InputComponent/InputWithLabel';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';

const PopUpComponent = ({ onClose }) => {
  // State to manage the page data
  const [pageData, setPageData] = useState({
    selectType: '', // Initialize with default values
    // Other data fields...
  });

  // Function to handle updating and saving the page data
  const handleUpdate = () => {
    console.log('Saving page data:', pageData);
    onClose();
  };

  // Function to handle changes in the select box
  const handleSelectChange = (event) => {
    setPageData({
      ...pageData,
      selectType: event.target.value, // Update the selectType field
    });
  };

  const initialState = {
    select_type: '',
    Remarks: '',
  };

  const validate = (values) => {
    let errors = {};

    if (!values.select_type) {
      errors.select_type = 'Type is required';
    }
    if (!values.Remarks) {
      errors.Remarks = 'Remarks is required';
    }
    return errors;
  };

  const { values, errors, handleChange, handleBlur } = FormValidation(initialState, validate);

  return (
    <DivTag className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
      <DivTag className="bg-white rounded-lg shadow-lg w-full max-w-lg">
        <DivTag className="modal-header flex justify-between items-center p-4 border-b border-gray-200">
          <HeadingTag className="text-xl font-semibold text-red-700">Confirmation</HeadingTag>
          <Button type="button" className="text-gray-500 hover:text-gray-700" aria-label="Close" onClick={onClose}>
            <SpanTag aria-hidden="true">&times;</SpanTag>
          </Button>
        </DivTag>
        <DivTag className="flex modal-body p-4">
          <SelectWithLabelOption
            DivclassName="w-full md:w-1/2 px-2 mb-4"
            label="Select Type"
            id="select_type"
            name="select_type"
            value={values.select_type}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`border ${errors.select_type ? 'border-red-500' : 'border-blue-600'} p-2 rounded`}
            error={errors.select_type}
          >
            <option value="">Select Type</option>
            <option value="Block">Block</option>
            <option value="De-Assign">De-Assign</option>
          </SelectWithLabelOption>

          <InputWithLabel
            DivclassName=" md:w-1/2 px-2 mb-4"
            label="Remarks"
            type="text"
            placeholder="Enter Remarks"
            name="Remarks"
            id="Remarks"
            value={values.Remarks}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`border ${errors.Remarks ? 'border-red-500' : 'border-blue-600'} p-2 rounded`}
            error={errors.Remarks}
          />
        </DivTag>
        <DivTag className="flex justify-center p-4 border-t border-gray-200">
          <Button
            type="button"
            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg px-2 py-1 text-lg"
            onClick={handleUpdate}
          >
            Update
          </Button>
        </DivTag>
      </DivTag>
    </DivTag>
  );
};

export default PopUpComponent;
