import React, { useState, useEffect } from 'react';
import { ImUsers } from "react-icons/im";
import { RiHomeOfficeLine, RiMenuFoldFill } from "react-icons/ri";
import { FaTableCells } from "react-icons/fa6";
import { HiOutlineDocumentText } from "react-icons/hi2";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CgCloseO } from "react-icons/cg";
import { Fade as Hamburger } from 'hamburger-react';

const SideBarMenuDashboard = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };  

  useEffect(() => {
    // Handle the initial sidebar state based on screen width
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        setIsSidebarOpen(true); // Keep sidebar open on desktop
      } else {
        setIsSidebarOpen(false); // Close sidebar on mobile
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMenuClick = (menuId) => {
    setActiveMenu(activeMenu === menuId ? null : menuId);
  };

  return (
    <>
      {/* Toggle Button for Mobile */}
      <button
        onClick={toggleSidebar}
        className="fixed left-1 z-50 size-11 bg-blue-500 text-white rounded-full md:hidden"
      >
        {isSidebarOpen ? <Hamburger duration={1} size={20}/> : <Hamburger duration={1} size={20}/>}
      </button>

      {/* Main Container */}
      <div className={`main-container inset-0 z-40 ${isSidebarOpen ? '' : 'sidebar-closed'}`} id="container">
        {/* Overlay for closing sidebar */}
        <div className={`overlay md:hidden inset-0 bg-black opacity-50  ${isSidebarOpen ? 'block' : 'hidden'}`} onClick={toggleSidebar}></div>

        {/* Sidebar */}
        <div
          className={`sidebar-wrapper sidebar-theme bg-gradient-to-r  bg-[#130092] rounded-md transition-all duration-300 ease-in-out shadow-lg 
          ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'} 
          fixed top-0 left-0 h-full md:h-auto md:static md:block  md:w-64 overflow-y-auto`}
        >
         
          {/* Sidebar Content */}
       <motion.div animate={{ x: [0, 100, 0] }}>
       <button
            onClick={toggleSidebar}
            className="fixed z-50 size-11 bg-blue-900 text-white rounded-full hidden md:block"
          >
            {isSidebarOpen ? <Hamburger duration={1} size={24} />  : <Hamburger duration={1} size={24}/>}
          </button>
    <nav id="sidebar" className="p-4">
      <div className="profile-card md:mt-4 mt-3 mb-6 bg-gradient-to-r from-blue-100 to-blue-300 p-4 rounded-lg shadow-md">
        <img src="../../assets/img/1.jpg" alt="Profile" className="w-16 h-16 rounded-full mx-auto mb-2 shadow-sm" />
        <h3 className="text-center text-lg font-semibold text-blue-900">IDSPay</h3>
        {/* <p className="text-center text-sm text-blue-700">SDE</p> */}
        <div className="text-center mt-4">
          <span className="inline-block bg-blue-400 text-white rounded-full px-2 py-1 text-xs shadow-sm">12 Mails</span>
          <span className="inline-block bg-red-400 text-white rounded-full px-2 py-1 text-xs ml-2 shadow-sm">11 Notifications</span>
        </div>
      </div>

      <ul className="list-unstyled menu-categories space-y-4">
        <li className="menu" >
          <Link
            to="/"
            id="dashboard"
            className="flex items-center p-2 rounded-lg bg-gradient-to-r px-10 from-blue-200 to-blue-500 hover:bg-blue-500 hover:text-white transition duration-300
              ease-in-out delay-50 bg-blue-500 hover:-translate-y-1 hover:scale-110 
              border border-red-400
            "
            onClick={() => handleMenuClick('dashboard')}
          >
            <img src='assets/img/das.png' className="text-blue-800 mr-2 size-6 bg-white rounded-full p-1" />
            <span className=''>Dashboard</span>
          </Link>
        </li>

        <li className={`menu ${activeMenu === 'apiUser' ? 'bg-blue-500 text-white' : ''}`}>
          <button
            className="flex items-center w-full p-2 rounded-lg bg-gradient-to-r px-10 from-blue-100 to-blue-500 hover:bg-blue-500
            ease-in-out delay-50 bg-blue-500 hover:-translate-y-1 hover:scale-110  hover:text-white transition duration-300 border border-red-400"
            onClick={() => handleMenuClick('apiUser')}
          >
            <img src='assets/img/user.png' className="text-blue-800 mr-2 size-6 bg-white rounded-full" />
            <span>API User</span>
          </button>
          {activeMenu === 'apiUser' && (
            <ul className="ml-4 mt-2 space-y-2">
              <li>
                <Link to="/ApiAccount" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Api Account</Link>
              </li>
              <li>
                <Link to="/ApiUserKyc" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Upload KYC</Link>
              </li>
              <li>
                <Link to="/ApiApproveKyc" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Approve Kyc</Link>
              </li>
              <li>
                <Link to="/Wallet_balance_management" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Wallet Bal Management</Link>
              </li>
              <li>
                <Link to="/wallet-transaction-report" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Wallet Bal Report</Link>
              </li>
              <li>
                <Link to="/CommissionMaster" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Commission Master</Link>
              </li>
              <li className='mb-3'>
                <Link to="/ManagePermission" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Manage Permission</Link>
              </li>
              <li className='mb-3'>
                <Link to="/FundApprove" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Fund Approve</Link>
              </li>
            </ul>
          )}
        </li>

        <li className={`menu ${activeMenu === 'manageCollections' ? 'bg-blue-500 text-white' : ''}`}>
          <button
            className="flex items-center w-full p-2 rounded-lg bg-gradient-to-r px-4 from-blue-100 to-blue-500 hover:bg-blue-500
            ease-in-out delay-50 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:text-white transition duration-300 border border-red-400"
            onClick={() => handleMenuClick('manageCollections')}
          >
            <img src='assets/img/mng.png' className="text-blue-800 mr-1 size-6 bg-white rounded-full" />
            <span>Manage Collections</span>
          </button>
          {activeMenu === 'manageCollections' && (
            <ul className="ml-4 mt-2 space-y-2">
              <li>
                <Link to="/IdfcVanConfig" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Idfc Van Config.</Link>
              </li>
              <li>
                <Link to="/IdfcVanReport" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Idfc Van Report</Link>
              </li>
              <li>
                <Link to="/ProvidersReport" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Providers Report</Link>
              </li>
            </ul>
          )}
        </li>

        <li className={`menu ${activeMenu === 'reports' ? 'bg-blue-500 text-white' : ''}`}>
          <button
            className="flex items-center w-full p-2 rounded-lg bg-gradient-to-r px-10 from-blue-100 to-blue-500
            ease-in-out delay-50 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-blue-500  hover:text-white transition duration-300 border border-red-400"
            onClick={() => handleMenuClick('reports')}
          >
            <img src='assets/img/rept.png' className="text-blue-800 mr-2 bg-white rounded-full size-6" />
            <span>Reports</span>
          </button>
          {activeMenu === 'reports' && (
            <ul className="ml-4 mt-2 space-y-2">
              <li>
                <Link to="/PayoutReport" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Payout Report</Link>
              </li>
              <li>
                <Link to="/CustomerReport" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Customer Report</Link>
              </li>
              <li>
                <Link to="/ApiKeyReport" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">ApKey Report</Link>
              </li>
            </ul>
          )}
        </li>

        <li className={`menu ${activeMenu === 'services' ? 'bg-blue-500 text-white' : ''}`}>
          <button
            className="flex items-center w-full p-2 rounded-lg bg-gradient-to-r px-10 from-blue-200 to-blue-500
             ease-in-out delay-50 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-blue-500 hover:text-white transition duration-300 border border-red-400"
            onClick={() => handleMenuClick('services')}
          >
            {/* <span className="las la-drafting-compass text-blue-800 mr-3"></span> */}
            <img src='assets/img/serv.png' className="text-blue-800 mr-3 size-6 bg-white rounded-full"  />
            <span>Services</span>
          </button>
          {activeMenu === 'services' && (
            <ul className="ml-4 mt-2 space-y-2 ">
              <li>
                <Link to="/AddService" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Add Service</Link>
              </li>
              <li>
                <Link to="/ActiveServiceReport" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Active Service Report</Link>
              </li>
              <li>
                <Link to="/ActivateService" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Activate Service</Link>
              </li>
              
            </ul>
          )}
        </li>

        <li className={`menu ${activeMenu === 'partnership' ? 'bg-blue-500 text-white' : ''}`}>
          <button
            className="flex items-center w-full p-2 rounded-lg bg-gradient-to-r px-10 from-blue-200 to-blue-500
            ease-in-out delay-50 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-blue-500  hover:text-white transition duration-300 border border-red-400"
            onClick={() => handleMenuClick('partnership')}
          >
            <img src="assets/img/agree.png" className="text-blue-800 mr-1 size-6 bg-white rounded-full"  />
            
            <span>Partnership</span>
          </button>
          {activeMenu === 'partnership' && (
            <ul className="ml-4 mt-2 space-y-2">
              <li>
                <Link to="/PartnerShip" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Partner Ship</Link>
              </li>
              <li>
                <Link to="/LeadReport" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Lead Report</Link>
              </li>
            
            </ul>
          )}
        </li>

        <li className={`menu ${activeMenu === 'MasterTraining' ? 'bg-blue-500 text-white' : ''}`}>
          <button
            className="flex items-center w-full p-2 rounded-lg bg-gradient-to-r px-10 from-blue-200 to-blue-500
            ease-in-out delay-50 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-blue-500  hover:text-white transition duration-300 border border-red-400"
            onClick={() => handleMenuClick('MasterTraining')}
          >
            <img src="assets/img/agree.png" className="text-blue-800 mr-1 size-6 bg-white rounded-full"  />
            
            <span>Master Training</span>
          </button>
          {activeMenu === 'MasterTraining' && (
            <ul className="ml-4 mt-2 space-y-2">
              <li>
                <Link to="/MasterTraining" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Master Of Training</Link>
              </li>
            
            </ul>
          )}
        </li>

        <li className={`menu ${activeMenu === 'MasterPage' ? 'bg-blue-500 text-white' : ''}`}>
          <button
            className="flex items-center w-full p-2 rounded-lg bg-gradient-to-r px-10 from-blue-200 to-blue-500
            ease-in-out delay-50 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-blue-500  hover:text-white transition duration-300 border border-red-400"
            onClick={() => handleMenuClick('MasterPage')}
          >
            <img src="assets/img/agree.png" className="text-blue-800 mr-1 size-6 bg-white rounded-full"  />
            <span>Master Page</span>
          </button>
          {activeMenu === 'MasterPage' && (
            <ul className="ml-4 mt-2 space-y-2">
              <li>
                <Link to="/MasterPage" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">Master Page</Link>
              </li>
            
            </ul>
          )}
        </li>

        <li className={`menu ${activeMenu === 'Routes' ? 'bg-blue-500 text-white' : ''}`}>
          <button
            className="flex items-center w-full p-2 rounded-lg bg-gradient-to-r px-10 from-blue-200 to-blue-500
            ease-in-out delay-50 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-blue-500  hover:text-white transition duration-300 border border-red-400"
            onClick={() => handleMenuClick('Routes')}
          >
            <img src="assets/img/agree.png" className="text-blue-800 mr-1 size-6 bg-white rounded-full"  />
            <span>Routes</span>
          </button>
          {activeMenu === 'Routes' && (
            <ul className="ml-4 mt-2 space-y-2">
              <li>
                <Link to="/RouteList" className="block px-1 border border-red-400 bg-blue-100 rounded-lg hover:bg-blue-200 transition duration-300 text-black ease-in-out delay-10 hover:-translate-y-1 hover:scale-40">List Routes</Link>
              </li>
            
            </ul>
          )}
        </li>

        

      </ul>
    </nav>
  </motion.div>
        </div>
      </div>
    </>
  );
};

export default SideBarMenuDashboard;
