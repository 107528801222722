
import React from 'react';

const ImgTag = ({ src, alt, className,style }) => (
 
    <img
      src={src}
      alt={alt}
      className={className}
      style={style}
    />
);

export default ImgTag;
