import React from 'react';
import DivTag from '../../Components/DivComponent/DivTag';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import SpanTag from '../../Components/SpanComponent/SpanTag';
import Button from '../../Components/ButtonComponent/Button';

const DetailsButtonPopup = ({ onCloseDetailsPop }) => {
    return (
        <DivTag className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-50">
            <DivTag className="bg-white rounded-lg shadow-lg w-full max-w-4xl">
                <DivTag className="flex justify-between items-center p-4 border-b border-gray-200">
                    <HeadingTag className="text-lg font-semibold text-red-700">Details</HeadingTag>
                    <Button type="button" className="text-gray-500 hover:text-gray-700" onClick={onCloseDetailsPop}>
                        <SpanTag aria-hidden="true">&times;</SpanTag>
                    </Button>
                </DivTag>

                <DivTag className="p-4">
                    <DivTag className="border-2 border-blue-800 rounded-md mb-4">
                        <DivTag className="flex flex-wrap p-2">
                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    Action Type:
                                    <SpanTag className="ml-2 font-normal">api_id</SpanTag>
                                </DivTag>
                            </DivTag>

                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    Activation Date:
                                    <SpanTag className="ml-2 font-normal">11/11/2000</SpanTag>
                                </DivTag>
                            </DivTag>

                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    CallBack URL:
                                    <SpanTag className="ml-2 font-normal">url8344</SpanTag>
                                </DivTag>
                            </DivTag>

                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    Token ID:
                                    <SpanTag className="ml-2 font-normal">3453454</SpanTag>
                                </DivTag>
                            </DivTag>

                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    IP Address:
                                    <SpanTag className="ml-2 font-normal">GEKh454A</SpanTag>
                                </DivTag>
                            </DivTag>

                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    Disable/Block Date:
                                    <SpanTag className="ml-2 font-normal">12/12/2000</SpanTag>
                                </DivTag>
                            </DivTag>

                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    Disable/Block Date Reason:
                                    <SpanTag className="ml-2 font-normal">Service</SpanTag>
                                </DivTag>
                            </DivTag>

                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    Domain:
                                    <SpanTag className="ml-2 font-normal">name@npsp</SpanTag>
                                </DivTag>
                            </DivTag>
                        </DivTag>
                    </DivTag>

                    <DivTag className="border-b-2 border-dotted border-red-700 my-4"></DivTag>

                    <DivTag className="border-2 border-blue-800 rounded-md mb-3 p-4">
                        <DivTag className="flex flex-wrap">
                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    Action Type:
                                    <SpanTag className="ml-2 font-normal">api_id</SpanTag>
                                </DivTag>
                            </DivTag>

                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    Activation Date:
                                    <SpanTag className="ml-2 font-normal">11/11/2000</SpanTag>
                                </DivTag>
                            </DivTag>

                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    CallBack URL:
                                    <SpanTag className="ml-2 font-normal">url8344</SpanTag>
                                </DivTag>
                            </DivTag>

                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    Token ID:
                                    <SpanTag className="ml-2 font-normal">3453454</SpanTag>
                                </DivTag>
                            </DivTag>

                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    IP Address:
                                    <SpanTag className="ml-2 font-normal">GEKh454A</SpanTag>
                                </DivTag>
                            </DivTag>

                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    Disable/Block Date:
                                    <SpanTag className="ml-2 font-normal">12/12/2000</SpanTag>
                                </DivTag>
                            </DivTag>

                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    Disable/Block Date Reason:
                                    <SpanTag className="ml-2 font-normal">Service</SpanTag>
                                </DivTag>
                            </DivTag>

                            <DivTag className="w-full md:w-1/2 pr-4 mb-2">
                                <DivTag className="font-semibold border-b-2 border-blue-900 py-1 px-2 rounded-sm">
                                    Domain:
                                    <SpanTag className="ml-2 font-normal">name@npsp</SpanTag>
                                </DivTag>
                            </DivTag>
                        </DivTag>
                    </DivTag>

                    <DivTag className="flex justify-center p-4">
                        {/* Add any buttons or additional elements here */}
                    </DivTag>
                </DivTag>
            </DivTag>
        </DivTag>
    );
};

export default DetailsButtonPopup;
