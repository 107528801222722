
import React from 'react';

const FormTag = ({children, className, method, dataGroupName, autoComplete, dataAutoSubmit,id }) => (
  <form
    className={className}
    method={method}
    data-group-name={dataGroupName}
    data-autosubmit={dataAutoSubmit}
    autoComplete={autoComplete}  
    id={id}
  >
    {children}
    
  </form>
);
export default FormTag;
