
import React from 'react';

const  SpanTag = ({text, id, children, className, style }) => (

    <span
       id={id}
      className={className}
      style={style}
    >
        {text}
        {children}
  </span>
);

export default SpanTag;
