import React from 'react';
import { FaSun, FaCloudSun } from 'react-icons/fa';

const WeatherForcast = () => {
  return (
    <div className="max-w-sm mx-auto p-4 bg-white rounded-lg shadow-lg border border-gray-200">
      <div className="flex flex-col items-center p-6 bg-blue-50 rounded-lg">
        <div className="text-center mb-6">
          <h2 className="text-2xl font-bold text-blue-700">Muzaffarpur</h2>
          <p className="text-sm text-blue-500">May 14</p>
        </div>
        <FaSun className="text-yellow-400 text-6xl animate-spin-slow" />
        <div className="mt-7 text-4xl font-semibold text-blue-700">
          27° <span className="mx-1 font-normal">/</span> 19°
        </div>
        <p className="text-blue-400 text-lg mt-2">Sunny</p>
      </div>
    </div>
  );
};

export default WeatherForcast;
