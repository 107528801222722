import React from 'react'; // Import React
import ImgTag from '../ImageComponent/ImgTag'; // Import ImgTag component
import DivTag from '../DivComponent/DivTag'; // Import DivTag component
import HeadingTag from '../HeadingComponent/HeadingTag'; // Import HeadingTag component
import ParagraphTag from '../ParagraphComponent/ParagraphTag'; // Import ParagraphTag component
import ATag from '../Anchorlink/ATag'; // Import ATag component

// Define the CouponComponent function component
const CouponComponent = ({ imageUrl, headingText, paragraphText }) => {
  return (
    <DivTag className="widget overflow-hidden p-0 mt-2.5 ">
      <ImgTag src={imageUrl} className="w-80" /> {/* Dynamic image source */}
      <DivTag className="p-3">
        <HeadingTag className="font-15 strong" text={headingText}></HeadingTag> {/* Dynamic heading text */}
        <ParagraphTag className="font-12 text-gray-700 mb-1" text={paragraphText}></ParagraphTag> {/* Dynamic paragraph text */}
        <ATag className="inline-block align-middle text-center select-none border font-normal
             whitespace-no-wrap rounded  no-underline py-2 px-3 leading-tight text-xs  bg-blue-600
               hover:bg-blue-600 text-white">
          Redeem
        </ATag>
      </DivTag>
    </DivTag>
  );
};

export default CouponComponent; // Export CouponComponent for reuse
