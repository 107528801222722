import React from 'react';

const SelectWithLabelOption = ({ id, name, value, onChange, onBlur, type, children, DivclassName, error, label, required }) => {
  return (
    <div className={DivclassName}>
      <label htmlFor={id} className="block tracking-wide text-s font-bold mb-0">
        {label} {required && <span className="text-red-500">*</span>}
      </label>
      <select
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className="appearance-none block w-full text-gray-700 rounded py-1.5 px-4 mb-1
         leading-tight focus:outline-none border border-blue-500 focus:bg-white text-center"
      >
        {children}
      </select>
      {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  );
};

export default SelectWithLabelOption;
