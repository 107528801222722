import React from 'react';
import DivTag from '../Components/DivComponent/DivTag';
import WidgetComponent from '../Components/WidgetComponent/WidgetComponent';
import SalesSummaryWidget from '../Components/SalesSummWidgetComponent/SalesSummaryWidget';
import CouponComponent from '../Components/CommingWidgetComponent/CommingSoWid';
import BadgeComponent from '../Components/BadgeComponent/BadgeCom';
import BestSellerComponent from '../Components/BestSellerComponent/BestSellerComponent';
import SalesMeetingComponent from '../Components/SalesMettingComponent/SalesMettingCom';
import SpendingReportComponent from '../Components/SpendingRepComponent/SpendingRepCom';
import QuickCategoryComponent from '../Components/QuickComponent/QuickCom';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import WeatherForcast from './WeatherForcast/WeatherForcast';
import StorageView from './StorageView/StorageView';
import WeatherRainBow from './WeatherRainBow/WeatherRainBow';
import PaymentView from './PaymentMethodView/PaymentView';
import { FaMobileAlt, FaLaptop, FaBook, FaCamera } from 'react-icons/fa';

const DashboardMainContent = () => {
  const { id } = useParams();
  return (
    <>
      <motion.div
        initial={{ opacity: 0, y: -50 }}  
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
      >
        <div className="sm:mt-4 mt-8 md:mt-4">
          <DivTag className="px-2">
            <DivTag className="flex flex-wrap">
              <DivTag className="xl:w-1/3 lg:w-1/3 md:w-1/4 sm:w-full mb-2 rounded-md">
                <BadgeComponent
                  imageUrl="../../assets/img/new-badge.png"
                  headingText="New Badge Earned"
                  paragraphText="Congratulations! You have earned a new badge today."
                />
              </DivTag>
              <DivTag className="w-full xl:w-1/3 lg:w-1/3 md:w-1/4 sm:w-full mt-2 pr-2 pl-2">
                <PaymentView />
              </DivTag>
              <DivTag className="w-full xl:w-1/3 pr-1 pl-2 lg:w-1/3 md:w-1/4 sm:w-full mb-4 mt-4 bg-white border border-red-700 rounded-md">
                <SalesMeetingComponent
                  headingText="Sales Meeting"
                  startTime="2nd Fab 2024"
                  joinedCount="175"
                  speakerAvatars={[
                    "../../assets/img/profile-1.jpg",
                    "../../assets/img/profile-2.jpg",
                    "../../assets/img/profile-3.jpg",
                    "../../assets/img/profile-4.jpg",
                  ]}
                />
                {/* <SpendingReportComponent /> */}
              </DivTag>
              <DivTag className="xl:w-1/3 lg:w-1/3 md:w-1/3 sm:w-full mb-2 bg-white rounded-md border border-red-700">
                <CouponComponent
                  imageUrl="../../assets/img/coming-soon-bg-1.png"
                  headingText="August Bank Coupon is here!"
                  paragraphText="Amount will be credited to your card after 24 hours of redemption"
                />
              </DivTag>
              <DivTag className="w-full xl:w-[32%] p-1 ml-2 lg:w-[30%] md:w-[30%] sm:w-full bg-white rounded-md border border-red-700">
                <SalesSummaryWidget />
              </DivTag>
              <DivTag className="w-full xl:w-1/3 lg:w-1/3 ml-1 md:w-1/1 sm:w-full md:mt-0 mt-2 ">
      <WidgetComponent
        icon={<FaMobileAlt className="text-white text-2xl animate-bounce" />}
        value="$2899"
        text="Mobile"
        totalSold={758}
      />
      <WidgetComponent
        icon={<FaLaptop className="text-white text-2xl animate-bounce" />}
        value="$2141"
        text="Laptop"
        totalSold={68}
      />
      <WidgetComponent
        icon={<FaBook className="text-white text-2xl animate-bounce" />}
        value="$1221"
        text="Books"
        totalSold={435}
      />
      <WidgetComponent
        icon={<FaCamera className="text-white text-2xl animate-bounce" />}
        value="$231"
        text="Camera"
        totalSold={123}
      />
    </DivTag>
              <DivTag className="w-full xl:w-1/3 pr-1 pl-2 lg:w-1/3 md:w-1/1 sm:w-full mt-2.5">
                <WeatherRainBow />
              </DivTag>
              <DivTag className="w-full xl:w-1/3 pr-1 pl-2 lg:w-1/3 md:w-1/1 sm:w-full mt-2.5">
                <WeatherForcast />
              </DivTag>
              <DivTag className="w-full xl:w-1/3 pr-1 pl-2 lg:w-1/3 md:w-1/1 sm:w-full mb-0.5 mt-2.5">
                <StorageView />
              </DivTag>
            </DivTag>
          </DivTag>

          {/* Cart Group */}
          <DivTag className="flex flex-wrap p-4">
          <QuickCategoryComponent
        Icon={FaMobileAlt}
        comparisonText="+5%"
        isPositive={true}
        categoryNumber="2899"
        categoryText="Mobile Sales"
      />
      <QuickCategoryComponent
        Icon={FaLaptop}
        comparisonText="-2%"
        isPositive={false}
        categoryNumber="2141"
        categoryText="Laptop Sales"
      />
      <QuickCategoryComponent
        Icon={FaBook}
        comparisonText="+10%"
        isPositive={true}
        categoryNumber="1221"
        categoryText="Book Sales"
      />
      <QuickCategoryComponent
        Icon={FaCamera}
        comparisonText="-7%"
        isPositive={false}
        categoryNumber="231"
        categoryText="Camera Sales"
      />

          </DivTag>

          {/* Cart Group 2 */}
          {/* <DivTag className="flex flex-wrap p-4">
          <QuickCategoryComponent
        Icon={FaMobileAlt}
        comparisonText="+5%"
        isPositive={true}
        categoryNumber="2899"
        categoryText="Mobile Sales"
      />
      <QuickCategoryComponent
        Icon={FaLaptop}
        comparisonText="-2%"
        isPositive={false}
        categoryNumber="2141"
        categoryText="Laptop Sales"
      />
      <QuickCategoryComponent
        Icon={FaBook}
        comparisonText="+10%"
        isPositive={true}
        categoryNumber="1221"
        categoryText="Book Sales"
      />
      <QuickCategoryComponent
        Icon={FaCamera}
        comparisonText="-7%"
        isPositive={false}
        categoryNumber="231"
        categoryText="Camera Sales"
      />
          </DivTag> */}
          {id}
        </div>
      </motion.div>
    </>
  );
};

export default DashboardMainContent;
