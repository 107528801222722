import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Import AutoTable plugin

// Download Pdf Function Starts Here
export const downloadPdf = (tableData, headers) => {
    const doc = new jsPDF(); // Create new jsPDF instance
    
    // Check if headers is provided and is iterable
    if (!headers || !Array.isArray(headers) || headers.length === 0) {
        console.error('Invalid or empty headers array');
        return;
    }
    
    // Add title to the PDF document
    doc.text('Table Data', 10, 10);
    
    // Define table columns and rows
    const columns = ['S No.', ...headers]; // Include 'S No.' at the beginning
    const rows = tableData.map(item => {
        const rowData = headers.map(header => item[header]);
        return [item.SNo, ...rowData]; // Include SNo manually
    });
  
    // AutoTable plugin options
    const options = {
      startY: 20, // Start y position of the table
    };
  
    // Add table to the PDF document
    doc.autoTable(columns, rows, options);
    
    // Save the PDF document
    doc.save('table_data.pdf');
};
// Download Pdf Function Ends Here

export default downloadPdf;
