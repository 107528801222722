import React, { useMemo, useState } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import DivTag from '../../Components/DivComponent/DivTag';
import Button from '../../Components/ButtonComponent/Button';
import FormValidation from '../../Components/FormValidation/FormValidation';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import TextAreaWithLabel from '../../Components/TextAreaWithLabel/TextAreaWithLabel';
import ReportHeading from '../../Components/ReportHeadings/ReportHeadings';
import InputWithLabel from '../../Components/InputComponent/InputWithLabel';
import Option from '../../Components/OptionComponent/Option';
import { motion } from 'framer-motion';
import FormTag from '../../Components/FormComponent/FormTag';
import { addApiService, RetriveServicecategoryService } from '../../../services/apiaddservice';
import { showErrorToast, showSuccessToast } from '../../../utils/toastUtils';
import { ToastContainer } from 'react-toastify';
import { retrivePackageService } from '../../../services/PackageList/PackageService';
import Seo from '../../../components/SeoComponent/Seo';
const AddService = () => {
    const initialState = {
        service: '',
        status: '',
    };
    const [value, setValue] = useState('');
    const [serviceCategoryList, setServiceCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [serviceName, setServiceName] = useState('');
    const [postUrl, setPostUrl] = useState('');
    const [introduction, setIntroduction] = useState('');
    const [requestResponse, setRequestResponse] = useState('');
    const [successResponse, setSuccessResponse] = useState('');
    const [failedResponse, setFailedResponse] = useState('');
    const [activationFees, setActivationFees] = useState();
    const [isAgreementAgreed, setIsAgreementAgreed] = useState(false);
    const [adjustmentType, setAdjustmentType] = useState('');
    const [packageData, setPackageData] = useState([]);
    const validate = (values) => {
        let errors = {};
        // Add validation logic here
        if (!values.SelectService || values.SelectService === '') {
            errors.SelectService = 'Service is required';
        }
        if (!values.SelectStatus || values.SelectStatus === '') {
            errors.SelectStatus = 'Status is required';
        }
        return errors;
    };
    const { values, errors, handleChange, handleBlur } = FormValidation(
        initialState,
        validate
    );
    const [names, setNames] = useState(['John', 'Bobby', 'Bobby', 'Bo', 'Ram', 'Ra', 'ram']);
    const [filteredNames, setFilteredNames] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);




    const modules_quill = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ 'size': [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            [{ 'color': ['#ff0000', '#00ff00', '#0000ff', '#000000'] }], // Set font color options to red, green, blue
            ['clean']
        ]
    };
    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video',
        'color'
    ];
    const handleSubmitSerice = async () => {
        try {
            if (isAgreementAgreed === false) {
                return showErrorToast('Checked Agrement ')
            }
            if (selectedCategory === "") {
                showErrorToast('Category is required ');
                return
            }
            if (serviceName === "") {
                showErrorToast('service name  is required ');
                return
            }
            if (postUrl === "") {
                showErrorToast('Post url is required ');
                return
            }
            if (value === "") {
                showErrorToast('Introduction  is required ');
                return
            }
            if (requestResponse === "") {
                showErrorToast('Request Response is required ');
                return
            }
            if (failedResponse === "") {
                showErrorToast('Failed Response is required ');
                return
            }
            if (activationFees === "") {
                showErrorToast('Activation  fees is required ');
                return
            }
            const response = await addApiService(
                {
                    categoryId: selectedCategory,
                    serviceName: serviceName,
                    postUrl: postUrl,
                    introduction: value,
                    requestResponse: requestResponse,
                    failedResponse: failedResponse,
                    fees: activationFees,
                    adjustment_type: adjustmentType,
                    successResponse:successResponse
                }
            );
            console.log(response)
            if (response.status.code === 201) {
                showSuccessToast('Successfully added service ');
                setActivationFees('')
                setFailedResponse('');
                setValue('');
                setServiceName('');
                setRequestResponse('');
                setPostUrl('');
                setSuccessResponse('')
                return;
            }
            if (response.status === 422) {
                showErrorToast('post url already exits');
                return;
            }

        } catch (error) {
            showErrorToast('Failed to add new service ');
            return
        }
    }

    async function RetriveServicecategory() {
        try {
            const respone = await RetriveServicecategoryService();

            if (respone.success === true) {
                setServiceCategoryList(respone.data);
                return;
            }
        } catch (error) {
            return error;
        }
    }

    async function RetrivePackageData() {
        try {
            const response = await retrivePackageService();
            console.log(response)
            setPackageData(response.data);
        } catch (error) {
            return error;
        }
    }

    useMemo(() => {
        RetriveServicecategory();

    }, [])
    return (
        <motion.div initial={{ opacity: 0, y: -50 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 1.2 }}>
            <Seo />
            <DivTag id="" className=" border-2 border-solid border-blue-800 rounded-xl border-t-0 mt-12 bg-white">
                <ReportHeading name="Add Service" />

                <DivTag className="flex flex-wrap p-3 mt-2">
                    <SelectWithLabelOption DivclassName="w-full md:w-1/4 px-3 md:mb-0" label="Select Service Category" id="service" name="service" value={selectedCategory} onChange={(e) => setSelectedCategory(e.target.value)} className={errors.service ? 'border border-red-500' : 'border border-blue-600'} error={errors.service} required={true} >
                        <Option value="" disabled selected>Select Service Category</Option>
                        {
                            serviceCategoryList && serviceCategoryList.length > 0 ?
                                serviceCategoryList.map((category, index) => (
                                    <Option key={index} value={category.id} >{category?.name}</Option>
                                ))
                                : ""
                        }
                    </SelectWithLabelOption>
                    <SelectWithLabelOption DivclassName="w-full md:w-1/4 px-3 md:mb-0" label="Select Adjustment_type
" id="service" name="service" value={adjustmentType} onChange={(e) => setAdjustmentType(e.target.value)} required={true} >
                        <Option value="" disabled selected>Select adjustment_type
                        </Option>
                        <Option value="charge" >charge
                        </Option>
                        <Option value="commission" >commission
                        </Option>

                    </SelectWithLabelOption>
                    <InputWithLabel DivclassName="w-full md:w-1/4 px-2 md:mb-0 text-left mb-0 text-black"
                        DivTagClassName="absolute z-60 ml-3 mt-16 w-40 bg-white border rounded shadow-md" label="Service Name" type="text" name="service_name" placeholder="Enter Service Name..." id="service_name" value={serviceName} onChange={(e) => setServiceName(e.target.value)} className="border" required={true} />
                    <InputWithLabel DivclassName="w-full md:w-1/4 text-black px-2 md:mb-0 text-left mb-0 text-black"
                        DivTagClassName="absolute z-60 ml-3 mt-16 w-40 bg-white border rounded shadow-md text-black" label="Post URL" type="url" name="post_url" placeholder="Enter POST URL..." id="post_url" value={postUrl} onChange={(e) => setPostUrl(e.target.value)} className="border" required={true} />

                    <div className="d-flex w-full">
                        <div className="flex flex-col w-full my-14" >
                            <label className='block uppercase tracking-wide text-s font-bold mb-1'>Instruction <span className="text-red-500">*</span></label>
                            <ReactQuill
                                theme="snow"
                                value={value}
                                onChange={setValue}
                                modules={modules_quill}
                                formats={formats}
                                placeholder='Enter Instruction here....'
                                required={true}
                                className='w-full h-20 '
                            />
                        </div>
                        <div className='flex gap-3'>
                            <TextAreaWithLabel DivclassName="w-full px-2 md:mb-0 text-left mb-0 text-black"
                                label="Request"
                                type="text"
                                placeholder="Enter Request Sample"
                                name="req_sample"
                                id="req_sample"
                                value={requestResponse}
                                onChange={(e) => setRequestResponse(e.target.value)}
                                onBlur={handleBlur}
                                class_name='h-72'
                                 />
                            <TextAreaWithLabel DivclassName="w-full px-2 md:mb-0 text-left mb-0 text-black"
                                label="Sucess Response"
                                type="text"
                                placeholder="Enter Request Sample"
                                name="req_sample"
                                id="req_sample"
                                value={successResponse}
                                onChange={(e) => setSuccessResponse(e.target.value)}
                                onBlur={handleBlur}
                                class_name='h-72'
                                />
                        </div>
                    </div>

                    <TextAreaWithLabel DivclassName="w-full px-2 md:mb-0 text-left mb-0 text-black"
                        label="faled Response "
                        type="text"
                        placeholder="Failed Response "
                        name="successResponse"
                        id="successResponse"
                        value={failedResponse}
                        onChange={(e) => setFailedResponse(e.target.value)}

                        class_name='h-72'
                        />

                    <InputWithLabel DivclassName="w-full md:w-1/3 px-2 md:mb-0 text-left mb-0 text-black"
                        DivTagClassName="absolute z-60 ml-3 mt-16 w-40 handleSearcherbg-white border rounded shadow-md" label="Service Price" type="number" name="post_url" placeholder="Enter Price " id="post_url" value={activationFees} onChange={(e) => setActivationFees(e.target.value)} className="border" required={true} />

                    <div className='flex mt-5 ml-6 items-center gap-2 '>
                        <input type="checkbox" className='h-5 w-5' onChange={() => setIsAgreementAgreed(!isAgreementAgreed)} />
                        <p className='text-neutral-500 '>I have agreed terms & condition</p>
                    </div>

                    <Button type="submit" className="text-center mx-auto text-white bg-blue-700 shadow-lg bg-gradient-to-r mt-3 from-blue-900 via-teal-400 to-purple-900 font-semibold rounded-md px-2 py-1 text-lg hover:bg-red-300" onClick={handleSubmitSerice}>
                        Submit
                    </Button>
                </DivTag>

            </DivTag>
            <ToastContainer />
        </motion.div>

    );
};


export default AddService;