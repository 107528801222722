import React, { useState } from 'react';
import DivTag from '../Components/DivComponent/DivTag';
import Button from '../Components/ButtonComponent/Button';
import FormValidation from '../Components/FormValidation/FormValidation';
import InputWithLabel from '../Components/InputComponent/InputWithLabel';
import HeadingTag from '../Components/HeadingComponent/HeadingTag';
import SelectWithLabelOption from '../Components/SelectComponent/SelectWithLabelOption';
import ReportHeading from '../Components/ReportHeadings/ReportHeadings';
import Option from '../Components/OptionComponent/Option';
import SearchApiUserFiled from '../Components/SearchApiUserComponent/SearchApiUserFiled';
import { motion } from 'framer-motion';
import printTable from '../Components/TablePrintExcelPdfSearch/tablePrint';
import downloadExcel from '../Components/TablePrintExcelPdfSearch/downloadExcel';
import downloadPdf from '../Components/TablePrintExcelPdfSearch/downloadPdf';
import InputField from '../Components/InputComponent/InputField';
import 'jspdf-autotable'; // Import AutoTable plugin
import Seo from '../../components/SeoComponent/Seo';



const PayoutReport = () => {   

    const initialState = {
        from_date: '',
        to_date: '',
        status: '',
        txn_id: '',
    };

    const validate = (values) => {
        let errors = {};

        // Add validation logic here
        if (!values.from_date) {
            errors.from_date = 'From Date is required';
        }
        if (!values.to_date) {
            errors.to_date = 'To Date is required';
        }
        
        if (!values.status || values.status === '') {
            errors.status = 'Status is required';
        }
        if (!values.txn_id) {
            errors.txn_id = 'TxnId is required';
        }
        return errors;
    };

    const { values, errors, handleChange, handleBlur } = FormValidation(
        initialState,
        validate
    );

    // Define default table data
    const [defaultTableData, setDefaultTableData] = useState([
        {
            SrNo: 1,
            BinACNo: '5633453',
            IFSC: 'If934',
            Utr: 'Utr837',
            ApiId: "ap86754",
            CompanyName: 'Candour',
            TxnId: 'txn787',
            ApiTxnId: 'Ap78563',
            TxnAmt: 'tx873',
            Charges: '83,434',
            TxnDate: '12/12/2012',
        },
        {
            SrNo: 2,
            BinACNo: '234432',
            IFSC: 'If334',
            Utr: 'Utr097',
            ApiId: "ap86454",
            CompanyName: 'tcs',
            TxnId: 'txn092',
            ApiTxnId: 'Ap71f23',
            TxnAmt: 'tx234',
            Charges: '33,434',
            TxnDate: '09/09/2020',
        },
        {
            SrNo: 3,
            BinACNo: '3453454',
            IFSC: 'If000',
            Utr: 'Utr663',
            ApiId: "ap89844",
            CompanyName: 'Vipro',
            TxnId: 'txn8756',
            ApiTxnId: 'Ap7986',
            TxnAmt: 'tx876',
            Charges: '53,000',
            TxnDate: '11/11/2021',
        },
        {
            SrNo: 4,
            BinACNo: '324234',
            IFSC: 'If934',
            Utr: 'Utr837',
            ApiId: "ap86754",
            CompanyName: 'Optum',
            TxnId: 'txn787',
            ApiTxnId: 'Ap78563',
            TxnAmt: 'tx873',
            Charges: '83,434',
            TxnDate: '10/10/2022',
        },
        {
            SrNo: 5,
            BinACNo: '234543',
            IFSC: 'If9074',
            Utr: 'Utr887',
            ApiId: "ap86523",
            CompanyName: 'infosys',
            TxnId: 'txn7423',
            ApiTxnId: 'Ap7343',
            TxnAmt: 'tx873',
            Charges: '83,434',
            TxnDate: '01/01/2016',
        },
        {
            SrNo: 6,
            BinACNo: '89767',
            IFSC: 'If9675',
            Utr: 'Utr8567',
            ApiId: "ap86756",
            CompanyName: 'adani',
            TxnId: 'txn7458',
            ApiTxnId: 'Ap7353',
            TxnAmt: 'tx846',
            Charges: '73,434',
            TxnDate: '06/06/2019',
        }
  
    

        // Add more default data objects as needed
    ]);


    // State to track if search button is clicked
    const [searchClicked, setSearchClicked] = useState(false);

 

    // Function to handle search button click
    const handleSearchClick = () => {
        // Update the state to indicate that the search button is clicked
        setSearchClicked(true);
    };



    // Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5); // Change this number as per your requirement

    // Calculate total number of pages
    const totalPages = Math.ceil(defaultTableData.length / itemsPerPage);

    // Logic to handle pagination
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Printing function starts here 



// Printing function
const handlePrint = () => {
    printTable('table');
};

// Printing Function Ends


// Download function starts here

// Downloading Function
const onDownloadExcel = () => {
    const headers = Object.keys(defaultTableData[0]).filter(header => header !== 'Activate');
    downloadExcel(headers, defaultTableData);
};

// Download function ends here


// Download pdf function starts here
const onDownloadPdf = () => {
    // Extract headers from the defaultTableData
    const headers = Object.keys(defaultTableData[0]).filter(header => header !== 'Activate');

    // Call downloadPdf with both defaultTableData and headers
    downloadPdf(defaultTableData, headers);
};
// Download pdf function ends here

// Get current items
const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = defaultTableData.slice(indexOfFirstItem, indexOfLastItem);



//Search By Input Field of Table Data
const [searchQuery, setSearchQuery] = useState('');

// Function to handle changes in the search input
const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
};

// Filtered items based on search query
const filteredItems = currentItems.filter(item =>
    item.BinACNo.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.IFSC.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.Utr.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.ApiId.toLowerCase().includes(searchQuery.toLocaleLowerCase())
);
//Search Filter End



    return (

        <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
    >
        <Seo />
   <DivTag id="" className=" mt-8 bg-white">
            <ReportHeading name="Payout Report" />
                <DivTag className="flex justify-center items-center">
                    <DivTag className="flex flex-wrap mt-3">
                    <SearchApiUserFiled  
                    className="w-1/2 md:w-1/6 px-2 md:mb-0"
                    />
                        <InputWithLabel
                            DivclassName="w-1/2 md:w-1/6 px-2 md:mb-0"
                            label="From Date"
                            type="date"
                            name="from_date"
                            id="from_date"
                            value={values.from_date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.from_date ? 'border border-red-500' : 'border border-blue-600'}
                            error={errors.from_date}
                        />


                        <InputWithLabel
                            DivclassName="w-1/2 md:w-1/6 px-2 md:mb-0"
                            label="To Date"
                            type="date"
                            name="to_date"
                            id="to_date"
                            value={values.to_date}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.to_date ? 'border border-red-500' : 'border border-blue-600'}
                            error={errors.to_date}
                        />



                        <SelectWithLabelOption
                            DivclassName="w-1/2 md:w-1/5 px-2 md:mb-0"
                            label="Status"
                            id="status"
                            name="status"
                            value={values.status}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.status ? 'border border-red-500' : 'border border-blue-600'}
                            error={errors.status}
                        >
                            <Option value="">Select Status</Option>
                            <Option value="New"></Option>
                            <Option value="De-Assigned"></Option>
                        </SelectWithLabelOption>
                       

                        <InputWithLabel
                            DivclassName="w-1/2 md:w-1/5 px-2 md:mb-0"
                            label="TxnID/UTR"
                            type="text"
                            placeholder="Enter Txnid/Utr"
                            name="txn_id"
                            id="txn_id"
                            value={values.txn_id}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={errors.txn_id ? 'border border-red-500' : 'border border-blue-600'}
                            error={errors.txn_id}
                        />

                    </DivTag>

                </DivTag>

                <DivTag className="flex justify-center">
                    <Button
                        type="submit"
                        className="text-center text-white bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900
                         via-teal-400 to-purple-900 font-semibold p-0 rounded-md px-2 py-1 text-lg hover:bg-red-300"
                        onClick={handleSearchClick}>
                        Search
                    </Button>
                </DivTag>


                <HeadingTag className="text-2xl text-blue-800 font-bold bg-white text-center mt-2 mb-2" text="Report"/>



                 {/* Print,pdf,excel,SearchFilter */}
                 <DivTag className="flex">
                    <DivTag className="mr-auto p-0.5">
                        {/* Button printing */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={handlePrint}
                            text="Print"
                        />

                        {/* Button downloadexcel */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={onDownloadExcel}
                            text="Excel"
                        />

                        {/* Button  downloadPdf  */}
                        <Button
                            type="button"
                            className="bg-gradient-to-r from-green-400 to-blue-800 hover:from-pink-500 hover:to-yellow-500 text-white border border-white rounded-lg text-md p-1.5"
                            onClick={onDownloadPdf}
                            text="PDF"
                        />
                    </DivTag>
                    {/* Input Search Field */}

                    <InputField
                        type="text"
                        value={searchQuery}
                        onChange={handleSearchInputChange}
                        placeholder="Search.........."
                        className="appearance-none block text-gray-700 border rounded mb-1
                leading-tight focus:outline-none focus:bg-white text-center"
                    />
                </DivTag>



                <DivTag className="justify-center p-0.5">
                        <table className="table table-hover w-full min-w-full">

                            <thead className="text-md">
                                <tr className="bg-blue-800 text-white text-center">
                                    <th className="border-2 p-2">S No.</th>
                                    <th className="border-2">Beneficiary A/C No</th>
                                    <th className="border-2">IFSC</th>
                                    <th className="border-2">Utr</th>
                                    <th className="border-2">Api ID</th>
                                    <th className="border-2">Company Name</th>
                                    <th className='border-2'>Txn ID</th>
                                    <th className='border-2'>Api Txn ID</th>
                                    <th className='border-2'>Txn Amt</th>
                                    <th className='border-2'>charges</th>
                                    <th className='border-2'>Txn Date</th>
                                </tr>
                            </thead>
                            <tbody className="overflow-y-scroll w-80">
                            {filteredItems.map((item, index) => (
                                    <tr key={index} className='text-center'>
                                        <td>{item.SrNo}</td>
                                        <td>{item.BinACNo}</td>
                                        <td>{item.IFSC}</td>
                                        <td>{item.Utr}</td>
                                        <td>{item.ApiId}</td>
                                        <td>{item.CompanyName}</td>
                                        <td>{item.TxnId}</td>
                                        <td>{item.ApiTxnId}</td>
                                        <td>{item.TxnAmt}</td>
                                        <td>{item.Charges}</td>
                                        <td>{item.TxnDate}</td>
                                     </tr>
                                ))}
                            </tbody>
                        </table>
                    </DivTag>
            </DivTag>
  
</motion.div>
  
        );
    };
    
    export default PayoutReport;













