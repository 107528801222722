// ButtonTag.js
import React from 'react';

const Button = ({type, text, children, className, id, name, onClick, areaLabel ,...props}) => (
  <button
  type={type}
    className={className}
    id={id}
    name={name}
    onClick={onClick}
    aria-label={areaLabel}
  >
    {text}
    {children}
  </button>
);

export default Button;
