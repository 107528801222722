
import React from 'react';

const ITag = ({children,className}) => (
  
    <i
      className={className}
    >
      {children}
  </i>
);

export default ITag;


