// src/Components/Loader.js
import React from 'react';
import IdsPayLogo from '../../assets/idsPaylogo.jpg';


const Loader = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 ">
            <div className="relative w-20 h-20 flex items-center justify-center ">
                <div className="absolute w-full h-full border-4 border-blue-800 border-t-4 border-solid rounded-full animate-bounce  "></div>
                <img
                    className="w-full h-full animate-bounce rounded-[50%]  object-contain "
                    src={IdsPayLogo}
                    alt="logo"
                />
            </div>
        </div>
    );
};

export default Loader