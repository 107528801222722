import React from 'react';

const TextAreaWithLabel = ({ label, placeholder, name, id, type, value, onChange, DivclassName, onBlur, error,row_val,column_val, class_name,required }) => {
  return (
    <div className={DivclassName}>
      <label htmlFor={id} className="block uppercase tracking-wide text-s font-bold mb-1">
        {label}  {required &&<span className="text-red-500">*</span>}
      </label>
      <textarea
        id={id}
        type={type}
        row={row_val}
        cols={column_val}
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        className={`appearance-none block w-full text-gray-700 border rounded py-2 px-2 leading-tight focus:outline-none focus:bg-white ${class_name}`}
      />
      {error && <p className="text-red-500 text-xs italic">{error}</p>}
    </div>
  );
  
};
TextAreaWithLabel.defaultProps = {
  class_name: '',
};

export default TextAreaWithLabel;
