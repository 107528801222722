import React, { useState } from 'react';
import InputField from '../../../Components/InputComponent/InputField';
import Label from '../../../Components/LabelComponent/Label';
import DivTag from '../../../Components/DivComponent/DivTag';
import HeadingTag from '../../../Components/HeadingComponent/HeadingTag';
import FileUpload from '../../../Components/UploadFileComponent/Uploadfile';
import { Button as Buttons } from 'primereact/button';

const CompanyData = ({ handleInputChange, Next }) => {
    const [companyName, setCompanyName] = useState('');
    const [authorizedPersonName, setAuthorizedPersonName] = useState('');
    const [companyType, setCompanyType] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [companyCIN, setCompanyCIN] = useState('');
    const [companyGstNo, setCompanyGstNo] = useState('');
    const [companyPan, setCompanyPan] = useState('');
    const [isNext, setIsNext] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [uploadCIN, setUploadCIN] = useState(null);
    const [uploadPAN, setUploadPAN] = useState(null);
    const [uploadGST, setUploadGST] = useState(null);

    // const handleNextStep = () => {
    //     console.log(companyName);
    //     if (companyName === '') {
    //         setIsNext(false)
    //         setErrorMessage('Company name is required');
    //     }
    //     // validateForm();
    //     Next();
    // };



    // State to manage error messages
    const [errors, setErrors] = useState({});

    // Handle input changes and validation
    const handleInputChangeField = (field, value) => {
        setErrors({ ...errors, [field]: '' }); // Clear the error for the field
        // Your code for handling input change
    };


    const validateForm = () => {
        let formErrors = {};
        if (!companyName) formErrors.companyName = 'Company Name is required';
        if (!authorizedPersonName) formErrors.authorizedPersonName = 'Authorized Person  Name is required';
        if (!companyType) formErrors.companyType = 'Company Type is required';
        if (!companyAddress) formErrors.companyAddress = 'Company Address is required';
        // if (!companyCIN) formErrors.companyCIN = 'Company CIN is required';
        // if (!companyPan) formErrors.companyPan = 'Company PAN is required';
        // if (!companyGstNo) formErrors.companyGstNo = 'Company GST No. is required';


        if (!companyCIN) {
            formErrors.companyCIN = 'Company CIN is required';
        } else if (!uploadCIN) {
            formErrors.upload_cin = 'CIN file is required because CIN is provided';
        }

        if (!companyPan) {
            formErrors.companyPan = 'Company PAN is required';
        } else if (companyPan.length < 10) {
            formErrors.companyPan = 'Company PAN must be 10 letter';
        } else if (!uploadPAN) {
            formErrors.upload_pan = 'PAN file is required because PAN is provided';
        }

        if (!companyGstNo) {
            formErrors.companyGstNo = 'Company GST No. is required';
        } else if (!uploadGST) {
            formErrors.upload_gst = 'GST file is required because GST No. is provided';
        }

        setErrors(formErrors);

        // If there are no errors, call the Next function
        if (Object.keys(formErrors).length === 0) {
            Next();
        }
    };


    return (
        <>
            <fieldset>
                <DivTag className="form-card p-1">
                    <HeadingTag className="fs-title text-xl font-bold" text="Company Information : -" />
                    {
                        errorMessage && <p className='text-red-500 text-center'>{errorMessage}</p>
                    }
                    <DivTag className="flex flex-wrap mt-3">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Company Type
                            </label>
                            <select
                                id="company_type"
                                name="company_type"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-4 mb-1 leading-tight focus:outline-none focus:bg-white text-center"
                                value={companyType}
                                required
                                onChange={(e) => {
                                    setCompanyType(e.target.value)
                                    handleInputChange('company', 'company_type_name', e.target.value)
                                }}
                            >
                                <option value="">Select Company Type</option>
                                <option value="Private Limited">Private Limited</option>
                                <option value="Public Limited">Public Limited</option>
                                <option value="NGO Sec-8">NGO Sec-8</option>
                                <option value="OPC">OPC</option>
                                <option value="LLP">LLP</option>
                                <option value="Partnership Firm">Partnership Firm</option>
                                <option value="Individual">Individual</option>
                                <option value="Other's">Other's</option>
                            </select>
                            {errors.companyType && <p className="text-red-500 text-xs italic">{errors.companyType}</p>}
                        </div>

                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Company Name
                            </label>
                            <input
                                id="companyName"
                                type="text"
                                name="company_name"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Company Name"
                                value={companyName}
                                required
                                onChange={(e) => {
                                    setCompanyName(e.target.value);
                                    console.log(companyName)
                                    // setIsNext(true)
                                    handleInputChange('company', 'company_name', e.target.value)
                                }}
                            />
                            {errors.companyName && <p className="text-red-500 text-xs italic">{errors.companyName}</p>}
                        </div>
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Authorized Person Name

                            </label>
                            <input
                                id="authorizePersonname"
                                type="text"
                                name="authorizePersonname"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Authorized Person Name"
                                value={authorizedPersonName}
                                required
                                onChange={(e) => {
                                    setAuthorizedPersonName(e.target.value);

                                    // setIsNext(true)
                                    handleInputChange('company', 'authorized_person_name', e.target.value)
                                }}
                            />
                            {errors.authorizedPersonName && <p className="text-red-500 text-xs italic">{errors.authorizedPersonName}</p>}
                        </div>
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Company Address
                            </label>
                            <input
                                id="companyAddress"
                                type="text"
                                name="company_address"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Company Address"
                                value={companyAddress}
                                required
                                onChange={(e) => {
                                    setCompanyAddress(e.target.value);
                                    console.log(companyAddress)
                                    handleInputChange('company', 'company_address', e.target.value)
                                }}
                            />
                            {errors.companyAddress && <p className="text-red-500 text-xs italic">{errors.companyAddress}</p>}
                        </div>

                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Company CIN/Registration
                            </label>
                            <input
                                id="companyCIN"
                                type="text"
                                name="company_cin"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Company CIN"
                                
                                value={companyCIN}
                                required
                                onChange={(e) => {
                                    setCompanyCIN(e.target.value);
                                    handleInputChange('company', 'company_cin', e.target.value)
                                }}
                            />
                            {errors.companyCIN && <p className="text-red-500 text-xs italic">{errors.companyCIN}</p>}
                        </div>

                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Company GST No.
                            </label>
                            <input
                                id="companyGstNo"
                                type="text"
                                name="company_gst_no"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Company GST No."
                                 
                                value={companyGstNo}
                                required
                                onChange={(e) => {
                                    setCompanyGstNo(e.target.value);
                                    handleInputChange('company', 'company_gst_no', e.target.value)
                                }}
                            />
                            {errors.companyGstNo && <p className="text-red-500 text-xs italic">{errors.companyGstNo}</p>}
                        </div>

                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label htmlFor="name" className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                                Company Pan
                            </label>
                            <input
                                id="companyPan"
                                type="text"
                                name="company_pan"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-1 leading-tight focus:outline-none focus:bg-white"
                                placeholder="Company Pan"
                                value={companyPan}
                                maxLength={10}
                                minLength={10}
                                required
                                onChange={(e) => {
                                    setCompanyPan(e.target.value);

                                    handleInputChange('company', 'company_pan', e.target.value)
                                    if (companyName != '') {
                                        setIsNext(true);
                                    }

                                }}
                            />
                            {errors.companyPan && <p className="text-red-500 text-xs italic">{errors.companyPan}</p>}
                        </div>

                        {/* <FileUpload label="Upload CIN" id="upload_cin" name="upload_cin" />
                        <FileUpload label="Upload GST No." id="upload_gst" name="upload_gst" />
                        <FileUpload label="Upload PAN" id="upload_pan" name="upload_pan" /> */}



                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                htmlFor="uploadCIN"
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            >
                                Upload CIN
                            </label>
                            <input
                                id="uploadCIN"
                                type="file"
                                name="upload_cin"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white"
                                onChange={(e) => {
                                    setUploadCIN(e.target.files[0])


                                    handleInputChange('company', 'company_cin_photo', e.target.files[0])
                                }}
                            />
                            {errors.upload_cin && <p className="text-red-500 text-xs italic">{errors.upload_cin}</p>}
                        </div>

                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                htmlFor="uploadGST"
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            >
                                Upload GST No.
                            </label>
                            <input
                                id="uploadGST"
                                type="file"
                                name="upload_gst"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white"
                                onChange={(e) => {
                                    setUploadGST(e.target.files[0])
                                    handleInputChange('company', 'company_gst_photo', e.target.files[0])

                                }}
                            />
                            {errors.upload_gst && <p className="text-red-500 text-xs italic">{errors.upload_gst}</p>}
                        </div>

                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <label
                                htmlFor="uploadPAN"
                                className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                            >
                                Upload PAN
                            </label>
                            <input
                                id="uploadPAN"
                                type="file"
                                maxLength={10}
                                name="upload_pan"
                                className="appearance-none block w-full text-gray-700 border border-blue-600 rounded py-2 px-2 mb-3 leading-tight focus:outline-none focus:bg-white"
                                onChange={(e) => {
                                    setUploadPAN(e.target.files[0])
                                    handleInputChange('company', 'company_pan_photo', e.target.files[0])
                                }


                                }
                            />
                            {errors.upload_pan && <p className="text-red-500 text-xs italic">{errors.upload_pan}</p>}
                        </div>



                    </DivTag>
                </DivTag>
                <div className='flex justify-center mt-6'>
                    <Buttons
                        label="Next"
                        icon="pi pi-arrow-right"
                        iconPos="right"
                        defaultValue="Next Step"
                        className='bg-red-600 py-2 px-4 sm:py-1 sm:px-2 text-white'
                        // disabled={isNext === false}
                        onClick={validateForm}
                    />
                </div>
            </fieldset>
        </>
    );
};

export default CompanyData;