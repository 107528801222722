import React, { useState, useEffect } from 'react';
import DivTag from '../../Components/DivComponent/DivTag';
import Button from '../../Components/ButtonComponent/Button';
import FormValidation from '../../Components/FormValidation/FormValidation';
import InputWithLabel from '../../Components/InputComponent/InputWithLabel';
import SelectWithLabelOption from '../../Components/SelectComponent/SelectWithLabelOption';
import ReportHeading from '../../Components/ReportHeadings/ReportHeadings';
import { motion } from 'framer-motion';
import printTable from '../../Components/TablePrintExcelPdfSearch/tablePrint';
import downloadExcel from '../../Components/TablePrintExcelPdfSearch/downloadExcel';
import downloadPdf from '../../Components/TablePrintExcelPdfSearch/downloadPdf';
import InputField from '../../Components/InputComponent/InputField';
import 'jspdf-autotable'; // Import AutoTable plugin
import Seo from '../../../components/SeoComponent/Seo';
import { FiEdit } from "react-icons/fi";
import ProvidersActionPopup from './ProvidersActionPopup';
import HeadingTag from '../../Components/HeadingComponent/HeadingTag';
import { MdAdd } from "react-icons/md";

const ProviderReport = () => {   
const [isModalOpenActionPopup, setIsModalActionPop] = useState(false);
const [providerData, setProviderData] = useState([]);

    const initialState = {
        s_providers: '',
        prefix: '',
        ifsc: ''
    };


    // Function to open the modal
    const openActionPopup = () => {
        setIsModalActionPop(true);
    };

    // Function to close the modal
    const onCloseActionPop = () => {
        setIsModalActionPop(false);
    };


    const validate = (values) => {
        let errors = {};

        // Add validation logic here
        if (!values.s_providers || values.s_providers === '') {
            errors.s_providers = 'select Providres';
        }
        if (!values.prefix) {
            errors.prefix = 'Prefix is required';
        }
        
        if (!values.ifsc) {
            errors.ifsc = 'Ifsc is required';
        }
        return errors;
    };

    const { values, errors, handleChange, handleBlur } = FormValidation(
        initialState,
        validate
    );


// Printing function
const handlePrint = () => {
    printTable('table');
};


    return (

        <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.2 }}
    >
        <Seo />
   <DivTag className=" mt-8 bg-white">
            <ReportHeading name="Provider Report" /> 
             <div className='flex justify-end md:px-24 py-0'>
                         <button className=" bg-blue-800 text-white text-sm px-1 py-1 rounded-md hover:text-white shadow-xl  shadow-blue-800 hover:bg-blue-500" onClick={openActionPopup}>
                             + Add Provider</button>
                                </div>
                <DivTag className="flex justify-center items-center">
                    <DivTag className="flex flex-wrap">
                        <SelectWithLabelOption
                        DivclassName="w-1/2 md:w-1/3 px-2 md:mb-0"
                        label="Select Providers"
                        name="s_providers"
                        id="s_providers"
                        value={values.s_providers}
                        onChange=""
                        required={true}
                        className={errors.s_providers ? 'border border-red-500' : 'border border-blue-600'}
                        error={errors.s_providers}
                        >
                            <option value="" selected disabled>Select Providers</option>
                            <option value="Providers1">Providers1</option>
                            <option value="Providers2">Providers2</option>
                        </SelectWithLabelOption>
                       
                        <InputWithLabel
                         DivclassName="w-1/2 md:w-1/3 px-2 md:mb-0"
                         label="Prefix"
                         type="text"
                         name="prefix"
                         id="prefix"
                         value={values.prefix}
                         onChange=""
                         required={true}
                         placeholder="Enter Prefix"
                         className={errors.prefix ? 'border border-red-500' : 'border border-blue-600'}
                         error={errors.prefix}
                         />
                          <InputWithLabel
                         DivclassName="w-1/2 md:w-1/3 px-2 md:mb-0"
                         label="Ifsc"
                         type="text"
                         name="ifsc"
                         id="ifsc"
                         placeholder="Enter IFSC"
                         value={values.ifsc}
                         onChange=""
                         required={true}
                         className={errors.ifsc ? 'border border-red-500' : 'border border-blue-600'}
                         error={errors.ifsc}
                         />
                         
                    </DivTag>
                  
                </DivTag>

                <DivTag className="flex justify-center mt-2">
                    <Button
                        type="submit"
                        className="text-center text-white bg-blue-700 shadow-lg bg-gradient-to-r from-blue-900
                         via-teal-400 to-purple-900 font-semibold p-0 rounded-md px-2 py-1 text-lg "
                        onClick="">
                        Search
                    </Button>
                </DivTag>

                <HeadingTag className="text-2xl text-blue-800 font-bold bg-white text-center mt-2 mb-2" text="Report"/>

                <div className="overflow-x-auto my-1 p-2">
                       <table id="table" className="min-w-full divide-y divide-gray-200">
                            <thead className="text-md">
                                <tr className="bg-blue-800 text-white text-center">
                                    <th className="border-2 p-2">S No.</th>
                                    <th className="border-2">Provider</th>
                                    <th className="border-2">Prefix Date</th>
                                    <th className="border-2">Ifsc</th>
                                    <th className='border-2'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                            {/* {providerData.map((item, index) => ( */}
                                    <tr key="" className='text-center'>
                                        <td className='p-1 shadow-inner shadow-blue-600'>1</td>
                                        <td className='p-1 shadow-inner shadow-blue-400'>prod</td>
                                        <td className='p-1 shadow-inner shadow-blue-400'>pref</td>
                                        <td className='p-1 shadow-inner shadow-blue-400'>ifs04</td>
                                        <td className='p-1 shadow-inner shadow-blue-400'>
                                            <div className='flex justify-center'>
                                                <FiEdit className=" text-blue-700" size={24} />
                                                </div>
                                                </td>
                                     </tr>
                                {/* ))} */}
                            </tbody>
                        </table>
                    </div> 
            </DivTag>
            {isModalOpenActionPopup && <ProvidersActionPopup visible={isModalOpenActionPopup} onCloseActionPop={onCloseActionPop}  />}
</motion.div>
  
        );
    };
    
    export default ProviderReport;
