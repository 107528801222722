import React from 'react';
const Popup = ({ isOpen, onClose, children, popupMainContentClass }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className={`bg-white p-4 rounded-lg relative popup_main_content ${popupMainContentClass}`}>
        <button className="absolute hover:text-red-500 font-bold top-2 right-2 text-gray-500" onClick={onClose}>
          X
        </button>
        {children}
      </div>
    </div>
  );
};
export default Popup;