// SelectDropdown.js
import React from 'react';

const Option = ({value, children,disabled,selected}) => {
  return (
    <option
      value={value}
      disabled={disabled}
      selected={selected}
    >
      {children}
    </option>
  );
};

export default Option;
